import {
    Form,
    Input,
    Button,
    Card
  } from "antd";
  import BaseLayout from "./baselayout";
  import { useEffect, useState } from "react";
  import { getUserDetails, trigger401 } from "../api";
  import Banner from "../components/Banner/Banner";

  import Header from "../components/Header/Header";
  import * as api from "../api";
  import { useGetUserQuery, useLazyGetUserQuery, useUpdateUserMutation } from "../services/user";
  import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
  import { parsePhoneNumber } from "awesome-phonenumber";

  
  function ProfileWrapper({isImpersonated}) {
    const [form] = Form.useForm();
    const [errorMsg, setErrormsg] = useState(null);

    const userDetails = getUserDetails();
    const [triggerUpdateUser, updateUpdateUserFunc] = useUpdateUserMutation();
    const [triggerLazyGetUser, {data:userLazyData, error: userLazyError, isLoading: isUserLazyLoading, isFetching:isUserLazyFetching}] = useLazyGetUserQuery();
    const {
      data: userGetData,
      error: userGetError,
      isLoading: isUserGetLoading,
      isFetching : isUserGetFetching
    } = useGetUserQuery(userDetails?.sub, {skip: localStorage.getItem("isImpersonated")});

    api.setLastPath(window.location.href);

    const onFinish = async (values) => {
      var resp;
      if (userGetData?.data?.jobTitle === null) {
        if(userDetails.emails[0].includes('@akamai.com')){
          resp = await triggerUpdateUser({...values, "sub": userDetails.sub, "jobTitle": "Internal User"}).unwrap();
        }
        else{
          resp = await triggerUpdateUser({...values, "sub": userDetails.sub, "jobTitle": "Field Technician"}).unwrap();
        }
      }
      else{
        resp = await triggerUpdateUser({...values, "sub": userDetails.sub}).unwrap();
      }
      if (resp.success) {
        localStorage.setItem("userDetails",values.mobilePhone)
        api.openNotification("Success!","success","Profile Updated");
        const res = await triggerLazyGetUser(userDetails?.sub);
      }
      else
      {
        api.openNotification("Failed!","fail","Profile was not updated");
      }
    }

    let nullValues = ["null", null, undefined, "undefined"]

    useEffect(() => {
      if(isImpersonated) {
        form.setFieldsValue({
          permanentaccess: false,
          emails: nullValues.includes(localStorage.getItem("IMP_email")) ? "" : localStorage.getItem("IMP_email"),
          extension_ContactID: nullValues.includes(localStorage.getItem("IMP_extension_ContactID")) ? "" : localStorage.getItem("IMP_extension_ContactID"),
          givenName: nullValues.includes(localStorage.getItem("IMP_givenName")) ? "" : localStorage.getItem("IMP_givenName"),
          surname:  nullValues.includes(localStorage.getItem("IMP_surname")) ? "" : localStorage.getItem("IMP_surname"),
          mobilePhone: nullValues.includes(localStorage.getItem("IMP_mobilePhone")) ? "" : localStorage.getItem("IMP_mobilePhone"),
          // jobTitle: userGetData.data.jobTitle || ""
        })
      }
      else if(userGetData?.data){
        form.setFieldsValue({
          permanentaccess: false,
          emails: userDetails ? userDetails.emails : "",
          extension_ContactID: userDetails ? userDetails.extension_ContactID : "",
          givenName: userGetData.data.givenName || "",
          surname: userGetData.data.surname || "",
          mobilePhone: userGetData.data.mobilePhone || "",
          jobTitle: userGetData.data.jobTitle || ""
        });
      }

      if(userGetError) {
        console.log(userGetError);
      }
    }, [userGetData, userGetError])
   
    var title = "Profile"
    
    return (
      <>
        {errorMsg && <Banner msg={errorMsg} type="error" />}
        <Header title={title }/>
          <div className="content-container">
            {isUserGetFetching?<AkamaiPreloader style={{position:"center", paddingTop: "500px"}}/>:
            <Form
            labelCol={{ span: 3, offset: 0 }}
            labelWrap
            wrapperCol={{ span: 9 }}
            initialValues={{
              permanentaccess: false,
              emails: userDetails ? userDetails.emails : "",
              extension_ContactID: userDetails ? userDetails.extension_ContactID : "",
              givenName: userDetails ? userDetails.givenName : "",
              surname: userDetails ? userDetails.surname : "",
              mobilePhone: null,
              jobTitle: userDetails ? userDetails.jobTitle : ""
            }}
            form={form}
            onFinish={(values) => onFinish(values)}
          >
            <Card style={{marginTop:"95px"}}>
             <Form.Item
              name="extension_ContactID"
              label="Contact ID"
            >
              <Input style={{backgroundColor:"#E8E8E8", color:"rgba(0,0,0,.65)"}} readOnly disabled={true}/>
            </Form.Item>
            <Form.Item
              name="emails"
              label="E-Mail ID"
            >
              <Input style={{backgroundColor:"#E8E8E8", color:"rgba(0,0,0,.65)"}} readOnly disabled={true}/>
            </Form.Item>
            <Form.Item
              name="jobTitle"
              label="Job Title"
            >
              <Input style={{backgroundColor:"#E8E8E8", color:"rgba(0,0,0,.65)"}} readOnly disabled={true}/>
            </Form.Item>
            <Form.Item
              name="givenName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "First Name is required!"
                },
                {
                  max: 64,
                  message: "Cannot exceed 64 characters"
                }]}
              >
                <Input />
            </Form.Item>
            <Form.Item
              name="surname"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Last Name is required!"
                },
                {
                    max: 64,
                    message: "Cannot exceed 64 characters"
                }]}
              >
                <Input />
            </Form.Item>
            <Form.Item
              name="mobilePhone"
              label="Phone Number"
              rules={[
                { required: true, message: "Phone number is required with a valid country code!" },
                {
                  validator(_, value) {
                    // Phone number validation
                    if(value){
                      const ph = parsePhoneNumber(value);
                      if (ph.isValid()) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter valid phone number with country code!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Enter phone number with country code" />
            </Form.Item>
            <Button
              type="primary" 
              htmlType="submit" 
              style={{ margin: '0'}}
              loading={updateUpdateUserFunc["isLoading"]}
              disabled={isImpersonated}
            >
              Submit
            </Button>
            </Card>
                                  
          </Form>}
            
          </div>
      </>
    );
  }
  
  export default function Profile({isImpersonated}) {
    let props = { isImpersonated }
    return BaseLayout(ProfileWrapper, props);
  }
  