const ShipmentCoumns = [
    {
        title: 'Tracking Number',
        dataIndex: 'tracking_number',
        key: 'tracking_number',
        fixed: 'left',
        width: 150
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        fixed: 'left',
        width: 80
    },
    {
        title: 'Quantity X Product',
        dataIndex: 'equipment_shipped',
        key: 'equipment_shipped',
        width: 200
    },
    {
        title: 'Signed By',
        dataIndex: 'signed_by',
        key: 'signed_by',
        width: 90
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 100
    }
]

export default ShipmentCoumns;