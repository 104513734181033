import { useEffect, useState } from "react";
import {
  Descriptions,
  Tag,
  Button,
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Checkbox,
  Tooltip,
  Popover,
  Modal,
  Timeline,
  Collapse,
  Alert,
  Menu,
  List,
  Comment,
  Avatar,
  Badge
} from "antd";
import {
  LockTwoTone,
  LoadingOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  StarFilled,
  StarOutlined,
  DownloadOutlined,
  ClockCircleOutlined,
  ExportOutlined
} from "@ant-design/icons";
import { useParams,useNavigate, Link } from "react-router-dom";
import BaseLayout from "./baselayout";
import DataTables from "../components/DataTables/DataTables";
import {
  useAddResolutionMutation,
  useGetIssueActivityQuery,
  useGetTodosQuery,
  usePrefetch,
  useGetAuditRackQuery,
  useGetShipmentInfoQuery,
  useGetFTNotesQuery,
  useInsertFTNotesMutation,
  useGetSingleDcQuery
} from "../services/datacenter";
import { useRunIsOkMutation, useGetIsOkMutation , useLazyAttachIsOkResultQuery} from "../services/isok";
import * as api from "../api";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import LoadingIcon from "../components/Spinners/Loading";
import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader"
import {CSVLink} from "react-csv";
import Marquee from 'react-fast-marquee';
import moment from "moment";
import ShipmentColumns from "../components/TodoElements/ShipmentColumns";
import { ReactComponent as RedirectIconLarge } from "../Assets/images/redirect-icon-large.svg"
import Icon from "@ant-design/icons"; 

let timer = null;
const userDetails = api.getUserDetails();

function TodoListWrapper({ type, isImpersonated }) {
  const [formsubmit] = Form.useForm();
  const [formNotes] = Form.useForm();
  const [testResult, setTestResult] = useState({value: ''});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [childDrawerVisible, setChildDrawerVisible] = useState(false);
  const [childDrawerNotesVisible, setChildDrawerNotesVisible] = useState(false);
  const [shipmentTableVisible, setShipmentTableVisible] = useState(false);
  const [rackDrawer,setRackDrawerVisible] = useState(false);
  const [todoSelected, setTodoSelected] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [unknownAskInfo, setUnknownAskInfo] = useState(false);
  const [form, setForm] = useState(false);
  const [ notesForm, setNotesForm ] = useState(false);
  const { data_center_id } = useParams();
  const { Panel } = Collapse;
  const [errorMsg, setErrormsg] = useState(null);
  const [resolutionSpinner, startResolutionSpinner] = useState(null);
  const [isokSpinner, startIsOkSpinner] = useState(null);
  const [disableCancel, setDisableCancel] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [searchedText,setSearchedText] = useState("");
  const [commentDisabled, setCommentDisabled] = useState(true);
  const [selectedIssueIDs, setSelectedIssueIDs] = useState({});
  
  const navigate = useNavigate();
  const { data, error, isLoading, isSuccess, refetch, isFetching } = useGetTodosQuery({
    data_center_id,
    type,
  });
  const {
    data: dc_data,
    error: dc_error
} = useGetSingleDcQuery({ dc_id: data_center_id }, {skip: type === "todos"});

  const [triggerAddResolution, {error: addResolutionError}] = useAddResolutionMutation();

  const [triggerGetResolution, getResolutionFunc] = useGetIsOkMutation();
  
  const [triggerAddFTNotes, addFTNotes] = useInsertFTNotesMutation();
  
  const [triggerIsOk, triggerIsOkFunc] = useRunIsOkMutation();
  
  const [triggerGetIsOkAttachment, {data:isOkAttachment, error:isOkAttachmentError, isFetching:isOkAttachmentFetching}] = useLazyAttachIsOkResultQuery();
  
  const {
    data: issueDetails,
    error: activityError,
    isLoading: isActivityLoading,
    isFetching : isActivityFetching
  } = useGetIssueActivityQuery({ data }, { skip: !isSuccess || type === "rmas" || data?.data?.length === 0});
  
  const {
    data: historyDetails,
    error: historyError,
    isLoading: isHistoryLoading,
    isFetching: isHistoryFetching,
    isSuccess: isHistorySuccess,
  } = useGetIssueActivityQuery({ limit:10, data:selectedIssueIDs, node_type:["Todo","Resolution"] }, { skip: type==="rmas" || (Object.keys(selectedIssueIDs).length === 0 || !childDrawerVisible)});

  const prefetchTodoHistory = usePrefetch('getIssueActivity');

  const {
    data: FTNotes,
    error: FTNotesError,
    isLoading: isFTNotesLoading,
    refetch: refetchNotes,
    isFetching: isFTNotesFetching,
    isSuccess: isFTNotesSuccess,
  } = useGetFTNotesQuery({ticket_id:todoSelected['issue_id']}, {skip: type === "rmas" || (!drawerVisible)});

  const {
    data: auditRackInfo,
    error: auditRackError,
    isLoading: isAuditRackLoading,
    isFetching: isAuditRackFetching
  } = useGetAuditRackQuery({data_center_id, rack_number:todoSelected['rack_number'], machine_number:todoSelected['hardware_asset_machine_number']}, { skip: type==="rmas" || (Object.keys(todoSelected).length === 0) || (!todoSelected['rack_number'] && !todoSelected['hardware_asset_machine_number'])});

  const {
    data: shipmentInfo,
    error: shipmentError,
    isFetching: isShipmentFetching,
  } = useGetShipmentInfoQuery({ data_center_id, data:selectedIssueIDs}, { skip: Object.keys(selectedIssueIDs).length == 0 || !shipmentTableVisible});

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let auditRackInfoData;
  if(auditRackInfo){
    if(auditRackInfo.data){
      auditRackInfoData=auditRackInfo.data
    }
  }
  let todosList = [];
  let csvList = [];
  let filename = "";
  let isokResponseData = null;
  api.setLastPath(window.location.href);
  const [filteredInfo, setFilteredInfo] = useState({})

  const dcAccessRequired = (error) => {
    if(error?.status === 401 && error?.data?.message == "Authorization failed") {
      alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
      navigate('/datacenters');
    } else {
      api.trigger401(error,setErrormsg)
    }
  }

  useEffect(() => {
    if (error) {
      console.log(error);
      dcAccessRequired(error);
    }

    if (activityError) {
      console.log(activityError);
      dcAccessRequired(activityError);
    }

    if(historyError) {
      console.log(historyError);
      dcAccessRequired(historyError);
    }

    if(shipmentError) {
      console.log(shipmentError);
      dcAccessRequired(shipmentError);
    }

    if(auditRackError) {
      console.log(auditRackError);
      dcAccessRequired(auditRackError);
    }

    if(isOkAttachmentError) {
      console.log(isOkAttachmentError)
      api.trigger401(isOkAttachmentError, setErrormsg)
    }
    
    if(FTNotesError) {
      console.log(FTNotesError);
      dcAccessRequired(FTNotesError);
    }
  }, [error, activityError, historyError, shipmentError, auditRackError, isOkAttachmentError, FTNotesError]);

  useEffect(() => {
    if(!isOkAttachmentFetching && isOkAttachment && isOkAttachment.data){
      if(!testResult.value){
        let {result,message,updated_date} = isOkAttachment.data
        if(result){
          result=result.map(x=>x.replace(/[{}"]/g, ''))
          result=result.map(x=>x.replace(/\\n\s*/g, '\n'))
          setTestResult({value: result, show: true, updated_date: updated_date})
          formsubmit.setFieldsValue({"Comments":message})
        }
      }
    }
  }, [isOkAttachment,isOkAttachmentFetching])

  useEffect(() => {
    if(issueDetails && drawerVisible){
      setTodoSelected(todosList[selectedIndex]);
    }
  }, [issueDetails])

  useEffect(() => {
    if(data && data?.data.length === 0){
      navigate('/datacenters');
      navigate(0)
    }
  }, [data])

  if(data) {
    if(data.success) {
      console.log(data);
      window.localStorage.setItem("issues", JSON.stringify(data));
    }
  }

  if (data) {
    if (!data.success) {
      console.log("failed");
    }
    todosList = data.data.map((row, index) => ({
      key: index,
      ...row,
    }));
    csvList = todosList.slice();
    if(csvList.length !== 0){
      filename = csvList[0].data_center_name;
    }
  }

  if (issueDetails) {
    let issuesActivity = {};
    for (let data of issueDetails.data) {
      issuesActivity[data.issue_id.toString()] = {
        summary: data.summary,
        activity_id: data.activity_id,
      };
    }
    window.localStorage.setItem("issueActivity", JSON.stringify(issuesActivity))
    todosList = todosList.map((item) => ({
      summary:
      item.issue_id in issuesActivity
      ? issuesActivity[item.issue_id].summary
      : "N/A",
      activity_id:
      item.issue_id in issuesActivity
      ? issuesActivity[item.issue_id].activity_id
      : "N/A",
      ...item,
    }));
    csvList = todosList.map((item) => ({
      ...item,
      summary: item.summary.replace(/(\r\n|\n|\r|\t|\n\r|,)/gm, ""),
      SLA: item.SLA ? Math.round(parseInt(item.SLA)) + "%" : "0%",
    }));
  }

  const showDrawer = (record) => {
    setDrawerVisible(true);
    setTodoSelected(record);
    setSelectedIndex(record.key);
  };

  const { confirm } = Modal;

  const showrack = (record) => {
    setRackDrawerVisible(true);
    setTodoSelected(record);
    setSelectedIndex(record.key);
  };

  const closeRack  = () => {
    setRackDrawerVisible(false);
    setTodoSelected({});
    setSelectedIndex(null);
  }

  const onClose = () => {
    setDrawerVisible(false);
    setIsModalVisible(false)
    setForm(false);
    setUnknownAskInfo(false);
    setTodoSelected({});
    setSelectedIndex(null);
    formsubmit.resetFields();
    setTestResult({value: ''});
    startResolutionSpinner(false);
    startIsOkSpinner(false);
    setShipmentTableVisible(false);
  };

  const checkClose = () => {
    let len_comment = formsubmit.getFieldValue("Comments")
    let len_tracking = formsubmit.getFieldValue("Return Tracking")
    len_comment = len_comment? len_comment : ""
    len_tracking = len_tracking? len_tracking : ""
    if (len_comment.length == 0 && len_tracking.length == 0)
    {
      onClose()
    }

    else{
      displayModal()
    }
  };

  const displayModal =() => {
    // setDrawerVisible(true);
      confirm({
        title: 'Are you sure, you want to close it?',
        icon: <ExclamationCircleOutlined />,
        content: 'All Progress will be reset if closed. ',
        onOk:{handleCancel},
        onCancel:{handleOk},
        okText: "No",
        cancelText: "Yes",
        onOk() {
        },
        onCancel() {
          setDrawerVisible(false);
          onClose()
        },
      });
  }

  const renderFTNotesChildDrawer = () => {
    let comment;
    if(!isFTNotesLoading && FTNotes && FTNotes.data.length !== 0){
      comment = <List
      className="comment-list"
      header={`${FTNotes.data.length} comments`}
      itemLayout="horizontal"
      dataSource={FTNotes.data}
      renderItem={item => {
        return (
        <li>
          <Comment
            avatar={<Avatar style={{ backgroundColor: '#67b2f7db', verticalAlign: 'middle' }} size="medium" gap={1}>{item.added_by ? item.added_by[0] : "!"}</Avatar>}
            author={<div style={{fontWeight: 500, color: "rgb(24, 144, 255)"}}>{item.added_by}</div>}
            content={<div style={{whiteSpace: "pre-line", backgroundColor: "rgba(148, 147, 146, 0.21)", padding: "10px", borderRadius: "8px"}}>{item.comment}</div>}
            datetime={<div style={{fontWeight: 500, color: "rgb(24, 144, 255)"}}>{moment.utc(item.added_date).local().format("ddd, DD MMM YYYY HH:mm")}</div>}
          />
        </li>
        )}}
      />
  }
    return(
      <>
          <Drawer
            title="Notes"
            placement="right"
            width={window.innerWidth > 600 ? "500" : "100%"}
            onClose={()=>{setChildDrawerNotesVisible(false)}}
            visible={childDrawerNotesVisible}
          >
            <Alert
            banner
            message={
              <Marquee pauseOnHover gradient={false}>
              <strong>Note:&nbsp;</strong>This information can only be viewed by the Field Technician who have access to the Ticket id &nbsp;
              </Marquee>
            }
          />
            {
              isFTNotesFetching ? <AkamaiPreloader/>
              :comment
            }
          {renderNotesForm()}
          </Drawer>
          </>
    )
  }

  const closeForm = () => {
    setForm(false);
  };

  const showForm = () => {
    setForm(true);
  };

  const showInfo = () => {
    setUnknownAskInfo(true);
    setTodoSelected(todosList[selectedIndex]);
  };

  const sorter = (a, b, field) => {
    if (a && a[field] && a[field].length && b && b[field] && b[field].length)
      return a[field].length - b[field].length;
    else if (a && a[field] && a[field].length) return -1;
    else if (b && b[field] && b[field].length) return 1;
    return 0;
  };

  const strCompare = (a, b, key) => {
    if (!a[key]) a[key] = "N/A";
    if (!b[key]) b[key] = "N/A";
    return a[key].localeCompare(b[key]);
  };

  const addResolution = async (values) => {
    try {
      setDisableSubmit(true);
      setErrormsg(null);
      startResolutionSpinner(true);
      let payload;
      console.log(values, type);
      if (!values) return;
      if (type === "todos") {
        payload = {
          comments: values.Comments + " " || " ",
          issue_id: todosList[selectedIndex].issue_id,
          relatedActivityId: todosList[selectedIndex].last_todo_act_id,
          username: userDetails?.name.toUpperCase(),
          return_tracking: values["Return Tracking"],
          resolution_type: (values["checkbox-group"] && values["checkbox-group"].length !== 0) ? values["checkbox-group"].join(", ") : "Other",
          problem_type: todosList[selectedIndex].problem_type,
          resolution: values.Comments,
          timezone,
          updated_date: moment().format("YYYY-MM-DD"),
        };
        if (values["checkbox-group"] && values["checkbox-group"].length > 0) {
          if (payload.comments) {
            payload.comments += values["checkbox-group"].join(", ");
          } else {
            payload.comments = values["checkbox-group"].join(", ");
          }
        }
        if (values.HardwareTestResults && testResult.value){
          payload.comments += " ISOK results: " + testResult.value
        }
      } else if (type === "rmas") {
        payload = {
          waybill_number: todosList[selectedIndex].waybill_number,
          line_id: todosList[selectedIndex].order_item_id,
          comments: values.Comments,
          order_id: todosList[selectedIndex].order_id,
          return_tracking: values["Return Tracking"],
          resolution_type: "Other",
          problem_type: todosList[selectedIndex].problem_type,
          resolution: values.Comments,
          issue_id: todosList[selectedIndex].issue_id,
          username: userDetails?.name.toUpperCase(),
          timezone,
          updated_date: moment().format("YYYY-MM-DD"),
          ticket_status: todosList[selectedIndex].ticket_status
        };
      }
      console.log(payload, type, data_center_id);
      // if(values.Comments && values['Return Tracking']){
        const resp = await triggerAddResolution({
          data_center_id,
          type,
          payload,
        }).unwrap();
        if (resp.success) {
          api.openNotification("Success!", "success", "Resolution Added");
          setDisableSubmit(false);
          refetch();
        }
      // }
      // else{
      //   api.openNotification("Failed!", "fail", "Empty form values!");
      // }
    } catch (error) {
      api.trigger401(error);
    } finally {
      onClose();
      setDisableSubmit(false);
      startResolutionSpinner(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    // formsubmit.resetFields();
  };

  const showModal = () => {
      setIsModalVisible(true);
    };

  const handleOk = () => {
      sessionStorage.removeItem(todoSelected.issue_id);
      clearInterval(timer);
      setIsModalVisible(false);
      setTestResult({value: ''});
      onClose();
      startResolutionSpinner(false);
    };

  const handleCancel = () => {
      setIsModalVisible(false);
    };

  const getAnswer = async () => {
    try{
      if(sessionStorage.getItem(todoSelected.issue_id)){
        let payload = {
          "id": sessionStorage.getItem(todoSelected.issue_id),
        }
        isokResponseData = await triggerGetResolution({ payload }).unwrap();
        if(isokResponseData.data.length > 0){
          if(isokResponseData.data[0].result.includes("INVALID") || isokResponseData.data[0].result.includes("COMPLETE") || isokResponseData.data[0].result.includes("FAILED")){
            startIsOkSpinner(false);
            api.openNotification("Success!", "success", "ISOK results are added in input section.");
            let result_detail = isokResponseData.data[0].resultDetail.map(x=>x.result.replace(/[{}"]/g, ''))
            result_detail=result_detail.map(x=>x.replace(/\\n\s*/g, '\n'))
            setTestResult({value: result_detail, show: true});
            sessionStorage.removeItem(todoSelected.issue_id);
            if(isokResponseData.data.resultDetail){clearInterval(timer);}
          }
        }
      }
    }
    catch (error){
      api.trigger401(error);
      sessionStorage.removeItem(todoSelected.issue_id)
      clearInterval(timer);
      startIsOkSpinner(false);
      setTestResult({value: ''});
    }
  }

  const runHardwareTest = async() => {
  try {
      setErrormsg(null);
      startIsOkSpinner(true);
      setDisableCancel(true);
      if (!todoSelected.ip_asset_ip_address) {
          api.openNotification("Invalid IP!", "fail");
          return;
      }
      let payload = {
          "targets": [todoSelected.ip_asset_ip_address],
          "force": true,
          "triggered_by": "isokToDo",
          "ticket_id": todoSelected.issue_id
      }
      const resp = await triggerIsOk({ payload }).unwrap();
      sessionStorage.setItem(todoSelected.issue_id, resp.data.id);
      if(timer){
        clearInterval(timer);
      }
      timer = setInterval(() => {
        getAnswer();
      }, 25000)
      if(resp.data.id){
        api.openNotification("Success!", "success", "ISOK job submitted. Please wait for the results!");
        setDisableCancel(false);
      }
    } catch (error) {
      api.trigger401(error);
      setDisableCancel(false);
      startIsOkSpinner(false);
    }
  }

  const fetchHistory = (issue_id) =>{
    // console.log("fetching History for issue IDs", issue_id);
    if( selectedIssueIDs && selectedIssueIDs.data && issue_id === selectedIssueIDs.data[0].issue_id){
      // console.log("History already present for issue IDs", issue_id)
    }
    else{
      let issueID = {"data":[{"issue_id":issue_id}]}
      setSelectedIssueIDs(issueID);
      prefetchTodoHistory({data_center_id, limit:10, data:issueID, node_type:["Todo","Resolution"]});
    }
  }

  const fetchIsOkResultAttached = (issue_id) =>{
    triggerGetIsOkAttachment({payload:{ticket_id:issue_id}})
  }

  const beautifyTestResult = (testResult) => {
    const items = testResult.map(result=>(<Descriptions.Item style={{whiteSpace: "pre-wrap"}} span={24} key={result}>{result}</Descriptions.Item>))
    return (<>{items}</>)
  }
  const [showcol, setshowcol] = useState(["issue_id","is_safe",
                                      "ecor_name","region_number","priority",
                                      "delegated_age","SLA","rack_number","hardware_asset_machine_number",
                                      "ip_asset_ip_address","current_hardware_serial_number","server_type","waybill_number",
                                      "order_id","status","age","asset_serial_number", "compute_score"
                                      ]);

  const [isActive,setIsActive] = useState([

  ]);

  const onadd = (e) => {
    isActive.find(di=> di.dataIndex===e) ? setIsActive([...isActive.filter((val )=> {return val.dataIndex!==e})]): setIsActive([...isActive,{dataIndex:e}]) ;
  }

 useEffect(()=>{
   const testdata = window.localStorage.getItem('ft-portal-col');
    if(testdata!=null) setshowcol([...JSON.parse(testdata)])
  },[])

  useEffect(()=>{
    window.localStorage.setItem('ft-portal-col',JSON.stringify(showcol))
  },[showcol])


  useEffect(()=>{
    const testdata = window.localStorage.getItem('ft-portal-bookmark');
     if(testdata!=null) setIsActive([...JSON.parse(testdata)])
   },[])

   useEffect(()=>{
     window.localStorage.setItem('ft-portal-bookmark',JSON.stringify(isActive))
   },[isActive])

  const onChange = (e) => {
    // setshowcol([...showcol,e.target.name])
    e.target.checked ? (setshowcol([...showcol,e.target.name])) : (setshowcol([...showcol.filter((val)=>{return val!=e.target.name})]));
    // getColumns();
  };

  // const fetchRacks = () =>{
  //     setSelectedIssueIDs(issueID);
  //     prefetchTodoHistory({data_center_id, limit:10, data:issueID, node_type:["Todo","Resolution"]});
  // }

  const getColumns = () => {
    let columns = [
      {
        title: "",
        dataIndex: "issue_id",
        key: "bookmark",
        align: "center",
        render: (text, record) => (
           <Button style={{color:"#fadb15"}} type="text" onClick={() => onadd(record.issue_id)}> {isActive.find(di=> di.dataIndex==record.issue_id) ?<StarFilled/> :<StarOutlined/>}</Button>
        ),
        sorter: (a, b) => isActive.find(di=> di.dataIndex===a.issue_id)!=undefined,
        show: "both",
        className:"bookmark",
        width: "1%"
      },
      {
        title: "Ticket Number",
        dataIndex: "issue_id",
        key: "issue_id",
        align: "center",
        render: (text, record) => (
           <div style={{textAlign:"left"}}>
           {/* <Button style={{color:"#fadb15"}} type="text" onClick={() => onadd(record.issue_id)}> {isActive.find(di=> di.dataIndex==record.issue_id) ?<StarFilled/> :<StarOutlined/>}</Button> */}
           <a href="#!" onClick={() => {
            showDrawer(record);
            if(type==="todos"){
              fetchHistory(record.issue_id);
              fetchIsOkResultAttached(record.issue_id)
            }
           }}
           style={{ color: "#1890ff" , marginLeft:"10px"}}
           >
                {text}
              </a>
           {/* <Button
              type="text"
              onClick={() => {
                showDrawer(record);
                if(type==="todos"){
                  fetchHistory(record.issue_id);
                  fetchIsOkResultAttached(record.issue_id)
                }
              }}
              style={{ color: "#1890ff" , paddingRight:"5px"}}
            >{text}
            </Button> */}
            
            <Tooltip title="Open Ticket Details">
              <span>
                <Link to={`/datacenters/${data_center_id}/${type}/${record.issue_id}`} target="_blank" rel="noopener noreferrer" >
                  <Button type="link" style={{padding:0, top:1}}>
                  <Icon component={RedirectIconLarge}/>
                  </Button>
                </Link>
              </span>
            </Tooltip>
            </div>
        ),
        filteredValue:[searchedText],
        onFilter:(value,record)=>{return String(record.summary)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
           String(record.issue_id)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
           String(record.waybill_number)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
           String(record.asset_serial_number)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
           String(record.order_id)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
           String(record.server_type)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
           String(record.rack_number)
           .toLowerCase()
           .includes(value.toLowerCase())
          ||
          String(record.hardware_asset_machine_number)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
          String(record.ip_asset_ip_address)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
          String(record.current_hardware_serial_number)
          .toLowerCase()
          .includes(value.toLowerCase())
          ||
          String(record.asset_serial_number)
          .toLowerCase()
          .includes(value.toLowerCase())
        },
        sorter: (a, b) => a.issue_id.localeCompare(b.issue_id),
        show: "both",
      },
      {
        title: "RMA Number",
        dataIndex: "order_id",
        key: "order_id",
        align: "center",
        sorter: (a, b) => a.order_id.localeCompare(b.order_id),
        show: "rmas",
      },
      {
        title: "Safe",
        dataIndex: "is_safe",
        key: "is_safe",
        align: "center",
        // filters: [
        //   {
        //     text: 'Yes',
        //     value: 'Yes',
        //   },
        //   {
        //     text: 'Unknown Ask',
        //     value: 'Unknown Ask',
        //   },
        // ],
        // onFilter: (value, record) => record.is_safe.indexOf(value) === 0,
        sorter: (a, b) => strCompare(a, b, "is_safe"),
        render: (text) => {
          let icon = "";
          if (!text) return;
          if (text.toLowerCase().includes("unknown")) {
            icon = (
              <ExclamationCircleOutlined
                style={{ color: "#fadb14", fontSize: "150%" }}
              />
            );
          } else if (text.toLowerCase() === "no") {
            icon = (
              <CloseCircleOutlined
                style={{ color: "#db0606", fontSize: "150%" }}
              />
            );
          } else {
            icon = (
              <CheckCircleOutlined
                style={{ color: "#52c41a", fontSize: "150%" }}
              />
            );
          }
          return (
            <Popover content={text} trigger="hover">
              {icon}
            </Popover>
          );
        },
        show: "todos",
      },
      {
        title: "Ecor Name",
        dataIndex: "ecor_name",
        key: "ecor_name",
        align: "center",
        sorter: (a, b) => strCompare(a, b, "ecor_name"),
        render: (text) => (text ? text : "N/A"),

        show: "todos"
      },
      {
        title: "Region Name",
        dataIndex: "region_number",
        key: "region_name",
        align: "center",
        sorter: (a, b) => strCompare(a, b, "region_number"),
        render: (text) => (text ? text : "N/A"),
        show: "todos",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        align: "center",
        sorter: (a, b) => strCompare(a, b, "priority"),
        render: (text) => {
          let color = "";
          if (!text) return;
          text = text.replace(/[^A-Za-z]/g, "");
          if (text.toLowerCase().includes("high")) {
            color = "error";
          } else if (text.toLowerCase().includes("critical")) {
            color = "volcano";
          } else if (text.toLowerCase().includes("medium")) {
            color = "warning";
          } else {
            color = "success";
          }

          return <Tag color={color}>{text}</Tag>;
        },
        show: "both",
      },
      {
        title: "Compute Score",
        dataIndex: "compute_score",
        key: "compute_score",
        show: "todos",
        align: "center",
        sorter: (a,b)=> parseInt(a.compute_score) - parseInt(b.compute_score),
        defaultSortOrder: 'descend',

      },
      // {
      //   title: "Status",
      //   key: "status",
      //   dataIndex: "status",
      //   align: "center",
      //   // defaultSortOrder: 'descend',
      //   render: (text) => (text ? text : "N/A"),
      //   show: "rmas",
      // },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        align: "center",
        // defaultSortOrder: 'descend',
        // render: (text) => (text ? text : "N/A"),
        show: "rmas",
        filters: [
          {
            text: 'Open',
            value: 'Open',
          },
          {
            text: 'Partially Received',
            value: 'Partially Received',
          },
          {
            text: 'Pending',
            value: 'Pending',
          }
        ],
        render:  (record)=>{
            let showText = "N/A";
            if(record === "Open") {
              showText = "Open"
            }
            if(record === "Partially Received" ){
                showText = "Partially Received"
            }
            if(record === "Pending" ){
              showText = "Pending"
            }
            return showText;
          },
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) => (record.status === value)
      },
      {
        title: "Age",
        dataIndex: type === "todos" ? "delegated_age" : "age",
        key: type === "todos" ? "delegated_age" : "age",
        align: "center",
        // defaultSortOrder: 'descend',
        sorter: (a, b) =>
          type === "todos" ? a.delegated_age - b.delegated_age : a.age - b.age,
        render: (text) => (text ? text : "N/A"),
        show: "both",
      },
      {
        title: "SLA",
        dataIndex: "SLA",
        key: "SLA",
        align: "center",
        // defaultSortOrder: 'descend',
        sorter: (a, b) => parseInt(a.SLA) - parseInt(b.SLA),
        render: (text) => (text ? Math.round(parseInt(text)) + "%" : "0%"),
        show: "todos",
      },
      {
        title: "Rack",
        dataIndex: "rack_number",
        key: "rack_number",
        align: "center",
        render: (text, record) =>
          text ? (
            ["ESSL", "CRYPTO", "LLDQ", "Metanet", "NESS", "NEVADA", "Odin"].includes(record.region_network) ? (
              <>
               <a href="#!"
              onClick={() => {
                showrack(record);
              }}
              className="rack-btn"
              style={{ color: "#1890ff" }}
              >
                {text + " "}
                <LockTwoTone />
              </a>
              </>
            ) : (
              <>
                <a href="#!"
              className="rack-btn"
              onClick={() => {
                showrack(record)
              }}
              style={{ color: "#1890ff" }}
              >
                {" " + text}
              </a>  </>
            )
          ) : (
            "N/A"
          ),
        sorter: (a, b) => strCompare(a,b,"rack_number"),
        show: "todos",
      },
      {
        title: "Machine",
        dataIndex: "hardware_asset_machine_number",
        key: "hardware_asset_machine_number",
        align: "center",
        render: (text) => (text ? text : "N/A"),
        sorter: (a, b) => ((a.hardware_asset_machine_number||"N/A").localeCompare(b.hardware_asset_machine_number||"N/A")),
        show: "todos",
      },
      {
        title: "IP Address",
        dataIndex: "ip_asset_ip_address",
        key: "ip_asset_ip_address",
        align: "center",
        render: (text) => (text ? <Link to={`/datacenters/search?ip=${text}`} target="_blank" rel="noopener noreferrer" >{text}</Link> : "N/A"),
        sorter: (a, b) => ((a.ip_asset_ip_address||"N/A").localeCompare(b.ip_asset_ip_address||"N/A")),
        show: "todos",
      },
      {
        title: "Tracking Number",
        dataIndex: "waybill_number",
        key: "waybill_number",
        align: "center",
        show: "rmas",
        render: (text, record) => {
          if (text)
            return (
              <a href="#!" onClick={() => showDrawer(record)}>
                {text}
              </a>
            );
          else {
            return (
              <Tooltip title="Add tracking number">
                <Button
                  type="text"
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    showDrawer(record);
                    showForm(true);
                  }}
                  style={{ color: "#1890ff" }}
                />
              </Tooltip>
            );
          }
        },
      },
      {
        title: "Serial Number",
        dataIndex:
          type === "todos"
            ? "current_hardware_serial_number"
            : "asset_serial_number",
        key:
          type === "todos"
            ? "current_hardware_serial_number"
            : "asset_serial_number",
        align: "center",
        render: (text) => (text ? <Link to={`/datacenters/search?serial_no=${text}`} target="_blank" rel="noopener noreferrer" >{text}</Link> : "N/A"),
        show: "both",
        sorter: (a, b) => {
          let key;
          key =
            type === "todos"
              ? "current_hardware_serial_number"
              : "asset_serial_number";
          return strCompare(a, b, key);
        },
      },
      {
        title: "Server Type",
        dataIndex: "server_type",
        key: "server_type",
        align: "left",
        render: (text) => (text ? text : "N/A"),
        show: "both",
        sorter: (a, b) => strCompare(a, b, "server_type"),
      },
    ].filter((item) => (item.show === "both" || item.show === type) && (showcol.includes(item.dataIndex)));
    return columns;
  };
  //

  const add_FTNotes = async (values) => {
    try {
      let payload = {
        comment: values.Comment,
        ticket_id: todoSelected['issue_id'],
        added_by: userDetails.name
      }
      const resp = await triggerAddFTNotes({ payload }).unwrap();
      if (resp.success) {
        api.openNotification("Success!", "success", "Note added successfully");
        // setDisableSubmit(false);
        refetchNotes();
        formNotes.resetFields();
        setCommentDisabled(true);
      }
    } catch (error) {
      console.log(error);
      api.trigger401(error);
    }
  }
  const renderNotesForm = () => {
    return (
      <Form onFinish={add_FTNotes} form={formNotes}
      // onValuesChange={(changed, all) =>{all.Comment.length === 0 ? setCommentDisabled(true) : setCommentDisabled(false) } }
      >
        <Form.Item
              name="Comment"
              label="Comment"
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                textAlign: "left",
                paddingBottom: 12
              }}
              rules={[{}, {
                validator(rules, value){
                  if(!value){
                    setCommentDisabled(true);
                  }
                  else if(!value.trim()?.length){
                    if(!commentDisabled) setCommentDisabled(true);
                    return Promise.reject(
                      new Error("Comments are required!")
                    )
                  }
                  else{
                    if(commentDisabled) setCommentDisabled(false)
                  }
                  if(value?.length >= 2000){
                    return Promise.reject(
                      new Error("Comments cannot exceed 2000 characters!")
                    )
                  }
                return Promise.resolve()
              }
              }]}
            >
              <Input.TextArea rows={3} maxLength='2000' showCount />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{display: "inline-flex" }}
              disabled = {commentDisabled || isImpersonated}
              loading= {addFTNotes?.isLoading}
              >Submit</Button>
      </Form>
    )
  }

  const renderForm = () => {
    return (
      <>
      <Form onFinish={addResolution} onFinishFailed={onFinishFailed}  form={formsubmit}>
        <p style={{fontSize: "large", fontWeight: "400", paddingTop: "15px"}}>Resolutions</p>
        {type === "todos" && (
          <Form.Item name="checkbox-group">
            <Checkbox.Group>
              <Row>
                <Col span={8}>
                  <Checkbox
                    value="Rebooted"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Rebooted
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Rescued"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Rescued
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Replaced disk"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Replaced disk
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Reseated components"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Reseated components
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Replaced RAM"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Replaced RAM
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Replaced Machine"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Replaced Machine
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Identified Faulty disk"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Identified Faulty disk
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Replaced ETH/Power cable"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Replaced ETH/Power cable
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Clean/Replaced optic"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Clean/Replaced optic
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    value="Other"
                    style={{
                      lineHeight: "32px",
                    }}
                  >
                    Other
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        )}
        {type === "todos" && todoSelected.ip_asset_ip_address && (
              <Descriptions.Item span={3} offset={4}>
              <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "30px", top: "-4px", display: "inline-flex", marginBottom: "10px"}}
                  onClick={() => runHardwareTest()}
                  disabled = {isokSpinner || isImpersonated}
              >Run Hardware Test{isokSpinner && (<LoadingOutlined style={{ fontSize: 20, }} spin />)}</Button>
              {/* <Badge status="success" text="Success"/> */}
              </Descriptions.Item>)}
        {testResult.value && (type === "todos") && !isokSpinner &&(<Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="HardwareTestResults" id="Test Results" label="Hardware Test Results" style={{
                            flexDirection: "column",
                            flexWrap: "wrap",
                            textAlign: "left",
                            marginBottom: "12px"
                        }} initialValue={testResult.value}>

                          <Descriptions colon={true} size="small" labelStyle={{ paddingRight:"0px"}} style={{backgroundColor:"#E8E8E8", padding:"5px 15px"}}>
                            {beautifyTestResult(testResult.value)}
                            {testResult.updated_date && <Descriptions.Item span={24} label="Last Updated Date" labelStyle={{fontWeight:500, fontSize:"12px", fontStyle:"italic"}} contentStyle={{fontWeight:500, fontSize:"12px", fontStyle:"italic"}}>{testResult.updated_date}</Descriptions.Item>}
                          </Descriptions>
                        </Form.Item>
                    </Col>
                </Row>)}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="Return Tracking"
              label="Return Tracking"
              id="Return Tracking"
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                textAlign: "left",
              }}
              rules={type==="todos"?([{whitespace: true}]):([{whitespace: true},{ required: true, message: "Return tracking is required!"}])}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {type==="todos" &&
              <div style={{float:"right", position:"absolute", right:0, zIndex:10}}>
                <Button type='link' onClick={()=>{setChildDrawerNotesVisible(true)}} style={{padding:"0px"}}>
                  <Badge
                    count={isFTNotesFetching ? <ClockCircleOutlined style={{  color: '#f5222d'}}/> : FTNotes?.data.length }
                  >
                      <div style={{color: "#1890ff"}}>
                        Add Notes&nbsp;&nbsp;&nbsp;
                      </div>
                  </Badge>
                </Button>
              </div>
              }
            <Form.Item
              name="Comments"
              label="Comment"
              style={{
                flexDirection: "column",
                flexWrap: "wrap",
                textAlign: "left",
                paddingBottom: 12
              }}
              rules={[{ required: true, message: "Comment is required!"},{whitespace: true, message: "Comment is required!"}]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4} style={{ maxWidth: "none" }}>
            <Form.Item>
              {!resolutionSpinner && (
                <Button
                  type="primary"
                  htmlType="submit"
                  // onClick={() =>submitBtn()}
                  disabled={(!testResult.value && isokSpinner) || isImpersonated}>
                  {/* Submit {isokSpinner && (<LoadingOutlined style={{ fontSize: 20, }} spin />)} */}
                  Submit
                </Button>
              )}
              {resolutionSpinner &&  (
              <Button
                type="primary"
                htmlType="submit"
                style={{display: "inline-flex" }}
                disabled = {true}
              >Submit{resolutionSpinner && <LoadingOutlined style={{ fontSize: 20,color:'grey',marginBottom:2 }} spin />}</Button>
              )
              }
            </Form.Item>
          </Col>
          <Col span={6}>
          <Form.Item>
              <Button htmlType="button" onClick={(isokSpinner? displayModal : checkClose)} disabled={isokSpinner && disableCancel && type==="todos"}>
                  Close
              </Button>
              <Modal maskClosable={false} visible={isModalVisible} closable={false} onOk={handleCancel} onCancel={handleOk} okText="No" cancelText="Yes">
                  <p>Are you sure, you want to close it?</p>
                  <p>All Progress will be reset if closed.</p>
              </Modal>
          </Form.Item>
          </Col>
        </Row>
      </Form>
        {type === "rmas" && <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}} style={{marginBottom:"20px"}}>
        <Descriptions.Item span={3} label="Tracking Number">{ todoSelected.waybill_number ? todoSelected.waybill_number : "N/A"}</Descriptions.Item>
        <Descriptions.Item label="Comments" span={3}>{todoSelected.comments?todoSelected.comments:'N/A'}</Descriptions.Item>
        <Descriptions.Item span={3} label="Last Updated">{ todoSelected.updated ? moment.utc(todoSelected.updated).local().format("DD-MM-YYYY HH:mm:ss") : "N/A"}</Descriptions.Item>
      </Descriptions>}
      </>
    );
  };

  const renderChildDrawer = () => {
    let items=[]
    if(!isHistoryLoading && historyDetails){
      items = historyDetails.data.map(item => {
        return (
          <Timeline.Item key={item.created_date} color={item.node_type === "Todo"? "blue": "green"}>
          <p style={{fontWeight:500, color:"#1890ff"}}>{item.node_type} added by {item.created_by_login} on {item.created_date.substring(0,10)} at {item.created_date.substring(11,16)}</p>
          <p style={{ whiteSpace: "pre-line",backgroundColor: "rgb(148 147 146 / 21%)",padding: "15px",borderRadius: "8px", }}>{item.summary}</p>
        </Timeline.Item>)
    })
  }
    return(
          <Drawer
            title="Todo History"
            placement="right"
            width={window.innerWidth > 600 ? "500" : "100%"}
            onClose={()=>{setChildDrawerVisible(false)}}
            visible={childDrawerVisible}
          >
            {
              isHistoryFetching ? <AkamaiPreloader/>
              :(
                <Timeline>
                  {items.reverse()}
                </Timeline>
                )
            }
          </Drawer>
    )
  }

  const rendershipmentinfo = () =>{
    if (shipmentTableVisible){
      return (
            <Descriptions size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}} style={{marginBottom:"20px"}}>
                <DataTables dataSource={!isShipmentFetching && shipmentInfo?.data} columns={ShipmentColumns} visible={shipmentTableVisible}
                rowKey="tracking_number" size="small" loading={isShipmentFetching} scroll={{}} locale={{emptyText: "No Data"}}/>
            </Descriptions>
            )}
  }

  const todos_headers = [
    { label: "data_center_id", key: "data_center_id" },
    { label: "data_center_name", key: "data_center_name" },
    { label: "data_center_location", key: "data_center_location" },
    { label: "issue_id", key: "issue_id" },
    { label: "is_safe", key: "is_safe" },
    { label: "ecor_name", key: "ecor_name" },
    { label: "region_number", key: "region_number" },
    { label: "priority", key: "priority" },
    { label: "compute_score", key: "compute_score" },
    { label: "delegated_age", key: "delegated_age" },
    { label: "SLA", key: "SLA" },
    { label: "rack_number", key: "rack_number" },
    { label: "hardware_asset_machine_number", key: "hardware_asset_machine_number" },
    { label: "ip_asset_ip_address", key: "ip_asset_ip_address" },
    { label: "hardware_asset_serial_number", key: "hardware_asset_serial_number" },
    { label: "server_type", key: "server_type" },
    { label: "abstract", key: "abstract" },
    { label: "delegated_status", key: "delegated_status" },
    { label: "problem_type", key: "problem_type" },
    { label: "sub_type", key: "sub_type" },
    { label: "deployment_project_name", key: "deployment_project_name" },
    {label:"summary",key:"summary"}
  ];

  const rmas_headers = [
    { label: "data_center_name", key: "data_center_name" },
    { label: "issue_id", key: "issue_id" },
    { label: "order_id", key: "order_id" },
    { label: "order_item_id", key: "order_item_id" },
    { label: "priority", key: "priority" },
    { label: "status", key: "status" },
    { label: "age", key: "age" },
    { label: "waybill_number", key: "waybill_number" },
    { label: "asset_serial_number", key: "asset_serial_number" },
    { label: "server_type", key: "server_type" },
    { label: "quantity_requested", key: "quantity_requested" },
    { label: "due_date", key: "due_date" },
  ];

  const rackDrawerInfo =
    <div>
      { todoSelected.rack_number && todoSelected.hardware_asset_machine_number && 
        <Collapse  bordered={false} style={{backgroundColor:"rgb(255,255,255)"}}>
          <Panel header={`Rack - ${todoSelected.rack_number}`} key="1" style={{border:0,overflow:"hidden",marginBottom:12 ,backgroundColor:"rgb(248,248,248)",fontWeight:500}}>
            { isAuditRackFetching ? < AkamaiPreloader /> :
              <Descriptions bordered column={1} style={{fontWeight:"normal", backgroundColor:"white"}}>
                <Descriptions.Item label="Floor">{auditRackInfoData && auditRackInfoData[0]?.['R_floor'] ? auditRackInfoData[0]['R_floor']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Row">{auditRackInfoData && auditRackInfoData[0]?.['R_row'] ? auditRackInfoData[0]['R_row']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Column">{auditRackInfoData && auditRackInfoData[0]?.['R_column'] ? auditRackInfoData[0]['R_column']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Type">{auditRackInfoData && auditRackInfoData[0]?.['type'] ? auditRackInfoData[0]['type']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Post Width">{auditRackInfoData && auditRackInfoData[0]?.['post_width'] ? auditRackInfoData[0]['post_width']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Holes">{auditRackInfoData && auditRackInfoData[0]?.['holes'] ? auditRackInfoData[0]['holes']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Cage Number">{auditRackInfoData && auditRackInfoData[0]?.['cage_number'] ? auditRackInfoData[0]['cage_number']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Circuits Per Rack">{auditRackInfoData && auditRackInfoData[0]?.['circuits_rack'] ? auditRackInfoData[0]['circuits_rack']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Voltage">{auditRackInfoData && auditRackInfoData[0]?.['voltage'] ? auditRackInfoData[0]['voltage']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Amps Per Circuit">{auditRackInfoData && auditRackInfoData[0]?.['amps_circuit'] ? auditRackInfoData[0]['amps_circuit']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Outlets Per Circuit">{auditRackInfoData && auditRackInfoData[0]?.['outlets_circuit'] ? auditRackInfoData[0]['outlets_circuit']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Outlet Type">{auditRackInfoData && auditRackInfoData[0]?.['outlet_type'] ? auditRackInfoData[0]['outlet_type']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Lock Combination">{auditRackInfoData && auditRackInfoData[0]?.['assigned_locks'] ? auditRackInfoData[0]['assigned_locks']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Status">{auditRackInfoData && auditRackInfoData[0]?.['Active'] ? (auditRackInfoData[0]['Active'] === 'Y' ? "Active" : "Inactive"): "NA"}</Descriptions.Item>
              </Descriptions>
            }
          </Panel>
          <Panel header={"Machine Details - " + " " + todoSelected.hardware_asset_machine_number} key="2" style={{border:0,overflow:"hidden",backgroundColor:"rgb(248,248,248)",fontWeight:500}}>
            { isAuditRackFetching ? < AkamaiPreloader /> :
              <Descriptions bordered column={1} style={{fontWeight:"normal", backgroundColor:"white"}}>
                <Descriptions.Item label="Asset Tag">{auditRackInfoData && auditRackInfoData[0]?.['assest_tag'] ? auditRackInfoData[0]['assest_tag']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Serial Number">{auditRackInfoData && auditRackInfoData[0]?.['serial_number'] ? auditRackInfoData[0]['serial_number']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Product Type">{auditRackInfoData && auditRackInfoData[0]?.['product_name'] ? auditRackInfoData[0]['product_name']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="IP Address">{auditRackInfoData && auditRackInfoData[0]?.['ip_address'] ? auditRackInfoData[0]['ip_address']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="NetMask">{auditRackInfoData && auditRackInfoData[0]?.['net_mask'] ? auditRackInfoData[0]['net_mask']: "NA"}</Descriptions.Item>
                <Descriptions.Item label="Gateway">{auditRackInfoData && auditRackInfoData[0]?.['gateway'] ? auditRackInfoData[0]['gateway']: "NA"}</Descriptions.Item>
              </Descriptions>
            }
          </Panel>
        </Collapse>
      }
    </div>

  const renderRackDrawer = () => {
    return(
      <Drawer
      title={`Rack - ${todoSelected.rack_number} (${todoSelected.issue_id})`}
      placement="right"
      visible={rackDrawer}
      bodyStyle={{
        minWidth: "320px",
      }}
      closable={true}
      onClose={closeRack}
      width={window.innerWidth > 600 ? "40%" : "100%"}
      maskClosable={false}
    >
      {isAuditRackFetching ? <AkamaiPreloader style={{ position: "center" }} /> : (rackDrawerInfo)}
    </Drawer>
    )
  }

  const showTotal = (total) =>
    `Total ${total} ${type === "todos" ? "TODOs" : "RMAs"} `;

  const getTitle = () => {
    let title = "";
    title = type === "todos" ? "TODOs - " : "RMAs - ";
    if(data && todosList.length && todosList[0].data_center_name) title += todosList[0].data_center_name;
    if(type==="todos" && data && todosList.length && todosList[0].data_center_location) title += `, ${todosList[0].data_center_location}`
    if(type==="rmas" && dc_data && dc_data.data && dc_data.data.length) title += `, ${dc_data.data[0].location}`
    return title;
  }

  return (
    <>
      <Header title={getTitle()} />
      {errorMsg && <Banner msg={errorMsg} type="error" />}
      {isSuccess &&
        !error &&
        !activityError &&
        !sessionStorage.getItem('todoDataWarningSkip') &&
        todosList.length &&
        type === "todos" && (
          <Banner
            msg="Data shown here is not real-time and can be up to 10 minutes old"
            type="info"
          />
        )}
        <Row>
         <Col lg={18} md={14} xs={24}>
            <Breadcrumbs />
         </Col>
         <Col className="search-menu-wrapper" lg={6} md={10}  xs={24}>
         <Popover content={type==="todos"?"Hint: Ticket no. / TODO description text/ Rack no./ Machine no./ Serial no./ IP address":"Hint: Ticket number/ Rma / Tracking number/ Serial no./ Server type"}trigger="hover">

            <Input  placeholder="Search" style={{width:200}}
              allowClear
              onChange={(e)=>{
                setSearchedText(e.target.value)
              }}
              />
          </Popover>
          <Button type="primary" style={{backgroundColor:"transparent",color:"black",border:"none",marginLeft:2,boxShadow:"none"}} disabled={isFetching || isActivityFetching} >
            <CSVLink headers={type==="todos"?todos_headers:rmas_headers}  data={csvList} filename={`${type==="todos"?"TODO_export_":"RMA_export_"}${filename}.csv`} >{<DownloadOutlined />}</CSVLink>
          </Button>
          <Menu
                mode="horizontal"
                defaultSelectedKeys={["mail"]}
                onClick={(e) => e.stopPropagation()}
              >
                <Menu.SubMenu key="SubMenu" style={{backgroundColor:"#f0f2f5"}} title={<EyeOutlined/>}>
                  <Menu.Item key="issue_id">
                    <Checkbox checked disabled name="issue_id" onChange={onChange}>
                    Ticket Number
                    </Checkbox>
                  </Menu.Item>
                  {type==="rmas" ?
                  <>
                  <Menu.Item key="order_id">
                    <Checkbox  checked disabled name="order_id" onChange={onChange}>
                    RMA Number
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="waybill_number">
                    <Checkbox checked disabled name="waybill_number" onChange={onChange}>
                      Tracking Number
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="status">
                    <Checkbox defaultChecked={showcol.includes("status")} name="status" onChange={onChange}>
                      Server Status
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="age">
                    <Checkbox defaultChecked={showcol.includes("age")} name="age" onChange={onChange}>
                      Age
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="asset_serial_number">
                    <Checkbox defaultChecked={showcol.includes("asset_serial_number")} name="asset_serial_number" onChange={onChange}>
                      Serial Number
                    </Checkbox>
                  </Menu.Item>
                  </>
                  :
                  <>
                  <Menu.Item key="is_safe">
                    <Checkbox defaultChecked={showcol.includes("is_safe")} name="is_safe" onChange={onChange}>
                      Safe
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="ecor_name">
                    <Checkbox defaultChecked={showcol.includes("ecor_name")} name="ecor_name" onChange={onChange}>
                    Ecor Name
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="region_number">
                    <Checkbox defaultChecked={showcol.includes("region_number")} name="region_number" onChange={onChange}>
                      Region
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="delegated_age">
                    <Checkbox defaultChecked={showcol.includes("delegated_age")} name="delegated_age" onChange={onChange}>
                    Age
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="SLA">
                    <Checkbox defaultChecked={showcol.includes("SLA")} name="SLA" onChange={onChange}>
                    SLA
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="rack_number">
                    <Checkbox defaultChecked={showcol.includes("rack_number")} name="rack_number" onChange={onChange}>
                    Rack
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="hardware_asset_machine_number">
                    <Checkbox defaultChecked={showcol.includes("hardware_asset_machine_number")} name="hardware_asset_machine_number" onChange={onChange}>
                    Machine
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="ip_asset_ip_address">
                    <Checkbox defaultChecked={showcol.includes("ip_asset_ip_address")} name="ip_asset_ip_address" onChange={onChange}>
                  IP Address
                    </Checkbox>
                  </Menu.Item>
                  <Menu.Item key="current_hardware_serial_number">
                    <Checkbox defaultChecked={showcol.includes("current_hardware_serial_number")} name="current_hardware_serial_number" onChange={onChange}>
                    Serial Number
                    </Checkbox>
                  </Menu.Item>
                  </>
                   }
                  <Menu.Item key="priority">
                    <Checkbox defaultChecked={showcol.includes("priority")} name="priority" onChange={onChange}>
                    Priority
                    </Checkbox>
                  </Menu.Item>
                  {type === "todos" && <Menu.Item key="compute_score">
                    <Checkbox defaultChecked={showcol.includes("compute_score")} name="compute_score" onChange={onChange}>
                    Compute Score
                    </Checkbox>
                  </Menu.Item>}
                  <Menu.Item key="server_type">
                    <Checkbox defaultChecked={showcol.includes("server_type")} name="server_type" onChange={onChange}>
                    Server
                    </Checkbox>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
          </Col>
        </Row>
      <div className="content-container">
        <div>
          {/* <Table
                        columns={columns}
                        dataSource={todosList}
                        scroll={{
                            x:'max-content',
                        }}
                    /> */}
          <DataTables
            columns={getColumns()}
            dataSource={todosList}
            rowKey="key"
            emptyText="No pending tasks in this Data Center"
            loading={isFetching}
            showTotal={showTotal}
            onChange={(pagination, filters, sorter, extra) => {setFilteredInfo(filters)}}
          />
        </div>
      </div>
      {todoSelected !== {} && (
        <Drawer
          title={<>
            {(type === "todos"
              ? "TODOs - Description ("
              : "RMAs - Tracking Number (") +
            todoSelected.issue_id +
            ") "}
            <Tooltip title="Open Ticket Details">
              <span>
                <Link to={`/datacenters/${data_center_id}/${type}/${todoSelected.issue_id}`} target="_blank" rel="noopener noreferrer" >
                  <Button type="link" style={{padding:0, top:0}}>
                    <Icon component={RedirectIconLarge}/>
                  </Button>
                </Link>
              </span>
            </Tooltip>
          </>}
          placement="right"
          onClose={checkClose}
          visible={drawerVisible}
          bodyStyle={{
            minWidth: "320px",
          }}
          width={window.innerWidth > 600 ? "40%" : "100%"}
          maskClosable={false}
        >
          { type === "todos" &&
            <>
              <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}} style={{marginBottom:"20px"}}>
                <Descriptions.Item label="Problem Type" span={2}>{todoSelected.problem_type?todoSelected.problem_type:'N/A'}</Descriptions.Item>
                {todoSelected.sub_type && <Descriptions.Item label="Sub Type" span={1}>{todoSelected.sub_type}</Descriptions.Item>}
                {todoSelected.deployment_project_name && <Descriptions.Item label="DP name" span={3}>{todoSelected.deployment_project_name}</Descriptions.Item>}
                <Descriptions.Item span={3} label="Rack Number">{todoSelected.rack_number || "N/A"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Machine Number">{todoSelected.hardware_asset_machine_number || "N/A"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Serial Number">{ todoSelected.current_hardware_serial_number || "N/A"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Product Type">{ todoSelected.server_type  || "N/A"}</Descriptions.Item>
                <Descriptions.Item span={3} label="IP Address">{todoSelected.ip_asset_ip_address || "N/A" }</Descriptions.Item>
                <Descriptions.Item span={3} label="NetMask">{ todoSelected.net_mask || "N/A"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Gateway">{ todoSelected.gateway || "N/A"}</Descriptions.Item>
              </Descriptions>
            </>
          }
          <Descriptions colon={false} >
            <Descriptions.Item
              label=""
              span={3}
              style={{ whiteSpace: "pre-line" }}
            >
              {isActivityLoading && <LoadingIcon tip={null} />}
              {!isActivityLoading && type === "rmas" && (
                <p
                  style={{
                    backgroundColor: "rgb(148 147 146 / 21%)",
                    padding: "15px",
                    borderRadius: "8px",
                    flexDirection: "column",
                  }}
                >
                  Return {todoSelected.quantity_requested} {todoSelected.server_type}
                </p>
              )}
              {!isActivityLoading &&
                type === "todos" &&
                ((todoSelected.is_safe === "Unknown Ask" || todoSelected.is_safe === "No") &&
                todoSelected.summary !== "N/A" ? (
                  unknownAskInfo ? (
                    <p
                      style={{
                        backgroundColor: "rgb(148 147 146 / 21%)",
                        padding: "15px",
                        borderRadius: "8px",
                        fontSize: "small",
                      }}
                    >
                      {todoSelected.summary}
                    </p>
                  ) : (
                    <a href="#!" onClick={showInfo}>{`${
                      todoSelected.ip_asset_ip_address
                        ? todoSelected.ip_asset_ip_address
                        : "This ticket"
                      }${
                        todoSelected.is_safe === "Unknown Ask"
                        ? " may not be safe to work on."
                        : " is not safe to work on."
                      } Please contact NIE to determine whether it is safe to proceed. Click this link when you are sure it is safe.`}</a>
                  )
                ) : (
                  <p
                    style={{
                      backgroundColor: "rgb(148 147 146 / 21%)",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    {todoSelected.summary}
                  </p>
                ))}
            </Descriptions.Item>
            {type === "todos" &&
              <Descriptions.Item span={3} offset={4}>
                <Button type='link' onClick={()=>{setChildDrawerVisible(true)}} style={{padding:"0px",display:"block"}}>Show Ticket History</Button>
              </Descriptions.Item>
            }
            {type === "todos" &&
            <Descriptions.Item span={3} offset={4}>
                {!shipmentTableVisible &&
                  <Button type='link' onClick={()=>{setShipmentTableVisible(true)}} style={{padding:"0px",display:"block"}}>Show Shipment Details</Button>}
                {shipmentTableVisible &&
                <Button type='link' onClick={()=>{setShipmentTableVisible(false)}} style={{padding:"0px",display:"block"}}>Hide Shipment Details</Button>}
            </Descriptions.Item>}
            {shipmentTableVisible && rendershipmentinfo()}
            {!form  && showForm()}
          </Descriptions>
          {form && renderForm()}

          {childDrawerVisible && renderChildDrawer()}
          {childDrawerNotesVisible && renderFTNotesChildDrawer()}
        </Drawer>
      )}
       {renderRackDrawer()}
    </>
  );
}

export default function TodoList({ type, isImpersonated }) {
  let props = { type, isImpersonated };
  return BaseLayout(TodoListWrapper, props);
}
