import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { message } from "antd";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: new URL("user", process.env.REACT_APP_FT_PORTAL_URL).toString(),
  }),
  endpoints: (build) => ({
    verifyUser: build.mutation({
      query: (token) => {
        return {
          url: "/verify",
          method: "POST",
          body: { token },
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
    }),
    loginUser: build.mutation({
      query: () => {
        return {
          url: "/login",
          method: "POST",
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    logoutUser: build.mutation({
      query: () => {
        return {
          url: "/logout",
          method: "POST",
          body: {
            id_token: localStorage.getItem("id_token"),
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
    }),
    reportIssue: build.mutation({
      query: (values) => {
        let file_keys = []
        const content = values.values;
        const formData = new FormData()
        formData.append("subject", "FT portal user has reported a Bug/Enhancement")
        for(let key in content) {
          formData.append(key, content[key])
        }
        if(content?.files?.fileList && content?.files?.fileList.length > 0){
          for(let i = 0; i<content.files.fileList.length;i++)
          {
            if(content.files.fileList[i]?.response?.name){
              file_keys.push(content.files.fileList[i].response.name)
            }
            else {
              message.error("Remove the >2mb file!")
            }
          }
          formData.append('file_keys', file_keys)
        }
        
        return {
          url: "/report-issue",
          method: "POST",
          body: formData,
          headers: {
            Authorization: localStorage.getItem("token")
          }
        };
      }
    }),
    getUser: build.query({
      query: (sub) =>{
        return {
            url: 'user_details/?sub='+sub,
            method: 'GET',
            headers: {
              Authorization: localStorage.getItem("token"),
            },
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getContactID: build.mutation({
      query: (payload) => {
        return {
          url: 'contactID',
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          body: {
            ...payload
          }
        }
      }
    }),
    updateUser: build.mutation({
      query: (content) => {
        return {
          url: "/user_details",
          method: "PATCH",
          body:{
            ...content
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      }
    }),
  }),
});

export const {
  useVerifyUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useReportIssueMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useLazyGetUserQuery,
  useGetContactIDMutation
} = userApi;
