import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setImpersonationHeaders } from "../impersonateApi";

export const clientUtilsApi = createApi({
    reducerPath: "clientUtilsApi",
    baseQuery: fetchBaseQuery({
      baseUrl: new URL("clientutils", process.env.REACT_APP_FT_PORTAL_URL).toString(),
      prepareHeaders: (headers) => {
        headers.set("Authorization", localStorage.getItem("token"));
        headers = setImpersonationHeaders(headers);
        return headers;
      },
    }),
    endpoints: (build) => ({
        getTicketOrders: build.query({
            query: ({ticket_id}) => {
                return {
                url: `ticket_orders/${ticket_id}`,
                method: "POST"
                }
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
        }),
        getIPDetails: build.query({
            query: ({ip_addr}) => {
                return {
                    url: `ip_details/${ip_addr}`,
                    method: "POST"
                }
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
        })
    })
});

export const {
    useGetTicketOrdersQuery,
    useGetIPDetailsQuery
} = clientUtilsApi;