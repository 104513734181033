import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/antd.min.css";
import "./Dashboard.css";
import Sider from "../Sider/sider";

export default function Dashboard({ isImpersonated }) {
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider isImpersonated={isImpersonated} />
        <Outlet />
      </Layout>
    </>
  );
}
