import React from "react";
import { Layout } from "antd";
import "./Footer.css";

export default function Footer(){
    const {Footer}=Layout
    return(
        <>
          <Footer style={{ textAlign:"center",padding:"10px"}}>
            © {new Date().getFullYear()} Akamai Technologies, Inc. All Rights Reserved. Internal and Confidential.
          </Footer>  
        </>
    )
}