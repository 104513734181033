import axios from "axios";
import { message, notification } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";

function getHeaders() {
  return {
    Authorization: localStorage.getItem("token"),
  };
}

export function dcAccessRequired(error, setErrormsg, navigate) {
  if(error?.status === 401 && error?.data?.message == "Authorization failed") {
    alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
    navigate('/datacenters');
  } else {
    trigger401(error,setErrormsg)
  }
}

export function setMessage(msg, type, timeout = 5) {
  message[type](msg, timeout);
  return null;
}

export function openNotification(
  message,
  type = "fail",
  description = null,
  duration = 5
) {
  let icon;
  if (type === "fail") icon = <CloseCircleTwoTone twoToneColor="red" />;
  else if (type === "success")
    icon = <CheckCircleTwoTone twoToneColor="#52c41a" />;
  else if (type === "info") icon = <InfoCircleTwoTone />;

  notification.open({
    message,
    description,
    icon,
    duration,
  });
}

export async function login() {
  try {
    const resp = await axios.post(
      new URL("user/login", process.env.REACT_APP_FT_PORTAL_URL)
    );
    if (resp && resp.data && resp.data.success) return resp.data.data;
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function triggerLogin() {
  console.log("triggered login");
  let redirectURL = await login();
  if (redirectURL) {
    console.log(`redirect url - ${redirectURL}`);
    window.location.href = redirectURL;
  }
}

export async function logout() {
  try {
    console.log("logout");
    const resp = await axios.post(
      new URL("user/logout", process.env.REACT_APP_FT_PORTAL_URL),
      { id_token: localStorage.getItem("id_token") },
      { headers: getHeaders() }
    );
    // console.log(resp.data.data);

    if (resp && resp.data && resp.data.success) {
      window.location.href = resp.data.data;
      return;
    }
  } catch (error) {
    console.log(error);
  } finally {
    // localStorage.removeItem("token");
    // localStorage.removeItem("id_token");
    sessionStorage.clear();
    localStorage.clear()
  }
}

export function clearData() {
  console.log("clearing data");
  localStorage.removeItem("token");
  sessionStorage.removeItem("lastpath");
  localStorage.removeItem("id_token");
}

export function setToken(token, id_token) {
  if (token) localStorage.setItem("token", token);
  if (id_token) localStorage.setItem("id_token", id_token);
}

export function getToken() {
  let token = localStorage.getItem("token");
  return token;
}

export function getLastPath() {
  return sessionStorage.getItem("lastpath");
}

export function setLastPath(path) {
  sessionStorage.setItem("lastpath", path);
}

export async function validateToken(token) {
  try {
    if (token) {
      const resp = await axios.post(
        new URL("user/verify", process.env.REACT_APP_FT_PORTAL_URL),
        { token }
      );
      // console.log(resp);
      if (resp && resp.data && resp.data.success) return token;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getIsOkData() {
  try {
    const resp = await axios.get(new URL("isok", process.env.REACT_APP_FT_PORTAL_URL), {
      headers: getHeaders(),
    });
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      triggerLogin();
    }
  }
}

export async function pingTool(IPs, method, id, isokData) {
  console.log(IPs);
  let resp;
  try {
    if (method === "PATCH") {
      console.log("patch");
      resp = await axios.patch(
        new URL("isok", process.env.REACT_APP_FT_PORTAL_URL),
        { IPs, id, isokData },
        { headers: getHeaders() }
      );
    } else {
      console.log("post");
      resp = await axios.post(
        new URL("isok", process.env.REACT_APP_FT_PORTAL_URL),
        { IPs },
        { headers: getHeaders() }
      );
    }
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }
  }
}

export async function getDataCenters() {
  try {
    const resp = await axios.get(
      new URL("datacenters", process.env.REACT_APP_FT_PORTAL_URL),
      { headers: getHeaders() }
    );
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      triggerLogin();
    }
  }
}

export function trigger401(error, callback = null) {
  console.log(error);
  if (error.status && error.status === 401) {
    triggerLogin();
    console.log("triggering 401");
  } else {
    console.log("not 401");
    if (callback) {
      if (error && error.data && error.data.message)
        callback(error.data.message);
      else callback("Unexpected Error Occurred! Please contact Administrator.");
      return;
    } else {
      if (error && error.data && error.data.message)
        openNotification("Error!", "fail", error.data.message, 5);
      return;
    }
  }
}

export async function getTodo(dc_id, ticket = null) {
  try {
    if (ticket == null) {
      const resp = await axios.get(
        new URL(`datacenters/${dc_id}/todos`, process.env.REACT_APP_FT_PORTAL_URL),
        { headers: getHeaders() }
      );
      return resp;
    }
    const resp = await axios.get(
      new URL(`datacenter/${dc_id}/todos/${ticket}`, process.env.REACT_APP_FT_PORTAL_URL),
      { headers: getHeaders() }
    );
    console.log(resp);
    return resp;
  } catch (error) {
    console.log(error);
    if (error.response.status === 401) {
      logout();
    }
  }
}

export async function activityLogsAPI() {
  try {
    const resp = await axios.get(
      new URL("activitylogs", process.env.REACT_APP_FT_PORTAL_URL),
      { headers: getHeaders() }
    );
    console.log(resp);
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }
  }
}

export async function updateIssue(issueID, abstract) {
  try {
    const resp = await axios.patch(
      new URL("issue", process.env.REACT_APP_FT_PORTAL_URL),
      { issueID, abstract, headers: getHeaders() }
    );
    console.log(resp);
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }
  }
}

// export async function updateOrder(orderID, description) {
//     const resp = await axios.patch(new URL("order", process.env.REACT_APP_FT_PORTAL_URL), {orderID, description});
//     console.log(resp);
//     return resp;
// }

export async function updateAsset(assetID, comments) {
  try {
    const resp = await axios.patch(
      new URL("asset", process.env.REACT_APP_FT_PORTAL_URL),
      { assetID, comments, headers: getHeaders() }
    );
    console.log(resp);
    return resp;
  } catch (error) {
    if (error.response.status === 401) {
      logout();
    }
  }
}

export function getUserDetails() {
  let token = localStorage.getItem("id_token");
  if(!token) return null;
  try {
    let val = JSON.parse(window.atob(token.split(".")[1]));
    return val;
  } catch (e) {
    console.log(e);
    return null;
  }
}