import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./loading.css";

const AntIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export default function LoadingIcon({ tip, ...props }) {
  return (
    <Spin className="spinner" tip={tip} indicator={AntIcon} size="large" {...props}></Spin>
  );
}
