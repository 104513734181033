import { useEffect, useState } from "react";
import BaseLayout from "./baselayout";
import DataTables from "../components/DataTables/DataTables";
import Header from "../components/Header/Header";
import Banner from "../components/Banner/Banner";
import * as api from "../api";
import moment from "moment";
import { useGetUpcomingShipmentsQuery, useLazyGetTrackingHistoryQuery, useGetSingleDcQuery } from "../services/datacenter";
import { Button, Drawer } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";


function UpcomingShipmentsWrapper({isImpersonated}){

    api.setLastPath(window.location.href);
    const [errorMsg, setErrormsg ] =useState(null)
    const { data_center_id } = useParams();
    const {
        data: dc_data,
        error: dc_error
    } = useGetSingleDcQuery({ dc_id: data_center_id });
    const {data: upcomingShipments, error: shipmentsError, isFetching: shipmentsIsFetching} = useGetUpcomingShipmentsQuery({data_center_id})
    const [triggerGetTrackingHistory, {data: trackingHistory, error: trackingHistoryError, isFetching: trackingHistoryIsFetching }] = useLazyGetTrackingHistoryQuery()
    const [drawerVisible, setDrawerVisible] = useState(false)
    const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null)

    const navigate = useNavigate();
    const dcAccessRequired = (error) => {
        if(error?.status === 401 && error?.data?.message == "Authorization failed") {
            navigate('/datacenters');
            alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
        } else {
            api.trigger401(error,setErrormsg)
        }
    }
    
    useEffect(() => {
        if(upcomingShipments) {
            console.log(upcomingShipments);
        }
        if(dc_data) {
            console.log(dc_data);
        }
        if(dc_error){
            console.log(dc_error);
            // if(dc_error.data) setMessage(error.data.message, "error", 10);
            dcAccessRequired(dc_error);
        }

        if(shipmentsError) {
          console.log(shipmentsError);
          // if(shipmentsError.data) setMessage(error.data.message, "error", 10);
          dcAccessRequired(shipmentsError);
        }
    
        if(trackingHistoryError) {
          console.log(trackingHistoryError)
          // if(trackingHistoryError.data) setMessage(error.data.message, "error", 10);
          api.trigger401(trackingHistoryError, setErrormsg)
        }
      }, [upcomingShipments, dc_data, dc_error, shipmentsError, trackingHistoryError]);

    const columns = [
        {
            title:"Ticket Number",
            key:"issue_id",
            dataIndex:"issue_id",
            sorter: (a,b) => a.issue_id.localeCompare(b.issue_id)
        },
        {
            title:"Order Number",
            key:"order_id",
            dataIndex:"order_id",
            sorter: (a,b) => a.issue_id.localeCompare(b.issue_id)
        },
        {
            title:"Tracking Number",
            key:"waybill_number",
            dataIndex:"waybill_number",
            render: (text) => (
                <Button type="link" onClick={()=>{showDrawer(text)}}>{text}</Button>
            ),
            sorter: (a,b) => a.issue_id.localeCompare(b.issue_id)
        },
        {
            title:"Product Name",
            key:"product_name",
            dataIndex:"product_name"
        },
        {
            title:"Quantity Shipped",
            key:"quantity_shipped",
            dataIndex:"quantity_shipped"
        },
    ]

    const showDrawer = (text) => {
        triggerGetTrackingHistory({tracking_number: text})
        setSelectedTrackingNumber(text)
        setDrawerVisible(true)
    }

    const renderDrawer = () =>{
        const title = `Tracking History - ${selectedTrackingNumber}`
        const drawerColumns = [
            {
                title:"Status",
                key:"STATUS",
                dataIndex:"STATUS"
            },
            {
                title:"Date",
                key:"CREATED_DATE",
                dataIndex:"CREATED_DATE",
                render: (text) => moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")
            }
        ]

        return(
            <Drawer
                title={title}
                placement="right"
                visible={drawerVisible}
                bodyStyle={{
                    minWidth: "320px",
                }}
                width={window.innerWidth > 600 ? "40%" : "100%"}
                maskClosable={false}
                onClose={()=>setDrawerVisible(false)}
            >
                <DataTables
                    columns={drawerColumns}
                    dataSource = {trackingHistory?.data}
                    rowkey="TRACKING_NUMBER"
                    emptyText="No tracking history to show"
                    loading={trackingHistoryIsFetching}
                />
            </Drawer>
        )
    }

    const getTitle = () => {
        let title = `Upcoming Shipments`;
        if(dc_data && dc_data.data && dc_data.data.length) {
            title += ` - ${dc_data.data[0].name}, ${dc_data.data[0].location}`
        }
        return title;
    }
    
    return (
        <>
            <Header title={getTitle()}/>
            {errorMsg && <Banner msg={errorMsg} type="error" />}
            <Breadcrumbs/>
            <div className="content-container" style={{marginTop: "20px"}}>
                <DataTables
                    columns={columns}
                    dataSource={upcomingShipments?.data}
                    rowkey="tracking_id"
                    emptyText="No upcoming shipments for this datacenter to show"
                    loading={shipmentsIsFetching}
                />
            </div>
            {drawerVisible && renderDrawer()}
        </>
    )
}

export default function UpcomingShipments({isImpersonated}) {
    let props = { isImpersonated }
    return BaseLayout(UpcomingShipmentsWrapper, props);
  }