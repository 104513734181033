import { Alert } from 'antd';
import React from 'react';

const onClose = (msg) => {
  if (msg.includes('real-time')) sessionStorage.setItem('todoDataWarningSkip', true);
  console.log('I was closed.');
};

export default function Banner({ msg, type }) {
    return (
        <Alert 
            message={msg}
            type={type}
            closable
            banner
            onClose={() => onClose(msg)}
            style={{position:"relative", top:"69px"}}
        />
    )
}