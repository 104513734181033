import "../Assets/css/App.css";
import { Button, Descriptions, Input, Select, Tag, Tooltip } from "antd";
import { CalendarTwoTone, AuditOutlined, SwapOutlined, ExportOutlined } from "@ant-design/icons";
import { Typography } from 'antd';
import { Alert } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import BaseLayout from "./baselayout";
import DataTables from "../components/DataTables/DataTables";
import {
  useGetCountersQuery,
  useGetDatacentersQuery,
} from "../services/datacenter";
import LoadingIcon from "../components/Spinners/Loading";
import Banner from "../components/Banner/Banner";
import { useEffect, useState } from "react";
import * as api from "../api";
import Header from "../components/Header/Header";

function DataCentersWrapper({isInfoStored, isImpersonated}) {
  let dataCenters;
  const { Option } = Select;
  const { Text } = Typography;
  const { data, error, isLoading, isSuccess } = useGetDatacentersQuery();
  const { data: todoData, isLoading: isLoadingTodos, error: todoError } = useGetCountersQuery(
    { issueType: "todos", data },
    { skip: !isSuccess }
  );
  const { data: rmaData, isLoading: isLoadingRMAs, error: rmaError } = useGetCountersQuery(
    { issueType: "rmas", data },
    { skip: !isSuccess }
  );

  const navigate = useNavigate()
  const [errorMsg, setErrormsg] = useState(false);
  api.setLastPath(window.location.href);

  useEffect(() => {
    if (error) {
      console.log(`error - ${JSON.stringify(error)}`);
      api.trigger401(error, setErrormsg);
    }
    if (todoError) {
      console.log(`todo error - ${JSON.stringify(todoError)}`);
      api.trigger401(todoError, setErrormsg);
    }
    if (rmaError) {
      console.log(`RMA error - ${JSON.stringify(rmaError)}`);
      api.trigger401(rmaError, setErrormsg);
    }
  }, [error, todoError, rmaError]);

  if (data) {
    try {
      if (!data.success) {
        console.log("failed");
      } else {
        dataCenters = data.data.map((row, index) => ({
          key: row.data_center_id + index,
          ...row,
        }));
      }
    } catch (error) {
      console.log(error);
      api.trigger401({"status": 500}, setErrormsg)
    }
    
  }

  if (todoData) {
    if (todoData.success) {
      let todos = todoData.data;
      for (let obj of dataCenters) {
        if (obj["data_center_id"] in todos) {
          obj["todo_counter"] = parseInt(todos[obj["data_center_id"]]);
        } else {
          obj["todo_counter"] = 0;
        }
      }
    }
    // console.log(`todos data - ${JSON.stringify(todoData)}`);
  }

  if (rmaData) {
    if (rmaData.success) {
      let rmas = rmaData.data;
      for (let obj of dataCenters) {
        if (obj["data_center_id"] in rmas) {
          obj["rma_counter"] = parseInt(rmas[obj["data_center_id"]]);
        } else {
          obj["rma_counter"] = 0;
        }
      }
    }
    // console.log(`rma data - ${JSON.stringify(rmaData)}`);
  }

  const showTotal = (total) => `Total ${total} data centers`;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        return (
          <>
            <span style={{fontWeight:500}}>{text}</span>
            <p style={{ fontSize: "11.5px" }}>
              {record.location} 
              <Tooltip placement="right" title="Location ID">
                <Text keyboard>{record.address_id}</Text>
              </Tooltip>
              {(!record.active || record.active === "N") && (
                <Tag
                  color="error"
                  style={{ float: "right", borderRadius: "20px" }}
                >
                  Inactive
                </Tag>
              )}
            </p>
          </>
        );
      },
    },
    {
      title: "Todo",
      dataIndex: "todo_counter",
      key: "todo_counter",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.todo_counter - b.todo_counter,
      onCell:(record, _) => {
        return {
          onClick: (e) => {
            if(record.todo_counter){
              navigate(`/datacenters/${record.data_center_id}/todos`)
            }
          }
        }
      },
      render: (text, record) => {
        if (record.todo_counter !== undefined) {
          return (
            record.todo_counter ?
            <div>
              <Link to={`/datacenters/${record.data_center_id}/todos`}>
                {record.todo_counter}
              </Link>
            </div>
            : <a disabled={true}>0</a>
          );
        } else if (isLoadingTodos){
          return (
            <div>
              <LoadingIcon tip={null} />
            </div>
          );
        } else {
          return (
            <div> N/A</div>
          )
        }
      },
    },
    {
      title: "RMAs",
      dataIndex: "rma_counter",
      key: "rma_counter",
      align: "center",
      sorter: (a, b) => a.rma_counter - b.rma_counter,
      onCell:(record, _) => {
        return {
          onClick: (e) => {
            if(record.todo_counter){
              navigate(`/datacenters/${record.data_center_id}/rmas`)
            }
          }
        }
      },
      render: (text, record) => {
        if (record.rma_counter !== undefined) {
          return (
            record.rma_counter ?
            <Link to={`/datacenters/${record.data_center_id}/rmas`}>
              {record.rma_counter}
            </Link>
            : <a disabled={true}>0</a>
          );
        } else if(isLoadingRMAs){
          return <LoadingIcon tip={null} />;
        } else {
          return <div>N/A</div>
        }
      },
    },
    {
      title: "Actions",
      dataIndex: "audit",
      key: "audit",
      align: "center",
      render: (text, record) => (
        <>
        <Tooltip title="Schedule Visit">
          <span>
            <Link to={`/datacenters/${record.data_center_id}/schedule-visit`}>
              <Button type="link" icon={<CalendarTwoTone />}></Button>
            </Link>
          </span>
        </Tooltip>
        <Tooltip title="Audit">
          <span>
            <Link to={`/datacenters/${record.data_center_id}/audit-log`}>
            <Button type="link" icon={<AuditOutlined />}></Button>
            </Link>
          </span>
        </Tooltip>
        <Tooltip title="Upcoming Shipment">
          <span>
            <Link to={`/datacenters/${record.data_center_id}/upcoming-shipments`}>
            <Button type="link" icon={<SwapOutlined />}></Button>
            </Link>
          </span>
        </Tooltip>
        </>
      ),
    },
    // keep this code for future use.. when shipments bug is fixed
    // {
    //     title: 'Shipments',
    //     dataIndex: 'shipments',
    //     key: 'shipments',
    //     align:'center',
    //     render: text => <Tooltip title="View Shipments"><span><Button type="link" icon={<EyeOutlined />}></Button></span></Tooltip>
    // },
  ];

  const expandedRowRender = (row) => {
    const data = row;
    return (
      <Descriptions>
        <Descriptions.Item label="Hands and Eyes" span={3}>
          {data["hands_and_eyes"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Disposal Procedure" span={3}>
          {data["trash_disposal_procedure"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Special Instructions" span={3}>
          {data["special_instructions"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Access Procedure" span={3}>
          {data["access_procedure"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number" span={1}>
          {data["phone_number"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Fax Number" span={2}>
          {data["fax_phone_number"] || "NA"}
        </Descriptions.Item>
        <Descriptions.Item label="Shipping Address" span={3}>
          <div>
            {data["street"] && ` ${data["street"]}`}
            {data["street_2"] && ` ${data["street_2"]}`}
            {data["street_3"] && ` ${data["street_3"]}`}
            {data["city"] && `, ${data["city"]}`}
            {data["state"] && `, ${data["state"]}`}
            {data["zipcode"] && `, ${data["zipcode"]}`}
            {data["province"] && `, ${data["province"]}`}
            {data["country"] && `, ${data["country"]}`}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Shipping Instructions" span={3}>
          {data["ship_instructions"] || "NA"}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  // const expandIcon = () => null;
 
  function onChange(pagination, filters, sorter, extra) {}
  return (
    <>
      <Header title="Datacenters"/>
      {errorMsg && <Banner msg={errorMsg} type="error"/>}
      {isImpersonated && 
      (<Alert 
          message={"Please Note: Impersonation mode is read-only mode. Users are not allowed to make any changes to data or submit any requests in Impersonation mode"}
          type="warning"
          banner
          style={{position:"relative", top:"69px"}}
        />)
      }
      {process.env.REACT_APP_ENVIRONMENT!=='production' &&
         (<Alert 
         message={"Please note: You are logged in to "+ process.env.REACT_APP_ENVIRONMENT +" Environment" }
         type={"info"}
         banner
         style={{position:"relative", top:"69px"}}
     />)}
      { (isInfoStored && (!localStorage.getItem("userDetails") || localStorage.getItem("userDetails") === "null")) && !isImpersonated &&
         (<Alert 
         message={<>Please enter your phone number in the <Link to="/profile">Profile page</Link></>}
         type={"warning"}
         banner
         style={{position:"relative", top:"69px"}}
     />)
      }
      <div className="content-container">
        <div className="datacenter-table" style={{ marginTop: "90px" }}>
          <DataTables
            columns={columns}
            dataSource={dataCenters}
            emptyText="No Data Centers assigned"
            onChange={onChange}
            expandable={{ expandedRowRender }}
            pagination = {false}
            rowKey="key"
            loading={isLoading}
            showTotal={showTotal}
            // scroll={{
            //   x: "max-content",
            // }}
            // style={{width:'100%'}}
          />
        </div>
      </div>
    </>
  );
}

export default function DataCenters({isInfoStored, isImpersonated}) {
  let props = {isInfoStored, isImpersonated}
  return BaseLayout(DataCentersWrapper, props);
}

