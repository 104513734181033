import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setImpersonationHeaders } from "../impersonateApi";

export const isokApi = createApi({
  reducerPath: "isokApi",
  baseQuery: fetchBaseQuery({
    baseUrl: new URL("isok", process.env.REACT_APP_FT_PORTAL_URL).toString(),
    prepareHeaders: (headers) => {
      headers.set("Authorization", localStorage.getItem("token"));
      headers = setImpersonationHeaders(headers);
      return headers;
    },
  }),
  endpoints: (build) => ({
    runIsOk: build.mutation({
      query: ({ payload }) => {
        return {
            url: '/',
            method: 'POST',
            body: payload
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
    }),
    updateIsOk: build.mutation({
      query: ({ payload }) =>{
        return {
          url:'/',
          method: 'PATCH',
          body: payload
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
    }),
    getIsOk: build.mutation({
      query: ({payload}) => {
        return {
          url: '/?id='+payload.id,
          method: 'GET'
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getMultipleIsOk: build.mutation({
      query: ({payload}) => {
        return {
          url: '/?ids=['+payload.ids+']',
          method: 'GET'
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    listIsOk: build.query({
      query: ({username}) =>{
        return {
          url: `${username}/test-results`
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    attachIsOkResult: build.query({
      query: ({payload}) => {
        return {
          url: 'attach-ticket/?ticket_id='+payload.ticket_id,
          method: 'GET'
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    })
  }),
});

export const { 
  useRunIsOkMutation, 
  useGetIsOkMutation, 
  useGetMultipleIsOkMutation,
  useUpdateIsOkMutation,
  useListIsOkQuery, 
  useLazyListIsOkResultQuery,
  useLazyAttachIsOkResultQuery,
  usePrefetch } = isokApi;