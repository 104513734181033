import { useEffect, useState } from "react";
import BaseLayout from "./baselayout";
import Header from "../components/Header/Header";
import DataTables from "../components/DataTables/DataTables";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
import { Button, Tag, Modal, Form, DatePicker, Drawer, Select, Collapse, Input, InputNumber, Alert, Row, Col,Menu, Timeline, Tooltip } from "antd";
import { DownloadOutlined, PlusOutlined, CaretRightOutlined, LockOutlined, DeleteFilled, DeleteOutlined} from "@ant-design/icons";
import { useAddActivitylogMutation, useGetActivitylogsQuery, useLazyGetActivitylogsQuery, useLazyGetActivitylogQuery, useUpdateActivitylogMutation, useGetExportlogsQuery, useDeleteActivitylogMutation } from "../services/activitylogs";
import { useGetDatacentersQuery, useGetIssueActivityHistoryMutation } from "../services/datacenter";
import * as api from "../api";
import moment from "moment";
import Marquee from 'react-fast-marquee';
import {CSVLink} from "react-csv";

function ActivityLogsWrapper( { isImpersonated } ) {
  
  const [startDate, setStartDate] = useState(moment().subtract(5,"years").format("YYYY-MM-DD HH:mm:ss"));
  const [endDate, setEndDate] = useState(moment().add(10,"days").format("YYYY-MM-DD HH:mm:ss"));
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isTodoHistoryVisible, setIsTodoHistoryVisible] = useState(false);
  const [errorMsg, setErrormsg] = useState(null);
  const [searchedText,setSearchedText] = useState("");
  const [activityLogDate, setActivityLogDate ] = useState(null);
  const [activityLogEditData, setActivityLogEditData ] = useState(null);
  const [activityLogSelected, setActivityLogSelected ] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [triggerSearchActivityLogs,{data:searchActivityLog, error: searchError, isLoading: isSearchLoading, isFetching:isSearching}] = useLazyGetActivitylogsQuery()
  const {data:activityLogs, error:activityError, isLoading:isActivityLogsLoading, refetch:getActivitylogsRefetch}= useGetActivitylogsQuery({ start_date:startDate, end_date:endDate, timezone},{skip: startDate == null || endDate==null})
  const [triggerGetActivityLog, {data:oneActivityLog, error:oneActivityError, isLoading:isOneActivityLoading, isFetching:isOneActivityFetching}]= useLazyGetActivitylogQuery();
  const [triggerAddActivityLog, addActivityLogFunc ] = useAddActivitylogMutation();
  const [triggerUpdateActivityLog, updateActivityLogFunc ] = useUpdateActivitylogMutation();
  const [triggerDeleteActivityLog, triggerActivityLogFunc ] = useDeleteActivitylogMutation();
  const {data:datacenters, error, isLoading:isDatacenterLoading} = useGetDatacentersQuery();
  const [triggerGetIssueActivity, {data:historyDetails, isLoading:isHistoryLoading, error: historyError}] = useGetIssueActivityHistoryMutation();
  const {data:exportLogs, error:exportError, isLoading:isExportLogsLoading, refetch:getExportlogsRefetch}= useGetExportlogsQuery({ start_date:startDate, end_date:endDate},{skip: startDate == null || endDate==null})
  
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  const maint_duration=(Form.useWatch("maint-hrs", form)||0)*60+(Form.useWatch("maint-mins", form)||0)
  const deploy_duration=(Form.useWatch("deploy-hrs", form)||0)*60+(Form.useWatch("deploy-mins", form)||0)
  const misc_duration=(Form.useWatch("misc-hrs", form)||0)*60+(Form.useWatch("misc-mins", form)||0)

  let datacentersList;
  const initialValues={
      datacenters: [],
      "maint-hrs": 0,
      "maint-mins": 0,
      "deploy-hrs":0,
      "deploy-mins":0,
      "misc-hrs":0,
      "misc-mins":0,
      "totaltime-mins":0,
      "totaltime-hrs":0,
      "maint-comment":"",
      "deploy-comment":"",
      "misc-comment":"",
  }
  api.setLastPath(window.location.href)

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  if(activityLogs){
    // console.log(activityLogs)
  }
  
  if(searchActivityLog){
    // console.log(searchActivityLog)
  }
  
  useEffect(() =>{
    if(activityLogDate && activityLogSelected===null){
      triggerSearchActivityLogs({start_date:moment(activityLogDate).format("YYYY-MM-DD 00:00:00"), end_date:moment(activityLogDate).format("YYYY-MM-DD 23:59:59"),timezone})
    }
  },[activityLogDate,activityLogSelected])
  
  useEffect(()=>{
    if(!isSearching){
      modalForm.resetFields();
      if(searchActivityLog?.length===0){
        setIsModalVisible(false)
        setIsDrawerVisible(true)
      }
      if(searchActivityLog?.length>0){
        let status = searchActivityLog[0].status && moment().diff(moment(searchActivityLog[0].created_date),"days")>=7
        setIsModalVisible(false)
        if(status){
          Modal.error({
            title: 'Activity Log already submitted!',
            content: 'The activity log for the selected date is already submitted, Would you like to view it?',
            okText: 'Yes',
            onCancel:'No',
            onOk(){
              editActivityLog(searchActivityLog[0])  
            }
          })
        }
        else{
          Modal.confirm({
            title: 'Activity Log already present!',
            content: 'The activity log for the selected date is present, Would you like to edit it?',
            okText: 'Yes',
            cancelText:'No',
            onOk(){
              editActivityLog(searchActivityLog[0])     
            }
          })
        }
      }
    }
  },[searchActivityLog,isSearching])

  useEffect(()=>{
    if (error) {
      console.log(error);
      api.trigger401(error, setErrormsg);
    }
    if (activityError) {
      console.log(activityError);
      api.trigger401(activityError, setErrormsg);
    }
    if (exportError) {
      console.log(exportError);
      api.trigger401(exportError, setErrormsg);
    }
    if (oneActivityError) {
      console.log(oneActivityError);
      api.trigger401(oneActivityError, setErrormsg);
    }
  },[error,activityError,exportError,oneActivityError])

  useEffect(()=>{
    if(activityLogSelected?.visit_id){
      triggerGetActivityLog({visit_id:activityLogSelected.visit_id},{preferCacheValue:true})
      let duration= {
        "maint-hrs":parseInt((activityLogSelected.maintenance_duration || 0)/60),
        "maint-mins":(activityLogSelected.maintenance_duration|| 0)%60,
        "deploy-hrs":parseInt((activityLogSelected.deployment_duration || 0)/60),
        "deploy-mins":(activityLogSelected.deployment_duration || 0)%60,
        "misc-hrs":parseInt((activityLogSelected.misc_duration|| 0)/60),
        "misc-mins":((activityLogSelected.misc_duration || 0)%60),
      }
      duration["totaltime-hrs"]=duration["maint-hrs"]+duration["deploy-hrs"]+duration["misc-hrs"]
      duration["totaltime-mins"]=duration["maint-mins"]+duration["deploy-mins"]+duration["misc-mins"]
      form.setFieldsValue({
        ...duration
      })
      if(!isOneActivityFetching && oneActivityLog){
        const oneActivityLogData=oneActivityLog.data
        let maint=oneActivityLogData["Maintenance"]?.filter(item=>item.issue_id===null)
        let datacenters = oneActivityLogData["Data_center"].map(dc=>dc.data_center_id)
        let editable = oneActivityLogData["Data_center"].filter(dc=>(dc.editable === "No")).map(dc=>dc.data_center_id)
        let deploy=oneActivityLogData["Deployment"]?.filter(item=>item.issue_id===null)
        let misc=oneActivityLogData["Miscellaneous"]?.filter(item=>item.issue_id===null)
        let status;
        if(activityLogSelected.status)
          status="Submitted"
        else status="Created"

        form.setFieldsValue({
          "maint-comment": maint[0]?.resolution || '',
          "deploy-comment": deploy[0]?.resolution || '',
          "misc-comment": misc[0]?.resolution || '',
          "datacenters": datacenters
        })
        setActivityLogEditData({
          "Datacenters": datacenters,
          "Editable": editable,
          "Maintenance": oneActivityLogData["Maintenance"]?.filter(item=>item.issue_id),
          "Deployment": oneActivityLogData["Deployment"]?.filter(item=>item.issue_id),
          "Miscellaneous": oneActivityLogData["Miscellaneous"]?.filter(item=>item.issue_id),
          "maint-comment": maint[0]?.resolution || '',
          "deploy-comment": deploy[0]?.resolution || '',
          "misc-comment": misc[0]?.resolution || '',
          "Status":status,
          "visit_id":activityLogSelected["visit_id"],
          ...duration
        })
      }
    }
  },[activityLogSelected,oneActivityLog,isOneActivityFetching])
  

  if(oneActivityLog){
    // console.log(oneActivityLog.data)
  }

  if(datacenters){
    datacentersList = datacenters.data.filter((d)=>d.active && d.active==='Y' )
  }

  const showModal = () => {
      setIsModalVisible(true);
  }

  const showTodoHistory = (ticket_id) => {
    triggerGetIssueActivity({data:[ticket_id]});
    setIsTodoHistoryVisible(true);
  }

  const showTotal=(total)=> `Total ${total} Activity logs`;

  const modalOnFinish=(values,type)=>{
    setActivityLogSelected(null);
    setActivityLogEditData(null);
    setActivityLogDate(moment(values["activityLogDate"]).format());
    // setIsModalVisible(false);
    // setIsDrawerVisible(true);
  }

  const checkVisitType = (formValues,formType) =>{
    let visitType=[]
    const types=["maint","deploy","misc"]
    const fields=["comment","hrs","mins"]
    for (const type of types){
      for (const field of fields){
        if(formType==="new"){
          if(formValues[`${type}-${field}`] !== initialValues[`${type}-${field}`]){
           if(!visitType.includes(type)) visitType.push(type)
          }
        }
        if(formType==="edit"){
          if(formValues[`${type}-${field}`] !== activityLogEditData[`${type}-${field}`]){
            if(!visitType.includes(type)) visitType.push(type)
          }
        }
      }
    }
    return visitType
  }

  const headers = [
    { label: "Activity Log date", key: "created_date" },
    { label: "Total Duration", key: "total_duration" },
    { label: "Data Center (comma Separated)", key: "data_center_id" },
    { label: "Maintenance Duration", key: "maintenance_duration" },
    { label: "Maintenance Resolution", key: "Maintenance_resolution" },
    { label: "Maintenance Ticket ID", key: "maintenance_tickets" },
    { label: "Deployment Duration", key: "deployment_duration" },
    { label: "Deployment Resolution", key: "Deployment_resolution" },
    { label: "Deployment Ticket ID", key: "deployment_tickets" },
    { label: "Miscellaneous Duration", key: "misc_duration" },
    { label: "Miscellaneous Resolution", key: "Miscellaneous_resolution" },
    { label: "Status", key: "status" },
  ];

  let activityList = [];
  let csvlist = [];
  let filename = "exportlogs.csv";

  if (exportLogs) {
    activityList = exportLogs.map((row, index) => ({
      key: index,
      ...row,
    }));
    csvlist = activityList.map((row, index) => {
      if(row.created_date) row.created_date = moment.utc(row.created_date).local().format("YYYY-MM-DD HH:mm:ss")
      if(row.post_width) row.post_width = row.post_width.replace(/[^0-9]/g,'')+"\" ";
      if(row.Maintenance_resolution) row.Maintenance_resolution = row.Maintenance_resolution.replaceAll('"', '""')
      return row;
    });
  }

  const onFinish= async (type,values)=>{
    try{
      let payload={};
      let visit_type=checkVisitType(values,type)
      const resolution_type="Other"
      
      if(type==="new"){
        if(visit_type.length>0){
          payload['visit_type']=visit_type.map(type =>{ if(type==="maint") return "Maintenance" 
                                        else if(type==="deploy") return "Deployment"
                                        else return "Miscellaneous"
                                      })
          for (const type of visit_type){
            if(type==="maint"){
              payload["Maintenance_resolution"]= values[`${type}-comment`]
              payload["maintenance_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
            else if(type==="deploy"){
              payload["Deployment_resolution"]= values[`${type}-comment`]
              payload["deployment_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
            else{
              payload["Miscellaneous_resolution"]= values[`${type}-comment`]
              payload["misc_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
          }
          payload["data_center_id"]=values["datacenters"]
          payload["created_date"]=moment.utc(activityLogDate).format("YYYY-MM-DD HH:mm:ss")
        }

        if(Object.keys(payload).length>0){
          payload={...payload,resolution_type,"page":"",timezone}
          console.log(payload)
          const resp = await triggerAddActivityLog({payload}).unwrap();
          // console.log(resp)        
          if(resp.success){
            closeDrawer();
            form.resetFields();
            setActivityLogSelected(null);
            setActivityLogDate(null);
            setActivityLogEditData(null);
            api.openNotification("Success!","success","Activity Log Added");
            getActivitylogsRefetch();
            getExportlogsRefetch();
          } 
        }

      }
      else if(type==="edit"){
        if(visit_type.length>0){
          payload['visit_type']=visit_type.map(type =>{ if(type==="maint") return "Maintenance" 
                                        else if(type==="deploy") return "Deployment"
                                        else return "Miscellaneous"
                                      })
          for (const type of visit_type){
            if(type==="maint"){
              payload["Maintenance_resolution"]= values[`${type}-comment`]
              payload["maintenance_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
            else if(type==="deploy"){
              payload["Deployment_resolution"]= values[`${type}-comment`]
              payload["deployment_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
            else{
              payload["Miscellaneous_resolution"]= values[`${type}-comment`]
              payload["misc_duration"]=values[`${type}-hrs`]*60+values[`${type}-mins`]
            } 
          }
        }

        let dcs = values["datacenters"]
        payload["data_center_id"]=dcs

        if(Object.keys(payload).length>0){
          payload={...payload,resolution_type,"page":""}
          console.log(payload)
          const resp = await triggerUpdateActivityLog({payload,"visit_id":''+activityLogEditData["visit_id"]}).unwrap();
          // console.log(resp)
          if(resp.success){
            let visit_id=activityLogSelected?.visit_id
            closeDrawer()
            form.resetFields();
            setActivityLogSelected(null);
            setActivityLogDate(null);
            setActivityLogEditData(null);
            api.openNotification("Success!","success","Activity Log Edited");
            getActivitylogsRefetch();
            getExportlogsRefetch();
            triggerGetActivityLog({visit_id});
          } 
        }
      }
     
    } catch(error){
      api.trigger401(error)
      console.log(error)
    }
  }

  const editActivityLog=(record)=>{
    setActivityLogDate(moment(record.created_date).format());
    setActivityLogSelected(record);
    setIsDrawerVisible(true);
  }
  
  const closeDrawer=()=>{
    setShowConfirm(false);
    setIsDrawerVisible(false);
    setActivityLogDate(null)
    setActivityLogSelected(null);
    setActivityLogEditData(null);
    form.resetFields();
  }

  const renderChildDrawer = () => {
      let items=[]
      if(!isHistoryLoading && historyDetails){
        items = historyDetails.data.map(item => {
          return (
            <Timeline.Item key={item.created_date} color={item.node_type === "Todo"? "blue": "green"}>
            <p style={{fontWeight:500, color:"#1890ff"}}>{item.node_type} added by {item.created_by_login} on {item.created_date.substring(0,10)} at {item.created_date.substring(11,16)}</p>
            <p style={{ whiteSpace: "pre-line",backgroundColor: "rgb(148 147 146 / 21%)",padding: "15px",borderRadius: "8px", }}>{item.summary}</p> 
          </Timeline.Item>)
      })
    }
      return(
            <Drawer
              title="Ticket History"
              placement="right"
              width={window.innerWidth > 600 ? "500" : "100%"}
              onClose={()=>{setIsTodoHistoryVisible(false)}}
              visible={isTodoHistoryVisible}
            >
              {
                isHistoryLoading ? <AkamaiPreloader/>
                :(
                  <Timeline>
                    {items.reverse()}
                  </Timeline>
                  )
              }
            </Drawer>
      )
    }

  const getStatus=(record)=>{
      if(record.status === 0) {
        return "Created";
      }
      if(record.status === 1 ){
        return "Submitted";
      }
  }

  const columns=[
    {
      title: "Activity Log Date",
      key: "created_date",
      dataIndex: "local_created_date",
      defaultSortOrder: 'descend',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      })=> {
        return <><RangePicker
        autoFocus
        value={selectedKeys[0]}
        disabledDate={(current) => current.isBefore(moment().subtract(6,"Month")) || current.isAfter(moment())}
        onChange={
          (date, dateString) => {
            setSelectedKeys(date ? [date] : []);
            confirm();
          }
        }
      />
      </>
      },
      onFilter: (value, record) => {
        let startdate = value[0];
        let lastdate = value[1];
        return moment(record.local_created_date).isBetween(startdate,lastdate,'days',[]);
      },
      render: (text,record)=>(
        <a href="#!" onClick={() => {editActivityLog(record)}}>
          {moment(new Date(text.replace("GMT",""))).format("ddd, DD MMM YYYY HH:mm")}
        </a>
        // <Button type="link" onClick={()=>{editActivityLog(record)}}>{moment(new Date(text.replace("GMT",""))).format("ddd, DD MMM YYYY HH:mm")}</Button> 
        // <>{text.substring(4,22)}</>
      )
    },
    {
      title: "Maintenance Time",
      key: "maintenance_duration",
      dataIndex: "maintenance_duration",
      align: "center",
      render: (text)=>{
        let duration = parseInt(text);
        if(duration){
          let hours = parseInt(duration/60);
          let minutes = duration%60;
          let showText = `${hours!==0 ? `${hours} hrs`:''} ${minutes!==0? `${minutes} mins`:''}`
          return showText 
        }
        return "-"
      },
      sorter: (a,b)=> a.maintenance_duration - b.maintenance_duration,
    },
    {
      title: "Deployment Time",
      key: "deployment_duration",
      dataIndex: "deployment_duration",
      align: "center",
      render: (text)=>{
        let duration = parseInt(text);
        if(duration){
          let hours = parseInt(duration/60);
          let minutes = duration%60;
          let showText = `${hours!==0 ? `${hours} hrs`:''} ${minutes!==0? `${minutes} mins`:''}`
          return showText ;
        }
        return "-"; 
      },
      sorter: (a,b)=> a.deployment_duration - b.deployment_duration,
    },
    {
      title: "Misc Time",
      key: "misc_duration",
      dataIndex: "misc_duration",
      align: "center",
      render: (text)=>{
        let duration = parseInt(text);
        if(duration){
          let hours = parseInt(duration/60);
          let minutes = duration%60;
          let showText = `${hours!==0 ? `${hours} hrs`:''} ${minutes!==0? `${minutes} mins`:''}`
          return showText ;
        }
        return "-"; 
      },
      sorter: (a,b)=> a.misc_duration - b.misc_duration,
    },
    {
      title: "Total Time",
      key: "total_time",
      align: "center",
      render: (record) =>{
        let total=record.maintenance_duration+record.deployment_duration+record.misc_duration
        let duration = parseInt(total);
        if(duration){
          let hours = parseInt(duration/60);
          let minutes = duration%60;
          let showText = `${hours!==0 ? `${hours} hrs`:''} ${minutes!==0? `${minutes} mins`:''}`
          return showText ;
        }
        return "-"; 
      }
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      filters: [
        {
          text: 'Created',
          value: 'Created',
        },
        {
          text: 'Submitted',
          value: 'Submitted',
        },
      ],
      onFilter: (value, record) => getStatus(record) == value,
      render:  (record)=>{
        let showText = "";
        let color = "";
        let locked = false;
        if(record.status === 0) {
          showText = "Created"
          color = "blue"
        }
        if(record.status === 1 ){
          showText = "Submitted"
          color = "green"
          locked = moment().diff(moment(record.created_date),"days")>=7
        }
        return <Tag color={color} icon={locked && <LockOutlined/>}>{showText}</Tag>
      }
    }
  ]
  
  const prevSixMonths=()=>{
    if(activityLogs){
      let data=activityLogs.slice();
      if(data.length > 0){
        let sorted_data=data.sort((a,b)=> moment(b.created_date).format("YYYYMMDD")- moment(a.created_date).format("YYYYMMDD"))
        let initial_date=sorted_data[0].created_date;
        let result = sorted_data.filter(o =>(moment(o.created_date).isBetween(moment(initial_date).subtract(12, 'years'), moment(initial_date),'month', '[]')))
        return result
      }
    }
    return []
  }

  const displayModal = () => {
    // setDrawerVisible(true);
      Modal.confirm({
        title: 'Are you sure, you want to delete  the activity log?',
        icon: <DeleteFilled />,
        okText: "No",
        cancelText: "Yes",
        style: {marginTop: "10%"},
        onOk() {
          // when user presses No
          console.log("no");
        }, 
        onCancel: async () => {
          // when user presses Yes
          console.log("yes");
          console.log(`visit id - ${activityLogSelected.visit_id}`)
          const resp = await triggerDeleteActivityLog({"visit_id": activityLogSelected.visit_id}).unwrap()
          console.log(resp);
          if(resp.success) {
            api.openNotification("Success!","success","Activity Log Deleted");
            setIsDrawerVisible(false);
            getActivitylogsRefetch();
            getExportlogsRefetch();
          } else {
            api.openNotification("Error!", "fail", resp.message);
          }
        }
      });
  }

  const renderDrawer=()=> {
    let type = "new"
    let locked = false
    const activityLogStatus = activityLogEditData?.["Status"] || "new"
    if(activityLogSelected){
      const day_difference = moment().diff(moment(activityLogSelected.created_date),"days")
      if(activityLogStatus === "Submitted" && day_difference>=7){
        locked = true
      }
      if(day_difference<7 || activityLogStatus == "Created"){
        type = "edit"
      }
    }
    const handleTotal=(_,all)=>{
      let total_time={"hrs":0,"mins":0}
      let maint_time={"hrs":all["maint-hrs"] || 0,"mins":all["maint-mins"] || 0}
      let deploy_time={"hrs":all["deploy-hrs"] || 0,"mins":all["deploy-mins"] || 0}
      let misc_time={"hrs":all["misc-hrs"] || 0,"mins":all["misc-mins"] || 0}
      total_time["mins"]=maint_time["mins"]+deploy_time["mins"]+misc_time["mins"];
      total_time["hrs"]=maint_time["hrs"]+deploy_time["hrs"]+misc_time["hrs"];
      if(total_time["mins"]>59){
        total_time["hrs"]+=parseInt(total_time["mins"]/60);
        total_time["mins"]%=60;
      }
      form.setFieldsValue({"totaltime-hrs":total_time["hrs"],"totaltime-mins":total_time["mins"]})
    }

    const disableDC=(dc_id)=>{
      let editable= activityLogEditData?.["Editable"] || []
      if(editable.length >0){
        return editable.find(dc=>dc===dc_id)
      }
      if(locked){
        return true
      }
      return false
    }

    const getColunns=()=>{
      return [
        {
          title: "Ticket Number",
          key: "issue_id",
          dataIndex: "issue_id",
          render: (text) => (
            // <Button type="link" onClick={() => showTodoHistory(text)}>
            //   {text}
            // </Button>
            <a href="#!" onClick={() => showTodoHistory(text)}>{text}</a>
          ),
        },
        {
          title: "Resolution",
          key: "resolution",
          dataIndex: "resolution",
          render: (text,record) =>{
            return `Resolution: ${record["resolution_type"]}, Comment: ${text}, Return Tracking Number: ${record["tracking_number"]||""}`
          }
        },
      ]
    }

    return(
      <Drawer
        title={<div>{`Activity Log for ${moment(activityLogDate).local().format("DD-MM-YYYY")}`} {locked && <LockOutlined/>}</div>}
        placement="right"
        width={window.innerWidth > 600 ? "60%" : "100%"}
        maskClosable={false}
        onClose={closeDrawer}
        visible={isDrawerVisible}
      >
        {isOneActivityFetching || (activityLogSelected && !activityLogEditData) ?
        <AkamaiPreloader/> :
        <>
          <Alert
            banner
            message={
              <Marquee pauseOnHover gradient={false}>
              <strong>Note:&nbsp;</strong>This activity log is for informational purposes only. You will not be paid based on the hours you put in here. You must continue to fill in hours on your regular timesheet or invoice in order to be paid. &nbsp;
              </Marquee>
            }
          />
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => onFinish(type,values)}
            onValuesChange={handleTotal}
            initialValues={initialValues}
            requiredMark={false}
          >
            <Form.Item
              name="datacenters"
              label={<p style={{fontWeight:"500", paddingTop:"10px"}}>Datacenters</p>}
            >
              <Select 
                  mode="multiple"
                  placeholder="Add Datacenters"
                  loading={isDatacenterLoading}
                  optionFilterProp="label"
                  filterOption={(input, option) => {
                    if(input) input = input.toLocaleLowerCase()
                      return (option?.searchvalue ? option.searchvalue.toLowerCase() : '').includes(input)
                  }}
                  filterSort={(optionA, optionB) =>
                    (optionA?.searchvalue ? optionA.searchvalue : '').toLowerCase().localeCompare((optionB?.searchvalue ? optionB.searchvalue : '').toLowerCase())
                  }
                  disabled={locked || (activityLogStatus==="new" && maint_duration===0 && deploy_duration===0 && misc_duration===0)}
                  options={
                    datacentersList? datacentersList.map((d)=>{
                      return { label: <p style={{color:"black" }}>{d.name} <span style={{ fontSize: "11.5px"}}>({d.data_center_location}</span>)</p>, value: d.data_center_id,disabled: disableDC(d.data_center_id), searchvalue: d.name + ' ' + d.data_center_location}
                      }): []
                  }
                  dropdownStyle={
                    locked? {display:"none"}:{}
                  }
                  style={{color:"black",background:"white"}}
              ></Select>
            </Form.Item>
            <Collapse 
              defaultActiveKey={[1,2,3]} 
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
              style={{background:"#fff"}}
              className="custom-collapse"
              >
                {locked && !activityLogEditData["maint-comment"]
                ? <></>
                :  <Panel 
                    header="Maintenance Issues" 
                    key="1" 
                    className="custom-panel"
                    >

                    {(type==="edit" || locked) && activityLogEditData?.["Maintenance"].length >0 && 
                      <DataTables
                      columns={getColunns()}
                      dataSource={activityLogEditData["Maintenance"]}
                      emptyText="No data to display"
                      rowKey="issue_id"
                      loading={false}
                      pagination={false}
                      scroll={undefined}
                      bordered
                      style={{fontWeight:400}}
                      />
                    }
                    {/* {
                      form.isFieldsTouched(["maint-hrs","maint-mins"]) && form.isFieldTouched("maint-comment") && maint_duration===0 && form.getFieldValue("maint-comment")!==''?
                      <Alert banner message="Duration required" type="error" style={{position:"relative",fontWeight:400}}/>:<></>

                    } */}
                    <Form.Item
                      name="maint-comment"
                      label="Comment"
                      dependencies={['maint-hrs','maint-mins']}
                      rules={[
                              {required: type==='edit' &&  activityLogEditData?.["Maintenance"].length!==0 ? true : false, message:"Maintenance comments are required"},
                              {
                                validator(rules, value) {
                                  let time = maint_duration;
                                  let words =value.replace(/\s+/g, '').length ; 
                                  if(time===0){
                                    return Promise.resolve();
                                  }
                                  if(time>0 && time<=60 && words<30){
                                    return Promise.reject(
                                    new Error("Maintenance comments must be atleast 30 characters")
                                    );
                                  }
                                  else if (time>60 && time<=120 && words<60){
                                    return Promise.reject(
                                      new Error("Maintenance comments must be atleast 60 characters")
                                      );
                                  }
                                  else if (time>120 && time<=180 && words<90){
                                    return Promise.reject(
                                      new Error("Maintenance comments must be atleast 90 characters")
                                      );
                                  }
                                  else if ( time>180 && words<100){
                                    return Promise.reject(
                                      new Error("Maintenance comments must be atleast 100 characters")
                                      );
                                  }
                                  return Promise.resolve();
                                }
                              },
                              {
                                validator(_,value){
                                  let time = maint_duration
                                  let words = value.replace(/\s+/g, '').length ;
                                  if(time===0 && words!==0){
                                    return Promise.reject(
                                      new Error("Warning: This data cannot be submitted as the total duration is 0")
                                    )
                                  }
                                  return Promise.resolve()
                                },
                                warningOnly:true
                              }
                      ]}
                      style={{paddingTop:"10px",marginLeft:"5px"}}
                    >
                      {locked
                        ?activityLogEditData["maint-comment"] && <p style={{fontWeight:"Normal",whiteSpace:"pre-line"}}>{activityLogEditData["maint-comment"]}</p> 
                        :<Input.TextArea
                          rows={4}
                          placeholder="Comments on Maintenance"    
                          disabled={maint_duration===0}
                          />
                      }
                    </Form.Item>
                      <Input.Group compact classname="duration-inputgroup" style={{display:"inline-flex", justifyContent:"right"}}>
                        <Form.Item style={{marginRight:"10px",marginBottom:"0px"}}>Duration :</Form.Item>
                        <Form.Item
                          name="maint-hrs"  
                        >
                          <InputNumber disabled={locked} className={locked ? "disabled" : ""} size="default" min={0} max={23} addonAfter={"hrs"} style={{width:"100px"}}/>
                          {/* <p>{activityLogEditData?.["maint-hrs"]} hrs</p> */}
                        </Form.Item>
                        <Form.Item
                          name="maint-mins"
                          >
                          <InputNumber disabled={locked} className={locked ? "disabled" : ""} size="default" min={0} max={59} addonAfter={"mins"} style={{width:"110px", color:"black"}}/>
                          {/* <p>{activityLogEditData?.["maint-mins"]} mins</p> */}
                        </Form.Item>
                      </Input.Group>
                  </Panel>
                }
                {locked && !activityLogEditData["deploy-comment"]
                ?<></>
                :<Panel 
                  header="Deployment Issues" 
                  key="2" 
                  className="custom-panel"
                >

                  {(type==="edit" || locked) && activityLogEditData?.["Deployment"].length >0 && 
                    <DataTables
                      columns={getColunns()}
                      dataSource={activityLogEditData["Deployment"]}
                      rowKey="issue_id"
                      emptyText="No data to display"
                      loading={false}
                      pagination={false}
                      scroll={undefined}
                      bordered
                      style={{fontWeight:400}}
                    />
                  }
                  {/* {
                    form.isFieldsTouched(["deploy-hrs","deploy-mins"]) && form.isFieldTouched("deploy-comment") && deploy_duration===0 && form.getFieldValue("deploy-comment")!==''?
                    <Alert banner message="Duration required" type="error" style={{position:"relative",fontWeight:400}}/>:<></>
                  } */}
                  <Form.Item
                    name="deploy-comment"
                    label="Comment"
                    dependencies={["deploy-hrs","deploy-mins"]}
                    rules={[
                            {required:type==='edit' && activityLogEditData?.["Deployment"].length!==0 ? true : false, message:"Deployment comments are required"},
                            {
                              validator(rules, value) {
                                let time = deploy_duration;
                                let words = value.replace(/\s+/g, '').length ;
                                if(time===0){
                                  return Promise.resolve();
                                }
                                if(time>0 && time<=60 && words<30){
                                  return Promise.reject(
                                  new Error("Deployment comments must be atleast 30 characters")
                                  );
                                }
                                else if (time>60 && time<=120 && words<60){
                                  return Promise.reject(
                                    new Error("Deployment comments must be atleast 60 characters")
                                    );
                                }
                                else if (time>120 && time<=180 && words<90){
                                  return Promise.reject(
                                    new Error("Deployment comments must be atleast 90 characters")
                                    );
                                }
                                else if ( time>180 && words<100){
                                  return Promise.reject(
                                    new Error("Deployment comments must be atleast 100 characters")
                                    );
                                }
                                return Promise.resolve();
                              }
                            },
                            {
                              validator(_,value){
                                let time = deploy_duration
                                let words = value.replace(/\s+/g, '').length ;
                                if(time===0 && words!==0){
                                  return Promise.reject(
                                    new Error("Warning: This data cannot be submitted as the total duration is 0")
                                  )
                                }
                                return Promise.resolve()
                              },
                              warningOnly:true
                              
                            }
                    ]}
                  >
                    {locked
                      ?activityLogEditData["deploy-comment"] && <p style={{fontWeight:"Normal",whiteSpace:"pre-line"}}>{activityLogEditData["deploy-comment"]}</p> 
                      :<Input.TextArea
                        rows={4}
                        placeholder="Comments on Deployment"  
                        disabled={deploy_duration===0}
                        />
                    }
                  </Form.Item>
                    <Input.Group compact classname="duration-inputgroup" style={{display:"inline-flex", justifyContent:"right"}}>
                      <Form.Item style={{marginRight:"10px",marginBottom:"0px"}}>Duration :</Form.Item>
                      <Form.Item 
                        name="deploy-hrs"
                      >
                        <InputNumber disabled={locked} className={locked ? "disabled" : ""} min={0} max={23} addonAfter={"hrs"} style={{width:"100px"}}/>
                      </Form.Item>
                      <Form.Item
                        name="deploy-mins"
                      >
                        <InputNumber disabled={locked} className={locked ? "disabled" : ""} min={0} max={59} addonAfter={"mins"} style={{width:"110px"}}/>
                      </Form.Item>
                    </Input.Group>
                </Panel>
                }
                {locked && !activityLogEditData["misc-comment"]
                ?<></>
                :<Panel 
                  header="Miscellaneous" 
                  key="3" 
                  className="custom-panel"
                >

                  {(type==="edit" || locked) && activityLogEditData?.["Miscellaneous"].length >0 && 
                    <DataTables
                      columns={getColunns()}
                      dataSource={activityLogEditData["Miscellaneous"]}
                      emptyText="No data to display"
                      rowKey="issue_id"
                      loading={false}
                      pagination={false}
                      scroll={undefined}
                      bordered
                      style={{fontWeight:400}}
                    />
                  }
                  {/* {
                    form.isFieldsTouched(["misc-hrs","misc-mins"]) && form.isFieldTouched("misc-comment") && misc_duration===0 && form.getFieldValue("misc-comment")!==''?
                    <Alert banner message="Duration required" type="error" style={{position:"relative",fontWeight:400}}/>:<></>

                  } */}
                  <Form.Item
                    name="misc-comment"
                    label="Comment"
                    dependencies={["misc-hrs","misc-mins"]}
                    rules={[
                            {required:type==='edit' && activityLogEditData?.["Miscellaneous"].length!==0 ? true : false, message:"Comments are required"},
                            {
                              validator(rules, value) {
                                let time = misc_duration;
                                let words = value.replace(/\s+/g, '').length ;
                                if(time===0){
                                  return Promise.resolve();
                                }
                                if(time>0 && time<=60 && words<30){
                                  return Promise.reject(
                                  new Error("Miscellaneous comments must be atleast 30 characters")
                                  );
                                }
                                else if (time>60 && time<=120 && words<60){
                                  return Promise.reject(
                                    new Error("Miscellaneous comments must be atleast 60 characters")
                                    );
                                }
                                else if (time>120 && time<=180 && words<90){
                                  return Promise.reject(
                                    new Error("Miscellaneous comments must be atleast 90 characters")
                                    );
                                }
                                else if ( time>180 && words<100){
                                  return Promise.reject(
                                    new Error("Miscellaneous comments must be atleast 100 characters")
                                    );
                                }
                                return Promise.resolve();
                              }
                            },
                            {
                              validator(_,value){
                                let time = misc_duration
                                let words = value.replace(/\s+/g, '').length ;
                                if(time===0 && words!==0){
                                  return Promise.reject(
                                    new Error("Warning: This data cannot be submitted as the total duration is 0")
                                  )
                                }
                                return Promise.resolve()
                              },
                              warningOnly:true
                              
                            }
                    ]}
                  >
                    {locked
                      ?activityLogEditData["misc-comment"] && <p style={{fontWeight:"Normal",whiteSpace:"pre-line"}}>{activityLogEditData["misc-comment"]}</p> 
                      :<Input.TextArea
                        rows={4}
                        placeholder="Comments"   
                        disabled={misc_duration===0}
                        />
                    }
                  </Form.Item>
                    <Input.Group compact style={{display:"inline-flex", justifyContent:"right"}}>
                      <Form.Item style={{marginRight:"10px",marginBottom:"0px"}}>Duration :</Form.Item>
                      <Form.Item
                        name="misc-hrs"
                      >
                        <InputNumber disabled={locked} className={locked ? "disabled" : ""} min={0} max={23} addonAfter={"hrs"} style={{width:"100px"}}/>
                      </Form.Item>
                      <Form.Item
                        name="misc-mins"
                      >
                        <InputNumber disabled={locked} className={locked ? "disabled" : ""} min={0} max={59} addonAfter={"mins"} style={{width:"110px"}}/>
                      </Form.Item>
                    </Input.Group>
                </Panel>
                }
            </Collapse>
            {!locked && 
            <Alert
            banner
            // showIcon={false}
            type="info"
            message={
              <Marquee pauseOnHover gradient={false}>
                After the log is submitted, editing is allowed for 7 days from the date of creation.&nbsp; 
               </Marquee>
            }
            style={{marginBottom:"15px"}}
            />
          }
            <Input.Group compact style={{display:"inline-flex", justifyContent:"right"}} className="custom-panel">
              <Form.Item style={{marginRight:"10px",marginBottom:"0px"}}>Total Duration :</Form.Item>
              <Form.Item
                name="totaltime-hrs"
                >
                  <InputNumber disabled className="disabled" addonAfter={"hrs"} style={{width:"100px", backgroundColor:"red"}}/>
              </Form.Item>
              <Form.Item
                name="totaltime-mins"
                >
                  <InputNumber disabled className="disabled" addonAfter={"mins"} style={{width:"100px"}}/>
              </Form.Item>
            </Input.Group>
            <Form.Item>
            {!(locked|| (activityLogStatus==="new" && maint_duration===0 && deploy_duration===0 && misc_duration===0)) &&
              <Button type="primary" htmlType="submit" disabled={isImpersonated} loading={addActivityLogFunc["isLoading"]||updateActivityLogFunc["isLoading"]} >Submit</Button>
              }
              {activityLogSelected && 
              (<Tooltip title="Delete Activity Log">
                <Button onClick={displayModal} disabled={isImpersonated} className="delete-btn" style={{marginLeft:"8px"}}>Delete <DeleteOutlined /></Button>
              </Tooltip>)}
            </Form.Item>
          </Form>
          {isTodoHistoryVisible && renderChildDrawer()}
        </>}
      </Drawer>
    )
  }

  return (
    <>
      <Header title="Activity Logs" />
      <div className="content-container" style={{marginTop: "90px"}}>
        <div className="button-group" style={{display:"flex"}}>
          <Button type="primary" disabled={isImpersonated} icon={<PlusOutlined/>} onClick={showModal} >Add Activity</Button>
          <Button icon={<DownloadOutlined /> } disabled={isExportLogsLoading} >
            <CSVLink headers={headers} data={csvlist} filename={`${filename}`} style={{paddingLeft:4}} >Export</CSVLink></Button>
        </div>
        <DataTables
          columns={columns}
          dataSource={prevSixMonths()}
          rowKey="visit_id"
          emptyText="No Activity Logs to show"
          loading={isActivityLogsLoading}
          showTotal={showTotal}
          pagination={{
            showSizeChanger: true,
            showTotal,
            responsive: true,
            // total: prevSixMonths(),
            defaultPageSize:20,
            pageSizeOptions:[10, 20, 50, 100],
          }}
        />
          <Modal 
            visible={isModalVisible} 
            style={{top: "200px"}} 
            width={350} 
            maskClosable={false} 
            closable={false} 
            footer={null}
          >
            <Form
              form={modalForm}
              onFinish={modalOnFinish}
              labelCol={{ span: 11, offset: 0 }}
              labelWrap
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="activityLogDate"
                    label="Activity Log Date"
                    rules={[
                      { required: true, message: "Date is required!" },
                    ]}
                    >
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      disabledDate={disabledDate}
                      style={{width:"160px"}}
                      />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{textAlign:"right"}}>
                  <Button
                    onClick={() => {
                      setIsModalVisible(false);
                    }}
                  >
                    Close
                  </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSearching}
                      style={{
                        margin: '0 8px',
                      }}
                      >
                      Submit
                    </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
          {isDrawerVisible && renderDrawer()}
      </div>
    </>
  );
}

export default function ActivityLogs({isImpersonated}) {
  let props = { isImpersonated }
  return BaseLayout(ActivityLogsWrapper, props);
}
