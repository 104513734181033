import { Button, Checkbox, Col, Form, Modal, Row, Upload, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./MailModal.css";
import { getUserDetails,openNotification } from "../../api";
import { useEffect, useState } from "react";
import { useReportIssueMutation } from "../../services/user";
import { UploadOutlined } from '@ant-design/icons';

export default function MailModal({isVisible,setIsVisible}) {
    
    const [fileList, setFileList] = useState([]);
    const beforeUpload = (file) => {
        const isValidFormat = ['image/jpeg', 'image/png', 'image/svg', 'image/heic', 'application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/pdf', 'application/zip'].includes(file.type);
        if (!isValidFormat) {
          message.error('File type not supported!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('File must be smaller than 2MB!');
        }
        return (isValidFormat && isLt2M) || Upload.LIST_IGNORE;
      };
    const userDetails = getUserDetails();
    const [form] = Form.useForm();
    const [triggerReportIssue, {data:resp,isLoading:GetRespLoading, }] = useReportIssueMutation();
    useEffect(() => {
        if(resp?.success){
            setIsVisible(false)
            openNotification("Success!","success","Your email has been sent!");
            form.resetFields();
            setIsVisible(false);
        }
    },[resp])    

    const body = `Hi There,\
                  \n\n---------------------------------------\
                  \n\n Describe the problem or enhancement request here.... \
                  \n\n---------------------------------------\
                  \n\nRegards,\
                  \n${userDetails.name} (${userDetails.extension_ContactID})
                `

    const initialValues = {
                        body: body,
                        cc: true
                        }
    const formFinished = async (values) => {
        values.from = userDetails.emails[0];
        values.cc = values.cc ? userDetails.emails[0] : null
        triggerReportIssue({values, fileList});
    }
    const props = {
        name: 'file',
        action: process.env.REACT_APP_FT_PORTAL_URL + '/user/upload-image',
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        onChange(info) {
            console.log(info)
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };
    return(
        <>
            <Modal 
                visible={isVisible}
                maskClosable={true}
                closable={false}
                width={800}
                footer={null}
            >
                <Form
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={formFinished}
                    style={{paddingBottom: "20px"}}
                    encType="multipart/form-data"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="body"
                                label="Describe your problem here"
                                rules = {[{required: true, message: "Body is required!"}]}
                            >
                                <TextArea rows={10}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="cc"
                                valuePropName="checked"
                            >
                                <Checkbox defaultChecked={true}>
                                    CC to me
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                            name="files"
                            >
                                <Upload {...props} maxCount={10} beforeUpload={beforeUpload} accept="image/jpeg, image/png, image/svg, image/heic, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/pdf, application/zip" multiple>
                                    <Button icon={<UploadOutlined />}>Click to Upload </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{textAlign:"right"}}>
                        <Button
                            onClick={() => {
                            setIsVisible(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                        type="primary"
                        htmlType="submit"
                        loading={GetRespLoading}
                        style={{
                            margin: '0 8px',
                        }}
                        >
                        Send
                        </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
