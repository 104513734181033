import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAuditRackQuery, useGetSingleDcQuery, useGetUnknownRacksQuery } from "../services/datacenter";
import { Row, Col, Collapse, Descriptions, Divider, Input, Popover } from "antd";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
import BaseLayout from "./baselayout";
import Banner from "../components/Banner/Banner";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from "../components/Header/Header";
import DataTables from "../components/DataTables/DataTables";
import * as api from "../api";

function RackDetailsWrapper({type, isImpersonated} ) {

    const { Panel } = Collapse;
    const { data_center_id, rack_number } = useParams();
    console.log(data_center_id);
    console.log(rack_number);
    const [errorMsg, setErrormsg ] = useState(null);
    const [searchedText, setSearchedText ]= useState("")
    const [machineData, setMachineData] = useState([]);
    const {
        data: auditRackInfo,
        error: auditRackError,
        isFetching: isAuditRackFetching
    } = useGetAuditRackQuery({data_center_id, rack_number, machine_number:null}, {skip: type === 'unknown'});
   
    const {
        data: unknownRacksData, 
        error: unknownRackError,
        isFetching: isUnknownRacksFetching
    } = useGetUnknownRacksQuery({data_center_id}, {skip: type === 'known'});

    const {
        data: dc_data,
        error: dc_error,
        isFetching: dc_fetching,
      } = useGetSingleDcQuery({ dc_id: data_center_id });
    
    api.setLastPath(window.location.href);

    const navigate = useNavigate();
    const dcAccessRequired = (error) => {
        if(error?.status === 401 && error?.data?.message === "Authorization failed") {
          alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
          navigate('/datacenters');
        } else {
            console.log("error", error)
            api.trigger401(error,setErrormsg)
        }
    }

    const assignList = (data) => {
        let list = [];
        let i = 0;
        for (const item of data){
            if(item.machine_number || item.ip_address || item.net_mask || item.assest_tag || item.serial_number || item.product_name || item.region_number || item.gateway) {
                let newItem = JSON.parse(JSON.stringify(item));
                newItem['key'] = i++;
                list.push(newItem);
            }
        }
        return list;
    }

    useEffect(()=>{
        if(unknownRacksData && unknownRacksData.data){ 
            console.log(unknownRacksData);
            let list = assignList(unknownRacksData.data);
            setMachineData(list);
        }
        if(auditRackInfo &&  auditRackInfo.data){
            console.log(auditRackInfo);
            let list = assignList(auditRackInfo.data);
            setMachineData(list);
        }
    },[auditRackInfo, unknownRacksData])

    let auditRackInfoData;
    if(auditRackInfo){
        if(auditRackInfo.data.length > 0){
            auditRackInfoData=auditRackInfo.data
        }
    }

    useEffect(()=>{
        if(dc_error){
            console.log(dc_error);
            // if(dc_error.data) setMessage(error.data.message, "error", 10);
            dcAccessRequired(dc_error);
          }
          if(auditRackError){
            console.log(auditRackError);
            // if(auditRackInfoError.data) setMessage(error.data.message, "error", 10);
            dcAccessRequired(auditRackError);
          }
          if(unknownRackError) {
            console.log(unknownRackError);
            dcAccessRequired(unknownRackError);
          }
    },[auditRackError, dc_error, unknownRackError])

    const strCompare = (a, b, key) => {
        return (a[key] || "N/A").localeCompare(b[key]|| "N/A");
    };

    const columns =[
        {
            title: "Machine",
            dataIndex: "machine_number",
            key: "machine_number",
            filteredValue:[searchedText],
            onFilter:(value,record)=>{return String(record.machine_number)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.ip_address)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.net_mask)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.assest_tag)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.serial_number)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.product_name)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.region_number)
                .toLowerCase()
                .includes(value.toLowerCase())
                ||
                String(record.gateway)
                .toLowerCase()
                .includes(value.toLowerCase())
                },
            sorter: (a, b) => strCompare(a, b, "machine_number"),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a, b) => strCompare(a, b, "status"),
        },
        {
            title: "Asset Tag",
            dataIndex: "assest_tag",
            key: "asset_tag",
            sorter: (a, b) => strCompare(a, b, "assest_tag"),
        },
        {
            title: "Serial Number",
            dataIndex: "serial_number",
            key: "serial_number",
            sorter: (a, b) => strCompare(a, b, "serial_number"),
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
            sorter: (a, b) => strCompare(a, b, "product_name"),
        },
        {
            title: "Region Number",
            dataIndex: "region_number",
            key: "region_number",
            sorter: (a, b) => strCompare(a, b, "region_number"),
        },
        {
            title: "Console",
            dataIndex: "console",
            key: "console",
            sorter: (a,b) => strCompare(a,b, "console")
        },
        {
            title: "IP Address",
            dataIndex: "ip_address",
            key: "ip_address",
            sorter: (a, b) => strCompare(a, b, "ip_address"),
        },
        {
            title: "Subnet Mask",
            dataIndex: "net_mask",
            key: "net_mask",
            sorter: (a, b) => strCompare(a, b, "net_mask"),
        },
        {
            title: "Gateway",
            dataIndex: "gateway",
            key: "gateway",
            sorter: (a, b) => strCompare(a, b, "gateway"),
        },
    ]
    
    const showTotal = (total) =>
        `Total ${total} Machines` ;

    return(
        <>
            {errorMsg && <Banner msg={errorMsg} type="error" />}
            <Header title="Rack Details" />
            <Row>
                <Col lg={18} md={14} xs={24}>
                    <Breadcrumbs />
                </Col>
            </Row>
            <Divider />
            <div className="content-container">
            { (isAuditRackFetching || isUnknownRacksFetching)? < AkamaiPreloader /> :
                <>
                <Collapse bordered={false} style={{backgroundColor:"rgb(255,255,255)"}}>
                    <Panel header={`Rack Info`} key="1" style={{border:0,overflow:"hidden",backgroundColor:"rgb(248,248,248)",fontWeight:500}}>
                        <Descriptions bordered column={1} size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                            <Descriptions.Item label="Datacenter Name">{dc_fetching ? "Loading" : (dc_data?.data?.[0].name || "NA" ) }</Descriptions.Item>
                            <Descriptions.Item label="Datacenter Location">{dc_fetching ? "Loading" : (dc_data?.data?.[0].location || "NA" ) }</Descriptions.Item>
                            {type === 'known' && 
                            (<><Descriptions.Item label="Floor">{auditRackInfoData && auditRackInfoData[0]?.['R_floor'] ? auditRackInfoData[0]['R_floor']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Row">{auditRackInfoData && auditRackInfoData[0]?.['R_row'] ? auditRackInfoData[0]['R_row']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Column">{auditRackInfoData && auditRackInfoData[0]?.['R_column'] ? auditRackInfoData[0]['R_column']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Type">{auditRackInfoData && auditRackInfoData[0]?.['type'] ? auditRackInfoData[0]['type']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Post Width">{auditRackInfoData && auditRackInfoData[0]?.['post_width'] ? auditRackInfoData[0]['post_width']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Holes">{auditRackInfoData && auditRackInfoData[0]?.['holes'] ? auditRackInfoData[0]['holes']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Cage Number">{auditRackInfoData && auditRackInfoData[0]?.['cage_number'] ? auditRackInfoData[0]['cage_number']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Circuits Per Rack">{auditRackInfoData && auditRackInfoData[0]?.['circuits_rack'] ? auditRackInfoData[0]['circuits_rack']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Voltage">{auditRackInfoData && auditRackInfoData[0]?.['voltage'] ? auditRackInfoData[0]['voltage']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Amps Per Circuit">{auditRackInfoData && auditRackInfoData[0]?.['amps_circuit'] ? auditRackInfoData[0]['amps_circuit']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Outlets Per Circuit">{auditRackInfoData && auditRackInfoData[0]?.['outlets_circuit'] ? auditRackInfoData[0]['outlets_circuit']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Outlet Type">{auditRackInfoData && auditRackInfoData[0]?.['outlet_type'] ? auditRackInfoData[0]['outlet_type']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Lock Combination">{auditRackInfoData && auditRackInfoData[0]?.['assigned_locks'] ? auditRackInfoData[0]['assigned_locks']: "NA"}</Descriptions.Item>
                            <Descriptions.Item label="Status">{auditRackInfoData && auditRackInfoData[0]?.['Active'] ? (auditRackInfoData[0]['Active'] === 'Y' ? "Active" : "Inactive"): "NA"}</Descriptions.Item></>
                            )}
                        </Descriptions>
                    </Panel>
                </Collapse>
                <div style = {{margin:"25px 0"}}>
                <Row>
                    <Col lg={18} md={14} xs={24}>
                        <h2 style={{marginBottom:0, paddingLeft:"2px"}}>Machine Details</h2>
                    </Col>
                    <Col className="search-menu-wrapper" lg={6} md={10}  xs={24} style={{marginTop:0}}>
                    <Popover content={"Hint: Machine number/ Serial Number / IP address"}trigger="hover">
                        <Input placeholder="Search" style={{width:200}}
                        allowClear
                        onChange={(e)=>{
                            setSearchedText(e.target.value)
                        }}
                        />
                    </Popover>
                    </Col>
                </Row>
                </div>
                <DataTables 
                    columns={columns}
                    rowKey="key"
                    emptyText="No machines for this Rack"
                    dataSource={machineData}
                    showTotal={showTotal}
                    loading={isAuditRackFetching}
                />
                </>
                } 
            </div> 
        </>
    )
}

export default function RackDetails({type, isImpersonated}) {
    let props = {type, isImpersonated}
    return BaseLayout(RackDetailsWrapper, props);
}