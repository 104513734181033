import { Collapse, Col, Descriptions, Divider, Row, Button, List} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
import Banner from "../components/Banner/Banner";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from "../components/Header/Header";
import { useGetSearchResultsQuery, useLazyGetTicketHistoryQuery } from "../services/datacenter";
import BaseLayout from "./baselayout";
import { ReactComponent as RedirectIconLarge } from "../Assets/images/redirect-icon-large.svg"
import Icon from "@ant-design/icons"; 
import DataTables from "../components/DataTables/DataTables";
import * as api from "../api";

function SearchPageWrapper({ isImpersonated }){

    const [urlParams] =  useSearchParams();
    const searchMapping = {
        'ticket_id':'ticket_id',
        'rma_no': 'rma_no',
        'rack_no':'rack_number',
        'serial_no':'serial_number',
        'ip':'ip_address',
        'tracking_no':'tracking_number'
    }
    const textMapping = {
        'ticket_id':'Ticket ID',
        'rma_no': 'RMA Number',
        'rack_number':'Rack Number',
        'serial_number':'Serial Number',
        'ip_address':'IP Address',
        'tracking_number':'Tracking Number'
    }
    const search_type  = searchMapping[[...urlParams.keys()][0]]
    const search_string = [...urlParams.values()][0]
    const {Panel} = Collapse;
    const navigate = useNavigate();
    
    const [errorMsg, setErrormsg] = useState(null);
    const [searchResult,setSearchResult] = useState([]);
    const [noAccess, setNoAccess] = useState(false)
    
    const {
        data:searchResultResp,
        error: searchError,
        isFetching: isSearchFetching
    } = useGetSearchResultsQuery({[search_type]:search_string})

    const [triggerGetTicketHistory ,{data: historyDetails, error: historyError, isFetching: isHistoryFetching}] = useLazyGetTicketHistoryQuery();
    
    useEffect(() => {
        if (searchError) {
          console.log(`search error - ${JSON.stringify(searchError)}`);
          api.trigger401(searchError, setErrormsg);
        }
        if (historyError) {
          console.log(`issue-activity error - ${JSON.stringify(historyError)}`);
          api.trigger401(historyError, setErrormsg);
        }
    }, [searchError, historyError]);

    useEffect(()=>{
        if(!isSearchFetching){
            if(searchResultResp?.message === "No access"){
                setNoAccess(true);
                return;
            }
            setNoAccess(false)

            if(searchResultResp?.data?.length > 0){
                setSearchResult(searchResultResp.data)
                console.log("search result resp",searchResultResp.data)
                const item = searchResultResp.data[0]
                if(searchResultResp.data.length === 1 && (search_type === 'ticket_id'|| search_type === 'rack_number' || search_type === "tracking_number" || search_type === 'rma_no'))
                {
                    let sub_page,id;
                    if(search_type === "ticket_id" || search_type === "tracking_number"){
                        sub_page = "todos"
                        id = item.issue_id
                    } else if(search_type === 'rma_no') {
                        sub_page = 'rmas';
                        id = item.issue_id
                    }
                    else{
                        sub_page = "rack"
                        id = item.rack_number
                    }
                    navigate(`/datacenters/${item.data_center_id}/${sub_page}/${encodeURIComponent(id)}`)
                }
                else {
                    triggerGetTicketHistory({[search_type]:item[search_type]})
                }
            }
            else{
                setSearchResult([])
            }
        }
        },[isSearchFetching,searchResultResp])
        
    const renderAssetDetails = () => {
        let columns = [
            {
                title:"Ticket ID",
                dataIndex: "issue_id",
                key: "issue_id",
                render: (text, record) => (
                    <Link to={`/datacenters/${record.data_center_id}/todos/${text}`} key={text} target="_blank" rel="noopener noreferrer">
                        <Button type="link" style={{padding:0, top:1}}>
                                        {text} <Icon component={RedirectIconLarge}/>
                        </Button>
                    </Link>
                )
            },
            {
                title:"Created Date",
                dataIndex: "created_date",
                key: "created_date",
                // sorter: (a, b) => a.name.localeCompare(b.name)
            },
            {
                title:"Datacenter Name",
                dataIndex: "data_center_name",
                key: "data_center_name",
            },
            {
                title:"Datacenter Location",
                dataIndex: "data_center_location",
                key: "data_center_location",
            },
        ]
        return(
            <Collapse size="large" defaultActiveKey={['1','2','3']}>
                <Panel header={<b>Hardware Asset Information</b>} key="1">
                    <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                    <Descriptions.Item label="Serial Number" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.serial_number) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Product Type" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.product_name) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Data Center Name" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.data_center_name) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Data Center Location" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.data_center_location) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Rack Number" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.rack_number) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Machine Number" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.machine_number) || "N/A"}</Descriptions.Item>
                    <Descriptions.Item label="Asset Tag" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.asset_tag) || "N/A"}</Descriptions.Item>
                    </Descriptions>
                </Panel>
                <Panel header={<b>IP Information</b>} key="2">
                    <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                        <Descriptions.Item label="IP Address" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.ip_address) || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Net Mask" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.net_mask) || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label="Gateway" span={3}>{ isHistoryFetching ? "Loading..." : (searchResult?.[0]?.gateway) || "N/A"}</Descriptions.Item>
                    </Descriptions>
                </Panel>
                <Panel header={<b>Ticket History</b>} key="3">
                    <DataTables
                        columns={columns}
                        dataSource={historyDetails?.["data"]|| []}
                        emptyText="No data to display"
                        rowKey="issue_id"
                        loading={isHistoryFetching}
                        scroll={undefined}
                        bordered
                        style={{fontWeight:400}}
                    />

                </Panel>
            </Collapse>
        )
    }

    const renderList = () => {
        
        const columns = [
            {
                title: "Ticket ID",
                dataIndex: "issue_id",
                key: "issue_id",
                show: "ticket_id",
                render:(text,record) => (
                    <Link to={`/datacenters/${record.data_center_id}/todos/${text}`} key={text}>
                        <Button type="link" style={{padding:0, top:1}}>
                                        {text} <Icon component={RedirectIconLarge}/>
                        </Button>
                    </Link>
                )
            },
            {
                title: "Ticket ID",
                dataIndex: "issue_id",
                key: "issue_id",
                show: "tracking_number",
                render:(text,record) => (
                    <Link to={`/datacenters/${record.data_center_id}/todos/${text}`} key={text}>
                        <Button type="link" style={{padding:0, top:1}}>
                                        {text} <Icon component={RedirectIconLarge}/>
                        </Button>
                    </Link>
                )
            },
            {
                title: "Rack Number",
                dataIndex: "rack_number",
                key: "rack_number",
                show: "rack_number",
                render:(text,record) => (
                    <Link to={`/datacenters/${record.data_center_id}/rack/${encodeURIComponent(text)}`} key={text}>
                        <Button type="link" style={{padding:0, top:1}}>
                                        {text} <Icon component={RedirectIconLarge}/>
                        </Button>
                    </Link>
                )
            },
            {
                title: "Datacenter Name",
                dataIndex: "data_center_name",
                key: "data_center_name",
                show: "all"
            },
            {
                title: "Datacenter Location",
                dataIndex: "data_center_location",
                key: "data_center_location",
                show: "all"
            }
        ].filter((item) => (item.show === "all" || item.show === search_type));

        return( 
            <DataTables
                dataSource={searchResult}
                columns = {columns}
                emptyText = "No data matches found "
                rowKey = "data_center_id"
                loading={isSearchFetching}
                scroll={undefined}
                bordered
                style={{fontWeight:400}}
            />)
        }

    return (
        <>
            <Header title={`Search Results for ${textMapping[search_type]} : ${search_string}`}/>
            {errorMsg && <Banner msg={errorMsg} type="error" />}
            <Row>
                <Col lg={18} md={14} xs={24}>
                    <Breadcrumbs />
                </Col>
            </Row>
            <Divider />
            {( isSearchFetching ? <AkamaiPreloader/> : 
                <div className="content-container">
                    {
                        searchResult.length === 0 ?
                            noAccess?
                                <div>You have no access to view the following ID <br/> &#x2022; {search_string}</div> :
                                <div style={{textAlign:"center"}}>No data found :( </div> :
                            (search_type === 'ticket_id'|| search_type === 'rack_number' || search_type === "tracking_number") ? renderList(): renderAssetDetails()
                    }
                </div>
            
            )}
        </>
    )
}

export default function SearchPage({ isImpersonated }) {
    let props = { isImpersonated };
    return BaseLayout(SearchPageWrapper, props);
}