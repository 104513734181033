import { getUserDetails } from "./api";

export function is_internal() {
    try {
      let user = getUserDetails();
      if(user.emails && user.emails.length > 0) {
        if(user.emails[0].endsWith("@akamai.com")) return user.emails[0];
      }
      return null;
      // check if the email entered contains the suffix @akamai.com
    } catch (error) {
      console.log(error);
      return null;
    }
  }

export function setImpersonationHeaders(headers) {
  if(localStorage.getItem("isImpersonated")) {
    headers.set("isImpersonated", true);
    headers.set("impersonatedUser", localStorage.getItem("IMP_extension_ContactID"))
  }
  return headers
}