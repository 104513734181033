import { Table } from "antd";
import AkamaiPreloader from "../AkamaiPreloader/AkamaiPreloader";

export default function DataTables({
  columns,
  dataSource,
  loading,
  rowKey,
  showTotal,
  emptyText,
  expandable,
  onChange,
  ...props
}) {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      locale={{ emptyText: emptyText }}
      onChange={onChange}
      scroll={{
        x: "max-content",
      }}
      expandable={expandable}
      rowKey={rowKey}
      loading={{
        indicator: (
          <div>
            {" "}
            <AkamaiPreloader style={{ position: "absolute" }} />
          </div>
        ),
        spinning: loading,
      }}
      // pagination={{
      //   showSizeChanger: true,
      //   showTotal,
      //   responsive: true,
      //   total: { dataSource },
      //   pageSizeOptions:[10, 20, 50, 100, 500, 1000],
      // }}
      pagination = {false}
      showSorterTooltip={false}
      {...props}
    />
  );
}