import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Layout } from "antd";

export default function BaseLayout(WrappedComponent, properties) {
  const { Content } = Layout;
  return (
    <Layout>
      {/* <Header /> */}
      <Content className="content">
        <WrappedComponent {...properties} />
      </Content>
      <Footer />
    </Layout>
  );
}
