import React from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.css";

function capitalizeFirstLetter(string) {
  if (string.includes("rma")) {
    string = string.replace("rma", "RMA");
  }
  if (string.includes("datacenters")) {
    string = string.replace("datacenters", "Datacenters");
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Breadcrumbs() {
    const location = useLocation();
    let pathSnippets = location.pathname.split("/").filter((i) => i);
    const Items = pathSnippets.map((key, index) => {
    let url1 = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    let url2 = `/${pathSnippets.slice(0, index + 2).join("/")}`;
    let url=url2
    if (index===0){
      url=url1
    }
    if(index===3){
      url="#"
    }
    if (
      pathSnippets[index] === "todos" ||
      pathSnippets[index] === "rmas" ||
      pathSnippets[index] === "schedule-visit" ||
      pathSnippets[index] === "audit-log"||
      pathSnippets[index] === "upcoming-shipments"||
      pathSnippets[index] === "rack"
    ) {
      return null;
    }
    if (
      pathSnippets[index + 1] === "todos" ||
      pathSnippets[index + 1] === "rmas" ||
      pathSnippets[index + 1] === "schedule-visit"||
      pathSnippets[index + 1] === "upcoming-shipments"
    ) {
      pathSnippets[index] = `${pathSnippets[index + 1]} (${
        pathSnippets[index]
      })`;
    }
    if (
      pathSnippets[index + 1] === "audit-log"
      ) {
        pathSnippets[index] = `audit (${pathSnippets[index]})`;
      }
    if( 
      pathSnippets[index + 1] === "rack"
      ){
        pathSnippets[index] = `rack (${decodeURIComponent(pathSnippets[index])})`;
        url=url.replace("rack", "audit-log");
    }
    if( 
      pathSnippets[index + 1] === "unknown-racks"
      ){
        pathSnippets[index] = `rack (${decodeURIComponent(pathSnippets[index])})`;
        url=url.replace("unknown-racks", "audit-log");

    }
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{capitalizeFirstLetter(decodeURIComponent(decodeURIComponent(pathSnippets[index])))}</Link>
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb className="breadcrumb">{Items}</Breadcrumb>;
}
