import {
  Button,
  Form,
  Input,
  Checkbox,
  DatePicker,
  Select,
  notification,
  Spin,
  Modal,
  Result
} from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import BaseLayout from "./baselayout";
import DataTables from "../components/DataTables/DataTables";
import {
  useGetTodosQuery,
  useGetRackInfoQuery,
  useUpdateScheduleVisitMutation,
  useGetSingleDcQuery,
  useGetIssueActivityQuery,
  useGetRecipientsQuery
} from "../services/datacenter";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { getUserDetails, trigger401 } from "../api";
import Banner from "../components/Banner/Banner";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
import { LockTwoTone } from "@ant-design/icons";
import Header from "../components/Header/Header";
import * as api from "../api";

function ScheduleVisitWrapper( {isImpersonated} ) {

  const userDetails                                   = getUserDetails();
  const [triggerUpdate, updateResult]                 = useUpdateScheduleVisitMutation();
  const [form]                                        = Form.useForm();
  const [issuesSelected, setIssuesSelected]           = useState([]);
  const [scErrorMsg, setScErrorMsg]                   = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [defaultCCList, setDefaultCCList]             = useState([]);
  const [ccListCheckbox, setCCListCheckBox]           = useState(false);
  const [openConfirmModal, setOpenConfirmModal]       = useState(false);
  const [openSuccessModal, setOpenSuccessModal]       = useState(false);
  const [selectedRack, setSelectedRack]               = useState([])
  const [isSecure, setIsSecure]                       = useState(false)
  const [isSecureTable, setIsSecureTable]             = useState(false)
  const [rackSecure, setRackSecure]                   = useState({})
  const [dates, setDates]                             = useState(null)
  const [tableList, setTableList]                     = useState([])
  const [value, setValue]                             = useState(null);
  const [startDate, setStartDate]                     = useState(null);
  const [endDate, setEndDate]                         = useState(null)
  const { RangePicker }                               = DatePicker;
  const { data_center_id }                            = useParams();
  const {
    data: dc_data,
    error: dc_data_err,
    isLoading: dc_data_loading,
  } = useGetSingleDcQuery({ dc_id: data_center_id });

  const {
    data: recipientData,
    error: ccError,
    isLoading: ccLoading,
    isFetching: ccFetching
  } = useGetRecipientsQuery({ data_center_id });

  const {
    data: TodoData,
    error,
    isLoading,
    isSuccess,
  } = useGetTodosQuery({ data_center_id, type: "todos" });

  const {
    data: rackInfo,
    error: rackInfoError,
    isLoading: isRackInfoLoading,
  } = useGetRackInfoQuery({ data_center_id });

  const {
    data: issueDetails,
    error: activityError,
    isLoading: isActivityLoading,
  } = useGetIssueActivityQuery(
    { data: TodoData },
    { skip: !isSuccess || TodoData?.data?.length === 0 }
  );

  let todosList;
  let racksList;
  const [formInputs, setFormInputs] = useState(null);

  api.setLastPath(window.location.href);

  const navigate = useNavigate();
  const dcAccessRequired = (error) => {
      if(error?.status === 401 && error?.data?.message == "Authorization failed") {
          alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
          navigate('/datacenters');
      } else {
          trigger401(error,setScErrorMsg)
      }
  }
  useEffect(() => {
    let newRack = {}
    if(rackInfo && rackInfo.data){
      rackInfo.data.forEach(rack => {
        newRack[rack.rack_id] = rack.type
      })
      setRackSecure({...newRack})
    }
  }, [rackInfo])
  useEffect(() => {
    if (error) {
      console.log(error);
      dcAccessRequired(error);
    }
    if(ccError) {
      console.log(ccError);
      if (ccError.data && ccError.data.hasOwnProperty("message") && ccError.data.message.includes("No NIE has been assigned")) {
        setScErrorMsg(ccError.data.message);
      } else {
        dcAccessRequired(ccError);
      }
      setDisableSubmitButton(true);
    }
    if(dc_data_err){
      console.log(dc_data_err);
      dcAccessRequired(dc_data_err);
    }
    if(activityError){
      console.log(activityError);
      dcAccessRequired(activityError);
    }
    if(updateResult?.error){
      console.log(updateResult.error);
      dcAccessRequired(updateResult.error)
    }

    rackInfoError &&
      api.openNotification("Error getting rack details!", "error", JSON.stringify(rackInfoError), 10)
      if (TodoData && TodoData.success && TodoData.data) {
        setIssuesSelected(TodoData.data.map((todos) => todos.issue_id));
        setTableList(TodoData.data.map((todos) => todos.rack_id));
      }

      if(recipientData) {

        let ccEmailsData = isImpersonated ? [localStorage.getItem("IMP_email")] : userDetails.emails;
        let ccEmails;
        console.log(userDetails.emails);
        
        if (recipientData?.data?.ccEmails?.length){
          console.log("cc emails found")
          ccEmails = recipientData.data.ccEmails;
        } else {
          console.log("cc emails not found")
          ccEmails =  userDetails ? ccEmailsData: [];
        }
        console.log(ccEmails);

        form.setFieldsValue({
          cc: ccEmails,
          send_to: recipientData.data.toEmails,
          permanentaccess: false,
          name: isImpersonated ? localStorage.getItem("IMP_name") : (userDetails ? `${userDetails.name}` : ""),
          racks: [],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      }
      return () => {
        notification.destroy();
      }
  }, [error, ccError, rackInfoError, isSuccess, TodoData, recipientData, rackInfo], dc_data_err, activityError, updateResult);

  useEffect(() => {
      console.log('selected', selectedRack);
      let secureRacks = []
      selectedRack && selectedRack.forEach( obj => {
        if( obj && rackSecure[obj.toString()] === "4-Post Secure")
          {
            secureRacks.push(obj)
          }
      })
    if(secureRacks.length > 0){
      setIsSecure(true)
    }
    else
    {
      setIsSecure(false)
    }
  }, [selectedRack])

  useEffect(() => {
    let secureRacks = []
    console.log("tableList", tableList)
    tableList && tableList.forEach( obj => {
      if( obj && rackSecure[obj.toString()] === "4-Post Secure")
        {
          secureRacks.push(obj)
        }
    })
    if(secureRacks.length > 0){
      setIsSecureTable(true)
    }
    else
    {
      setIsSecureTable(false)
    }
  }, [tableList])

  if (TodoData) {
    if (!TodoData.success) {
      console.log("failed");
    }
    if (TodoData.data) {
      todosList = TodoData.data;
    }
  }

  if(rackInfo) {
    if(rackInfo.data) racksList = rackInfo.data
  }

  if (issueDetails) {
    let issuesActivity = {};
    for (let data of issueDetails.data) {
      issuesActivity[data.issue_id.toString()] = {
        summary: data.summary,
        activity_id: data.activity_id,
      };
    }
    todosList = todosList.map((item) => ({
      summary:
        item.issue_id in issuesActivity
          ? issuesActivity[item.issue_id].summary
          : "N/A",
      activity_id:
        item.issue_id in issuesActivity
          ? issuesActivity[item.issue_id].activity_id
          : "N/A",
      ...item,
    }));
  }

  const onCCListChange = (values) => {
    console.log(values);
    setDefaultCCList(values);
  }

  const onCCListChecked = (e) => {
    console.log(`cc list checkbox - ${e.target.checked}`)
    setCCListCheckBox(e.target.checked);
  }

  const onFinish = (values) => {
    console.log(openConfirmModal);
    setFormInputs(values);
    setOpenConfirmModal(true);
    return;
  }

  const handleCancel = () => {
    setFormInputs(null);
    setOpenConfirmModal(false);
    setOpenSuccessModal(false);
  }

  const handleOk = () => {
    setOpenSuccessModal(false);
    window.location.href = "/datacenters";
    return;
  }

  const handleSubmit = async () => {
    setOpenConfirmModal(false);
    let values = formInputs;
    console.log(values);
    if (issuesSelected && issuesSelected.length > 0) {
      delete values["reasonformaint"];
      values["issueIds"] = issuesSelected;
    }
    values["accesstime"] = values.accesstime.map((date) => date.format());
    values["cc"] = values.cc.map(id=>id.toLowerCase());
    console.log("Form Values:", values);

    values['ccListCheckbox'] = ccListCheckbox;

    let issueMap = [];
    let rackMap = [];

    issueMap = todosList
    .filter(todo => values['issueIds']?.includes(todo.issue_id))
    .map(issue => ({issue_id: issue.issue_id, rack_id: issue.rack_id, rack_number: issue.rack_number, ip_asset_ip_address: issue?.ip_asset_ip_address}));
    
    rackMap = racksList
    .filter(rack => values['racks']?.includes(rack.rack_id))
    .map(rack => ({rack_number: rack.rack_number, rack_id: rack.rack_id, type: rack.type}))

    values['issueMap'] = issueMap;
    values['rackMap'] = rackMap;
    try {
      const resp = await triggerUpdate({
        data_center_id,
        scheduleVisitData: values,
      }).unwrap();
      console.log("response of post", resp);
      if (resp && resp.success) {
        console.log(resp);
        setOpenSuccessModal(true);
        // api.openNotification("Successfully submitted your request!", "success", "" ,0);
        form.setFieldsValue({
          accesstime: [moment().add(35, 'minutes')],
          racks: []
        })
      }
    } catch (err) {
      console.log(err, updateResult);
      if (
        err.status === 400 &&
        err.data &&
        err.data.hasOwnProperty("validation_error")
      ) {
        // validation_error is for pydantic validation error messages
        const msgs = err.data.validation_error.body_params.map(
          (e) =>
            `${e.msg}  ${
              e.loc[0] !== "__root__" ? `in field: ${e.loc[0]}` : ""
            }`
        );
        api.openNotification("Validation errors!", "error", msgs.join("\n"), 10);
      } else if (err.data && err.data.hasOwnProperty("message")) {
        api.openNotification("Error scheduling visit!", "error", err.data.message, 10);
      } else {
        api.openNotification("Error submitting the schedule visit request!", "error", "Check with support team or try again later", 10);
      }
    } 
  };

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "issue_id",
      key: "issue_id",
    },
    {
      title: "Rack",
      dataIndex: "rack_number",
      key: "rack_number",
      render: (text, record) =>
        text ? (
          record.region_network === "ESSL" ? (
            <>
              {text + " "}
              <LockTwoTone />
            </>
          ) : (
            <>{" " + text}</>
          )
        ) : (
          "N/A"
        ),
    },
    {
      title: "Machine",
      dataIndex: "hardware_asset_machine_number",
      key: "hardware_asset_machine_number",
    },
    {
      title: "IP Address",
      dataIndex: "ip_asset_ip_address",
      key: "ip_asset_ip_address",
    },
    {
      title: "Serial Number",
      dataIndex: "current_hardware_serial_number",
      key: "current_hardware_serial_number",
    },
    {
      title: "Todo",
      dataIndex: "summary",
      key: "summary",
      ellipsis: true,
      render: (text) =>
        text && text.length > 120 ? text.substring(0, 120).concat("...") : text,
    },
  ];

  const rowSelection = {
    selectedRowKeys: issuesSelected,
    onChange: (selectedRowKeys) => {
      console.log(selectedRowKeys);
      setIssuesSelected([...selectedRowKeys]);
      setTableList(selectedRowKeys.map( key => TodoData.data.filter( issue => issue.issue_id == key )[0]['rack_id']))
    },
  };
  
  const disabledDate = (current) => {
    if (dates && dates[0] && (isSecure || isSecureTable ) ) {
      return (
        current &&
        (current < dates[0].clone().startOf("day") ||
          current > dates[0].clone().add(1, "days").endOf("day"))
      );
    }
    return current && current < moment().startOf("day");
  }; 

  const disabledTime = (current) => {
    if (!dates || dates.length !== 0 || !dates[0] || !dates[1] || (!isSecure && !isSecureTable) ) {
      return {};
    }
    console.log("dates", dates)
    const startHour = dates && dates[0].clone().hour();
    const startMinute = dates && dates[0].clone().minute();
    // const endHour = startHour + 24;
    // const endMinute = startMinute;
    console.log("startHour", startHour)
    console.log("startMinute", startMinute)
    return {
      disabledHours: () => {
        const hours = range(0, 24).splice(startHour+1);
        return hours;
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === startHour) {
          return range(0, 60).splice(startMinute);
        }
        return [];
      },
    };
  };

  const handleCalendarChange = (dates) => {
    if (dates && dates[0]) {
      setDates(dates)
    } else {
      setDates(dates)
    }
  };
  
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const getTitle = () => {
    console.log(dc_data);
    let title = "Schedule a Visit ";
    if(dc_data && dc_data.data && dc_data.data.length) title += `to ${dc_data.data[0].name}, ${dc_data.data[0].location}`;
    return title;
  }

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  return (
    <>
      <Header title={getTitle()} />
      {scErrorMsg && <Banner msg={scErrorMsg} type="error" /> }
        <Breadcrumbs />
        <div className="content-container">
          <Spin indicator={<AkamaiPreloader />} spinning={updateResult.isLoading || ccLoading || ccFetching || isLoading || isActivityLoading} >
          <Form
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 8, offset: 0 }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ span: 16 }}
            initialValues={{
              cc: [],
              send_to: [],
              permanentaccess: false,
              name: userDetails ? `${userDetails.name}` : "",
              racks: [],
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              accesstime: [moment().add(35, 'minutes'), moment().add(1, 'day').add(34, 'minutes')],
              phonenumber: localStorage.getItem('userDetails') !== "null" ? localStorage.getItem('userDetails') :null
            }}
          >

            <div style={{ marginBottom: "20px" }}>
              <DataTables
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={todosList}
                rowKey="issue_id"
                loading={false}
                onChange={(value) => setTableList(value)}
                emptyText="No pending tasks in this Data Center"
                pagination={false}
              />
            </div>
            <Form.Item
              name="reasonformaint"
              label="Reason for maintenance"
              rules={[
                {
                  required: issuesSelected && issuesSelected.length === 0,
                  message:
                    "Reason for maintenance is required when you do not have issues to work on!",
                },
              ]}
            >
              <Input.TextArea
                rows={3}
                placeholder="Give reason for maintenance when you do not have issues to work on!"
                disabled={issuesSelected && issuesSelected.length > 0}
                allowClear
              />
            </Form.Item>

            <Form.Item
              name="racks"
              label="Additional Racks you will be working on"
            >
              <Select
                mode="multiple"
                placeholder="Choose Racks"
                allowClear
                loading={isRackInfoLoading}
                optionFilterProp="label"
                filterOption={(input, option) => {
                  if(input) input = input.toLocaleLowerCase();
                  return (option?.searchvalue ? option.searchvalue.toLowerCase() : '').includes(input)
                }}
                filterSort={(optionA, optionB) =>
                  (optionA?.searchvalue ? optionA.searchvalue : '').toLowerCase().localeCompare((optionB?.searchvalue ? optionB.searchvalue : '').toLowerCase())
                }
                onChange={(value) => setSelectedRack(value)}
                options={
                  rackInfo && rackInfo.success
                    ? rackInfo.data.map((r) => {
                        return { label: r.type==="4-Post Secure" ? <>{r.rack_number}  <LockTwoTone/> </> :r.rack_number
                        , value: r.rack_id, searchvalue: r.rack_number?.toString() };
                      })
                    : []
                }
              ></Select>
            </Form.Item>

            <Form.Item
              name="timezone"
              label="Time Zone"
              rules={[{ required: true, message: "Timezone is required!" }]}
            >
              <Select
                showSearch
                options={Intl.supportedValuesOf("timeZone").map((t) => {
                  return { label: t, value: t };
                })}
              ></Select>
            </Form.Item>

            <Form.Item
              name="accesstime"
              label="Access Start & End Time"
              rules={[
                { required: true, message: "Access time is required!" },
                {
                  validator(_, value) {
                    if (value && Array.isArray(value) && value.length === 2) {
                      const start = value[0];
                      const end = value[1]
                      const add30 = moment().add(30, 'minutes');
                      const add1 = value[0].clone().add(1, 'day').add(1, 'minute')
                      if (start.isBefore(add30)) {
                        return Promise.reject(
                          new Error(
                            "Start time cannot be less than 30 minutes from now"
                          )
                        );
                      }
                      if (end.isAfter(add1) && (isSecure || isSecureTable)){
                        return Promise.reject(
                          new Error(
                            "The duration to access a secure rack should not be more than 24 hours."
                          )
                        )
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <RangePicker
                value={dates}
                format={"YYYY-MM-DD HH:mm"}
                showTime={{ showSecond: false, format: "HH:mm" }}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                onCalendarChange={handleCalendarChange}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
            <Form.Item
              name="send_to"
              label="Send this email to"
              rules={[{ required: true, type: "array", defaultField: { type: "email", message: "Enter valid emails seperated by ','" } }]}
            >
               <Select placeholder="Comma seperated Email ids" mode="tags" tokenSeparators={[',']} dropdownStyle={{ display: "none" }} allowClear disabled={process.env.REACT_APP_ENVIRONMENT === 'production'}></Select>
            </Form.Item>
            <Form.Item
              name="cc"
              label="CC this information to"
              rules={[{ required: false, type: "array", defaultField: { type: "email", message: "Enter valid emails seperated by ','" } }]}
            >
               <Select onChange={onCCListChange} placeholder="Comma seperated Email ids" mode="tags" tokenSeparators={[',']} dropdownStyle={{ display: "none" }} allowClear></Select>
            </Form.Item>
            { defaultCCList.length > 0 && <Form.Item name="cc_checkbox" valuePropName="cc_checkbox" label="">
              <Checkbox style={{"alignItems": "inherit", "marginLeft": "51%"}} onChange={onCCListChecked}>
                Save cc list as default
              </Checkbox>
            </Form.Item>}
            <Form.Item
              name="name"
              label="Your Name"
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input readOnly={userDetails} />
            </Form.Item>

            <Form.Item
              name="phonenumber"
              label="Your Phone Number"
              rules={[
                { required: true, message: "Phone number is required with a valid country code!" },
                {
                  validator(_, value) {
                    // Phone number validation
                    if(value){
                      const ph = parsePhoneNumber(value);
                      if (ph.isValid()) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter valid phone number with country code!'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Enter phone number with country code" />
            </Form.Item>

            <Form.Item name="permanentaccess" valuePropName="checked">
              <Checkbox value="permanent access">
                I have permanent access to this datacenter
              </Checkbox>
            </Form.Item>

            {(!ccLoading && !ccFetching && !ccError) && <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={updateResult.isLoading || isImpersonated || disableSubmitButton}
              >
                Submit
              </Button>
            </Form.Item>}
           </Form>
          </Spin>
          <Modal
            visible={openConfirmModal}
            onOk={handleSubmit}
            onCancel={handleCancel}
            title="Confirm Schedule Visit"
            footer={[
              <>
              <Button key="return" onClick={handleCancel}>
                Return
              </Button>
              <Button key="submit" type="primary" onClick={handleSubmit}>
                Submit Request
              </Button>
              </>
            ]}>
              <p>Please review the information before submitting. Multiple schedule visit requests for the same time slot in a datacenter are prohibited. <br />Are you sure you wish to continue?</p>
          </Modal>
          <Modal
            visible={openSuccessModal}
            closable={null}
            footer={null}
            width={720}>
              {/* <p><CheckCircleTwoTone />Successfully submitted your request!</p> */}
              <Result
                status="success"
                title="Request submitted successfully!"
                extra={[
                  <Button type="primary" key="console" onClick={handleOk}>Go to Datacenters</Button>
                ]}
            />
            </Modal>
        </div>
    </>
  );
}

export default function ScheduleVisit( { isImpersonated } ) {
  let props = { isImpersonated };
  return BaseLayout(ScheduleVisitWrapper, props);
}
