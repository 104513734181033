import { useState, useEffect, useRef } from "react";
import BaseLayout from "./baselayout";
import * as api from "../api";
import "../Assets/css/App.css";
import Header from "../components/Header/Header";
import DataTables from "../components/DataTables/DataTables";
import { useGetMultipleIsOkMutation, useListIsOkQuery, useRunIsOkMutation, usePrefetch, useUpdateIsOkMutation } from "../services/isok";
import { Button, Input, Tag, Modal, Form, Row, Col, Tooltip } from "antd";
import { CaretRightOutlined, PaperClipOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import LoadingIcon from "../components/Spinners/Loading";

function HardwareTestWrapper({isImpersonated}) {

  const [isModalVisible,setIsModalVisible]=useState(false)
  const [selectedRecord, setSelectedRecord] = useState({})
  const [selectedAddress, setSelectedAddress]=useState((JSON.parse(sessionStorage.getItem("hardwareTestPage"))) || [])
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const [isokSpinner, setIsokSpinner ] = useState(false)
  const [attachTicketSpinner, setAttachTicketSpinner] = useState(false)

  const userDetails = api.getUserDetails();
  const timerRef = useRef(null)
  const [triggerGetResolution, {isLoading:GetIsOkLoading}] = useGetMultipleIsOkMutation();
  const [triggerIsOk, {isLoading:RunIsOkLoading}] = useRunIsOkMutation();
  const [triggerUpdateIsOk, {isLoading: UpdateIsOkLoading}] =useUpdateIsOkMutation();
  const {data:listIsOkData, error:listIsOkError, isFetching:listIsFetching, isLoading:listIsLoading } = useListIsOkQuery({username: isImpersonated ? localStorage.getItem("IMP_extension_ContactID") : userDetails.extension_ContactID})
  const fetchIsOkData = usePrefetch('listIsOk');

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [attachTicketForm] = Form.useForm();
  const buttonDisabled = Form.useWatch("ip_address",form)?.trim().length > 0 ? false: true;

  api.setLastPath(window.location.href);

  if(JSON.stringify(selectedAddress) !== sessionStorage.getItem("hardwareTestPage")){
    if(sessionStorage.getItem("hardwareTestPage")){
      setSelectedAddress(JSON.parse(sessionStorage.getItem("hardwareTestPage")))
    }
  }

  const removeSessionObject = ()=>{
    if(sessionStorage.getItem("hardwareTestPage")){
      sessionStorage.removeItem('hardwareTestPage')
    }  
  }

  useEffect(() => {
    window.addEventListener("beforeunload", removeSessionObject);
    return () => {
      window.removeEventListener("beforeunload", removeSessionObject);
    };
  }, []);

  const update_result = (record) => {
    // console.log("rerun hardware test for",{id:record.id,targets:[record.identifier]})
    rerunHardwareTest({id:record.id,targets:record.identifier});
  }

  const strCompare = (a, b, key) => {
    return (a[key]|| "N/A").localeCompare(b[key] || "N/A");
  };

  const getAnswer = async () => {
    try{
        if(sessionStorage.getItem("hardwareTestPage")){
          let running_ids = JSON.parse(sessionStorage.getItem("hardwareTestPage"))
          let payload = {
            ids: [...running_ids]
          }
          let isokResponseData = await triggerGetResolution({ payload }).unwrap();
          let result = isokResponseData.data
          for (const row of result){
            if(row.result.includes("INVALID") || row.result.includes("COMPLETE") || row.result.includes("FAILED")){
              let running_ids=[]
              if(sessionStorage.getItem("hardwareTestPage")){
                running_ids = JSON.parse(sessionStorage.getItem("hardwareTestPage"));
                running_ids = running_ids.filter(item=>item!==row.id)
                if(running_ids.length === 0){
                  sessionStorage.removeItem("hardwareTestPage");
                  clearInterval(timerRef.current);
                  timerRef.current=null;
                }
                api.openNotification("Success!", "success","ISOK results are added in input section.");          
                sessionStorage.setItem("hardwareTestPage",JSON.stringify(running_ids));
              }
              fetchIsOkData({username: userDetails.extension_ContactID},{force:true})
            }
          }
        }
      }
    catch (error){
      api.trigger401(error);
      // let running_ids = []
      if(sessionStorage.getItem("hardwareTestPage")){
        // running_ids = JSON.parse(sessionStorage.getItem("hardwareTestPage"));
        // running_ids = running_ids.filter(item=>item!==row_id)
        // if(running_ids.length === 0){
          sessionStorage.removeItem("hardwareTestPage");
        // }
        // sessionStorage.setItem("hardwareTestPage",JSON.stringify(running_ids));
      }
      clearInterval(timerRef.current);
      timerRef.current = null
    }
  }

  const runHardwareTest= async(targets)=>{
    try{
      if(!targets){
        console.log("Invalid IP!");
        api.openNotification("Invalid IP!","fail");
        return;
      }
      setIsModalVisible(false);
      let address = targets.trim().split(",")
      let payload ={
        "targets": address,
        "force": true,
        "triggered_by": "hardwareTestApp",
      }
      const resp = await triggerIsOk({ payload }).unwrap();

      if(resp.data.id){
        api.openNotification("Success!", "success", "ISOK job submitted. Please wait for the results!");
        const id = resp.data.id 
        let running_ids=[]
        if(sessionStorage.getItem("hardwareTestPage")){
          running_ids=JSON.parse(sessionStorage.getItem("hardwareTestPage"))
        }
        running_ids.push(id)
        sessionStorage.setItem("hardwareTestPage", JSON.stringify(running_ids));
        if(!timerRef.current){
          timerRef.current = setInterval(() => {
            getAnswer();
          }, 10000)
        }
        setIsokSpinner(false);
        fetchIsOkData({username: userDetails.extension_ContactID},{force:true})
      }
    } catch (error) {
      api.trigger401(error);
      setIsokSpinner(false);
    }

  }

  const rerunHardwareTest = async({targets,id}) =>{
    try{
      if(!id){
        console.log("Invalid Result ID!");
        api.openNotification("Invalid Result ID!","fail");
        return;
      }
      if(!targets){
        console.log("Invalid IP!");
        api.openNotification("Invalid IP!","fail");
        return;
      }
      targets = targets.trim().split(",")
      console.log("TARGETS",targets)
      let running_ids=[]
      if(sessionStorage.getItem("hardwareTestPage")){
        running_ids=JSON.parse(sessionStorage.getItem("hardwareTestPage"))
      }
      running_ids.push(id)
      sessionStorage.setItem("hardwareTestPage", JSON.stringify(running_ids));
      
      let payload = {
        "id":id,
        "targets":targets,
        "reload": true
      }

      const resp = await triggerUpdateIsOk({ payload }).unwrap();
      if(resp.data[0].id){
        // const id = resp.data[0].id 
        if(!timerRef.current){
          timerRef.current = setInterval(() => {
            getAnswer();
          }, 10000)
        }
        api.openNotification("Success!", "success", "ISOK job submitted. Please wait for the results!");
     }

    } catch(error) {
      api.trigger401(error);
      let running_ids=[]
      if(sessionStorage.getItem("hardwareTestPage")){
        running_ids = JSON.parse(sessionStorage.getItem("hardwareTestPage"));
        running_ids = running_ids.filter(item=>item!==id)
        if(running_ids.length === 0){
          sessionStorage.removeItem("hardwareTestPage");
        }
        sessionStorage.setItem("hardwareTestPage",JSON.stringify(running_ids));
      }
    }
  }

  const attachTicket = async(values)=>{
    try{
      setAttachTicketSpinner(true);
      if(!values.ticket_id.trim()){
        console.log('Ticket-ID missing')
        return;
      }
      let payload;
      if(selectedRecord.id){
        payload = {
          "id": selectedRecord.id,
          "ticket_id": values.ticket_id.trim(),
          "message": (values.message && values.message.trim()) || "",
          "reload": false
        }
      }
      else{
        payload = {
          "id": selectedRecord.isok_id,
          "result_id":selectedRecord.result_id,
          "ticket_id": values.ticket_id.trim(),
          "message": (values.message && values.message.trim()) || "",
          "reload": false
        }
      }
      const resp = await triggerUpdateIsOk({ payload }).unwrap();
      if(resp.success){
        fetchIsOkData({username: userDetails.extension_ContactID},{force:true})
        api.openNotification("Success!", "success", "Ticket Attached!");
      }
      setAttachTicketSpinner(false);
      setAttachmentModalVisible(false);
    }
    catch(error){
      api.trigger401(error);
      setAttachTicketSpinner(false);
      setAttachmentModalVisible(false);
    }
  }

  const columns = [
    {
      title:"ID",
      key:"id",
      dataIndex:"id",
      // defaultSortOrder:'descend',
      sorter: (a, b) => a.id - b.id,
    },
    // {
    //   title:"Updated Date",
    //   key:"updated_date",
    //   dataIndex:"updated_date",
    //   align:"center",
    //   render: (text)=>{
    //     return moment(text).format("DD-MM-YYYY hh:mm:ss")
    //   },
    //   defaultSortOrder:'descend',
    //   sorter:(a,b) => {return moment(a.updated_date).diff(moment(b.updated_date))},
    //   sortDirections: []
    // },
    {
      title:"Request Date",
      key:"request_date",
      dataIndex:"request_date",
      align:"center",
      render: (text)=>{
        return moment(text).format("DD-MM-YYYY hh:mm:ss")
      },
      // defaultSortOrder:'descend',
      sorter:(a,b) => {return moment(a.request_date).diff(moment(b.request_date))},
      // sortDirections: []
    },
    {
      title:"Identifiers",
      key:"identifier",
      dataIndex:"identifier",
      align:"center",
      sorter:(a,b) => strCompare(a,b,"identifier")
    },
    {
      title:"Result",
      key:"result",
      dataIndex:"result",
      align:"center",
      render: (text, record)=>{
        if(selectedAddress.includes(record.id)){
          return <Tag color="gold">LOADING...</Tag>
        }
        if (["COMPLETE"].includes(text.trim())){
          return <Tag color="green">{text}</Tag>
        }
        if(["IN PROGRESS","REQUEST"].includes(text.trim())){
          return <Tag color="blue">{text}</Tag>
        }
        else {
          return <Tag color="red">FAILED</Tag>
        }
      },
      sorter:(a,b) => strCompare(a,b,"result")
    },
    {
      title:"Actions",
      key:"rerun",
      align: "center",
      render: (record)=>{ 
        let task_loading = selectedAddress.includes(record.id)
        let duration_difference = moment.duration(moment(new Date()).diff(moment(record.updated_date)))
        let hours = Math.ceil(duration_difference.asHours())
        let attach_disabled = task_loading
        if([0,2].includes(record.status) && hours === 1){
          attach_disabled = true 
        }
        return(
          <>
          <span style={{display:"inline-block",paddingRight:"15px"}}>
            {task_loading
            ? <LoadingIcon tip={null} className="table-button" style={{width:"32px"}}/>
            : <Tooltip title="Update Hardware Test"> <Button disabled={isImpersonated} className="table-button" type="link" icon={<ReloadOutlined />} onClick={()=>update_result(record)}/></Tooltip>}
          </span>
          <span style={{display:"inline-block"}}> <Tooltip title="Attach Ticket"><Button className="table-button" type="link" icon={<PaperClipOutlined/>} onClick={()=>openAttachmentModal(record)} disabled={attach_disabled || isImpersonated}/></Tooltip></span>
          </>
        )
      }
    },
  ]

  const expandedRowRender = (row) => {
    const result_detail=row.resultDetail
    const nested_columns = [
      {
        title: "Result Detail",
        key:"result",
        dataIndex: "result",
        width:"30%",
        render: (text)=>{
                        let filtered_text = text.replace(/[{}"]/g, '')
                        filtered_text = filtered_text.replace(/\\n\s*/g, '\n')
                        let [ip,...rest] = filtered_text.split(':')
                        rest = rest.join(':')
                        let className = filtered_text.includes("passed") ? "success-block" : "failed-block"
                        return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', margin:0,whiteSpace: "pre-wrap" }} className={className}>
                                <span style={{fontWeight:"600"}}>{ip} : </span> {rest}
                                </div>
                        },
      },
      {
        title: "Message",
        key: "message",
        dataIndex: "message",
        align:"center",
        render: (text)=>(text || "N/A"),
        sorter: (a,b) => strCompare(a,b,"message")
        // width:"10%"
      },
      {
        title: "Ticket ID",
        dataIndex:"sub_ticket_id",
        key:"sub_ticket_id",
        align:"center",
        // width:"10%",
        render: (text, record)=>{
          const ticket_id = text.toUpperCase() || "Attach Ticket"
          return (<Button disabled={isImpersonated} type="link" onClick={()=>openAttachmentModal(record)}>{ticket_id}</Button>)
        },
      }
    ]

    return(
      <div>
        <DataTables
          columns={nested_columns}
          dataSource={result_detail}
          rowKey="result_id"
          emptyText="No Test runs to show"
          loading={listIsLoading}
          scroll={{
            x:1000
          }}
          pagination={false}
          />
      </div>
    )
  }
  const openAttachmentModal = (record) => {
    attachTicketForm.setFieldsValue({
      "ticket_id":record.sub_ticket_id || record.ticket_id,
      "message":record.message
    })
    setAttachmentModalVisible(true);
    setSelectedRecord(record);
  }


  const attachmentModalResult = () =>{
    const items = []
    let result_detail=[];
    if(selectedRecord.id){
      result_detail= selectedRecord.resultDetail
    }
    else if(selectedRecord.result_id){
      result_detail= [selectedRecord]
    }
    for (const row of result_detail) {
      let data = JSON.parse(row.result)
      
      for (const i in data){
          // if("passed" in data[i]){
          //   items.push(<div key={i} className="success-block"><span style={{fontWeight:"600"}}>{i} : </span><div style={{width:"80%",display:"inline-grid"}}>{data[i]["passed"]}</div></div>)
          // }
          if("failed" in data[i]){
            if(typeof(data[i]["failed"])==='object'){
              let x=[];
              let failed=data[i]["failed"]
              for (const item in data[i]["failed"]){
                x.push(<p key={item} style={{marginBottom:0}}><span style={{fontWeight:"500"}}>{item} - </span><span>{failed[item]}</span></p>)
              }
              items.push(
                <div 
                  key={i} 
                  className={`failed-block`} 
                  // style={{maxWidth:"fit-content"}}
                  >
                    <span style={{fontWeight:"600"}}>{i} : </span>
                    <div style={{width:"80%",display:"inline-grid"}}>{x}</div>
                </div>)
            }
            else {
              items.push(
                <div 
                  key={i} 
                  className={`failed-block`}
                  >
                    <span style={{fontWeight:"600"}}>{i} : </span>
                    <div style={{width:"80%",display:"inline-grid"}}>{data[i]["failed"]}</div>
                  </div>)
            }
          }
        }
    }
    
    return(
      <div style={{marginBottom:'24px'}}>
        {items}
      </div>
    )
  }

  const renderModal = ()=>{
    return(
      <Modal 
        visible={attachmentModalVisible} 
        width={window.innerWidth > 600 ? "750px" : "100%"}
        maskClosable={false}
        closable={false} 
        footer={null} 
      >
        <h2 style={{marginBottom: "20px"}}> Attach results to a ticket</h2>
        <Form
          form = {attachTicketForm}
          onFinish={(values)=>{attachTicket(values)}}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="ticket_id"
                label={<span style={{fontWeight:500}}>Ticket</span>}
                rules={[
                  {
                    validator(_,value){
                      if(!value){
                        return Promise.reject(
                          new Error("Ticket-ID is required!")
                          )
                      }
                      if(!value.trim()){
                        return Promise.reject(
                          new Error("Ticket-ID is required!")
                        )
                      }
                      return Promise.resolve()
                    }
                  },
                ]}
                >
                <Input placeholder="Enter a ticket number to attach these results to"/>
              </Form.Item>
            </Col>
          </Row>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label={<span style={{fontWeight:500}}>Hardware Tested</span>}
                >
                  <p style={{marginBottom:"0px"}}>{selectedRecord.identifier || "NA"}</p>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item 
                  label={<span style={{fontWeight:500}}>Result</span>}  
                >
                  {
                    ["COMPLETE","REQUEST","passed"].some(word =>(selectedRecord?.result.trim()).includes(word))
                    ? <Tag color="green">PASSED</Tag>
                    : <Tag color="red">FAILED</Tag>
                  }
                </Form.Item>
                {attachmentModalResult()}
              </Col>
            </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="message"
                label={<span style={{fontWeight:"500"}}>Additional message</span>}
              >
                <TextArea/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span ={24} style={{textAlign:"right"}}>
              <Button onClick={()=>{setAttachmentModalVisible(false);setSelectedRecord({})}}>
                Close
              </Button>
              <Button
                type="primary" 
                htmlType="submit" 
                style={{ margin: '0 8px'}}
                loading={attachTicketSpinner}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
    

  }

  return (
    <>
      <Header title="Hardware Test (isok)" />
      <div className="content-container" style={{marginTop: "90px"}}>
        <div className="button-group">
          <Button type="primary" disabled={isImpersonated} icon={<CaretRightOutlined />} loading={isokSpinner} onClick={()=>{setIsModalVisible(true)}}>Run Hardware Test</Button>
        </div>
        <Modal 
          visible={isModalVisible} 
          width={window.innerWidth > 600 ? "750px" : "100%"}
          maskClosable={false} 
          closable={false} 
          footer={null}
          >
           <Form
              onFinish={(values)=>{ setIsokSpinner(true); runHardwareTest(values["ip_address"])}}
              labelCol={{ span: 11, offset: 0 }}
              labelWrap
              form={form}
            >
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <h3>Enter one or more IP addresses, region numbers, serial numbers or tickets that identify 
                        the hardware to be tested. The identifiers should be separated by a comma.
                    </h3>
                    <Form.Item
                      name="ip_address"
                      rules={[
                        {required: true,message:"Required!"},
                      ]}
                      noStyle
                    >
                      <TextArea size="large" placeholder="e.g.: 212.113.165.4,2.16.63.4,2.16.63 ..."/>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{textAlign:"right"}}>
                  <Button 
                    onClick={()=>{setIsModalVisible(false)}} 
                  >Close</Button>
                  <Button 
                    type="primary" 
                    htmlType="submit"
                    style={{margin: '0 8px'}} 
                    onClick={()=>{setIsModalVisible(false)}}
                    disabled={buttonDisabled}
                  >Submit</Button>     
                </Col>
              </Row>
          </Form>
        </Modal>
        <DataTables
          columns={columns}
          dataSource={listIsOkData}
          rowKey="id"
          emptyText="No Test runs to show"
          expandable={{ expandedRowRender: expandedRowRender,  
            rowExpandable: (record)=>(!["REQUEST","IN PROGRESS"].includes(record.result.trim())),
          }}
          loading={listIsFetching}
          pagination={{
            showSizeChanger: true,
            responsive: true,
            // total: {listIsOkData},
            defaultPageSize:20,
            pageSizeOptions:[10, 20, 50, 100],
          }}
          />
        {attachmentModalVisible && renderModal()}
      </div>
    </>
  );
}

export default function HardwareTest( {isImpersonated} ) {
  let props = {isImpersonated}
  return BaseLayout(HardwareTestWrapper, props);
}