import './akamai-preloader.css';

export default function AkamaiPreloader ({ className='', color='blue', ...props }){    
    return (
        <div className={`ak-preloader-wrapper ${className}`} {...props}>
            <div className={`ak-preloader ak-preloader-centered ${color === 'blue' ? 'ak-preloader-blue' : ''}`}>
                <div className="crescent-1"></div>
                <div className="crescent-2"></div>
                <div className="crescent-3"></div>
            </div>
        </div>
    );
}
