import {createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setImpersonationHeaders } from "../impersonateApi";

export const activitylogsApi = createApi({
    reducerPath: "activitylogsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: new URL("activitylogs", process.env.REACT_APP_FT_PORTAL_URL).toString(),
    prepareHeaders: (headers) => {
        headers.set("Authorization", localStorage.getItem("token"));
        headers = setImpersonationHeaders(headers);
        return headers;
        },
    }),
    endpoints: (build) => ({
        getActivitylogs: build.query({
            query: ({start_date, end_date,timezone="GMT"}) =>{
              return {
                  url: '/?start_date='+start_date+"&end_date="+end_date+"&timezone="+timezone,
                  method: 'GET'
              }
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
        }),
        getExportlogs: build.query({
            query: ({start_date, end_date}) =>{
              return {
                  url: '/?start_date='+start_date+"&end_date="+end_date+"&export=true",
                  method: 'GET'
              }
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
        }),
        getActivitylog: build.query({
            query: ({visit_id}) =>{
                return {
                    url:`/activity_log/${visit_id}`,
                    method: 'GET'
                }
            }

        }),
        updateActivitylog: build.mutation({
            query: ({payload,visit_id}) => {
                return {
                    method:"PATCH",
                    body: {...payload,visit_id}
                };
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
        }),
        addActivitylog: build.mutation({
            query: ({payload}) => {
                return {
                    method:"POST",
                    body: {...payload}
                };
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
        }),
        deleteActivitylog: build.mutation({
            query: ({visit_id}) => {
                console.log(visit_id);
                return {
                    url: `/activity_log/${visit_id}`,
                    method: "DELETE"
                }
            },
            keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT
        })
    }),
})

export const{ 
    useGetActivitylogsQuery,
    useLazyGetActivitylogsQuery,
    useLazyGetActivitylogQuery,
    useUpdateActivitylogMutation,
    useAddActivitylogMutation,
    useDeleteActivitylogMutation,
    useGetExportlogsQuery,
} = activitylogsApi;
