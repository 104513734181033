import { useEffect, useState } from 'react';
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import * as api from '../api';

export default function WriteOperations({isImpersonated}) {
    useEffect(() => {
        api.setLastPath(window.location.href);
    })

    const [abstract, setAbstract] = useState("");
    const [issueID, setissueID] = useState("");
    // const [orderID, setOrderID] = useState("");
    // const [description, setDescription] = useState("");
    const [comments, setComments] = useState("");
    const [assetID, setAssetID] = useState("");
    const [msg, setMsg] = useState({color: null, text: null});

    
    const handleIssueUpdate = async e => {
        e.preventDefault();
        setMsg({color: null, text: "Processing..."});
        let resp;
        try {
            resp = await api.updateIssue(issueID, abstract);            
            if(resp && resp.data ){
                if(resp.data.success) setMsg({text: "Done! Please confirm above changes via Siebel QA.", color: "green"});
                else setMsg({text: resp.data.message, color: "red"});
            } else {
                setMsg({text: "Unexpected Error Occurred!!", color: "red"});
            }
        } catch (error) {
            console.log(error.code);
            if ((error.code === 'ERR_NETWORK') || (error.code === 'ECONNABORTED')) {
                setMsg({text: "Request Timed out. Please try again or report this to administrator.", color: "red"});
            }
            console.log(error.response.data.message);
            setMsg({text: error.response.data.message, color: "red"});
        }
    }

    const handleAssetUpdate = async e => {
        e.preventDefault();
        setMsg({color: null, text: "Processing..."});
        let resp;
        try {
            resp = await api.updateAsset(assetID, comments);
            if(resp && resp.data){
                if(resp.data.success) setMsg({text: "Done! Please confirm the above changes via Siebel QA.", color: "green"});  
                else setMsg({text: resp.data.message, color: "red"});
            }  else {
                setMsg({text: "Unexpeceted Error!!", color: "red"});
            }
        } catch (error) {
            console.log(error.response.data.message);
            setMsg({text: error.response.data.message, color: "red"});
        }
    }

    return (
        <div className="content-container">
            <Breadcrumbs/>
            <h2>Demo Operations</h2>
            <div>
                <h3>Issues</h3>
                <form onSubmit={handleIssueUpdate}>
                    <label htmlFor="issue_id">Issue ID: </label>
                    <input type="text" name="issue_id" onChange={(e) => setissueID(e.target.value)}/>
                    <label htmlFor="abstract">Abstract: </label>
                    <input type="text" name="abstract" onChange={(e) => setAbstract(e.target.value)}/>
                    <button type="submit">Update</button>
                </form>

            </div>
            {/* <div>
                <h3>Orders</h3>
                <form onSubmit={handleOrderUpdate}>
                    <label htmlFor="order_id">Order ID: </label>
                    <input type="text" name="order_id" onChange={(e) => setOrderID(e.target.value)}/>
                    <label htmlFor="description">Description: </label>
                    <input type="text" name="description" onChange={(e) => setDescription(e.target.value)}/>
                    <button type="submit">Update</button>
                </form>
            </div> */}
            <div>
                <h3>Assets</h3>
                <form onSubmit={handleAssetUpdate}>
                    <label htmlFor="asset_id">Asset ID: </label>
                    <input type="text" name="asset_id" onChange={(e) => setAssetID(e.target.value)}/>
                    <label htmlFor="comments">Comments: </label>
                    <input type="text" name="comments" onChange={(e) => setComments(e.target.value)}/>
                    <button type="submit">Update</button>
                </form>
            </div>
            {msg.text && (<span style={{color: msg.color}}><br/>{msg.text}</span>)}
        </div>
    )
}