import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setImpersonationHeaders } from "../impersonateApi";

export const datacenterApi = createApi({
  reducerPath: "datacenterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: new URL("datacenters", process.env.REACT_APP_FT_PORTAL_URL).toString(),
    prepareHeaders: (headers) => {
      headers.set("Authorization", localStorage.getItem("token"));
      headers = setImpersonationHeaders(headers);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getDatacenters: build.query({
      query: () => "/",
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getTodo: build.query({
      query: ({ data_center_id, ticket_id }) => {
        return {
          url: `${data_center_id}/ticket_details/${ticket_id}`,
          method: "POST"
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getTodos: build.query({
      query: ({ data_center_id, type }) => {
        if (type === "todos") {
          return {
            url: `${data_center_id}/todos/`,
            method: "GET",
          };
        } else {
          return {
            url: `${data_center_id}/rmas/`,
            method: "GET",
          };
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getFTNotes: build.query({
      query:({ ticket_id }) => {
        return {
          url: `/ft_notes`,
          method: "GET",
          params: { "ticket_id" : ticket_id }
        };
      }
    }),
    getSingleDc: build.query({
      query: ({ dc_id }) => `/${dc_id}`,
    }),
    getCounters: build.query({
      query: ({ issueType, data }) => {
        let dataCenterIDs = data.data.map((row) => row.data_center_id);
        return {
          url: `${issueType}/count`,
          method: "POST",
          body: { dataCenterIDs },
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getIssueActivity: build.query({
      query: ({ limit = 1, data, node_type = ["Todo"] }) => {
        let issueIDs = [...new Set(data.data.map((row) => row.issue_id))]; 
        return {
          url: `/issue-activity`,
          method: "POST",
          body: { issueIDs, limit, node_type },
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    //same as getIssue-activity but we do not need dc_id to be passed
    getTicketHistory: build.query({
      query:({ip_address, serial_number}) => {
          return {
            url: `/issue-activity`,
          method: "POST",
          body: {serial_number,ip_address},
          }
      }
    }),
    getIssueActivityHistory : build.mutation({
      query: ({ limit = 10, data, node_type = ["Todo","Resolution"] }) => {
        return {
          url: `/issue-activity`,
          method: "POST",
          body: { issueIDs:data, limit, node_type },
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getAuditRack: build.query({
      query: ({ data_center_id, rack_number = null, machine_number = null }) => {
        if(machine_number === null && rack_number=== null){
          return {
            url: `${data_center_id}/audit_rack`,
            method: "GET",
          };
        }
        if(machine_number === null){
          return {
            url: `${data_center_id}/audit_rack`,
            method: "GET",
            params: {'rack_number': encodeURIComponent(rack_number)}
          };
        }
        return {
          url: `${data_center_id}/audit_rack`,
          method: "GET",
          params: {'rack_number': encodeURIComponent(rack_number), 'machine_number': encodeURIComponent(machine_number)}
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getRackInfo: build.query({
      query: ({ data_center_id }) => {
        return {
          url: `${data_center_id}/rackinfo`,
          method: "GET",
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getUnknownRacks: build.query({
      query: ({ data_center_id }) => {
        return {
          url: `${data_center_id}/unknown-racks`,
          method: 'GET'
        }
      }
    }),
    getShipmentInfo: build.query({
      query: ({ data_center_id, data }) => {
        let issueIDs = [...new Set(data.data.map((row) => row.issue_id))];
        return {
          url: `${data_center_id}/shipments/${issueIDs}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getUpcomingShipments: build.query({
      query: ({data_center_id}) => {
        return {
          url: `${data_center_id}/upcoming_shipments`,
          method: "GET"
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getTrackingHistory: build.query({
      query: ({tracking_number}) => {
        return {
          url: `tracking_history/${tracking_number}`,
          method: "GET"
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    getRecipients: build.query({
      query: ({data_center_id}) => {
        return {
          url: `${data_center_id}/schedule-visit/recipients`,
          method: "GET"
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    updateScheduleVisit: build.mutation({
      query: ({ data_center_id, scheduleVisitData }) => {
        return {
          url: `${data_center_id}/schedule-visit`,
          method: "POST",
          body: scheduleVisitData,
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    addResolution: build.mutation({
      query: ({ data_center_id, type, payload }) => {
        return {
          url: `${data_center_id}/${type}/add-resolution`,
          method: "POST",
          body: payload,
        };
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    }),
    insertFTNotes: build.mutation({
      query: ({ payload }) => {
        return {
          url: '/ft_notes',
          method: "POST",
          body: payload
        }
      }
    }),
    getSearchResults: build.query({
      query:(payload) =>{
        const search_type = Object.keys(payload)[0]
        const search_string = payload[search_type]
        const url = `/search?${search_type}=${encodeURIComponent(search_string)}`
        return{
          url: url,
          method: 'GET'
        }
      },
      keepUnusedDataFor: process.env.REACT_APP_CACHE_TIMEOUT,
    })
  }),
});

export const {
  useGetDatacentersQuery,
  useGetSingleDcQuery,
  useGetTodoQuery,
  useGetTodosQuery,
  useGetCountersQuery,
  useGetIssueActivityQuery,
  useLazyGetTicketHistoryQuery,
  useGetIssueActivityHistoryMutation,
  useGetRackInfoQuery,
  useUpdateScheduleVisitMutation,
  useAddResolutionMutation,
  usePrefetch,
  useGetRecipientsQuery,
  useGetAuditRackQuery,
  useGetUnknownRacksQuery,
  useGetShipmentInfoQuery,
  useInsertFTNotesMutation,
  useGetFTNotesQuery,
  useGetUpcomingShipmentsQuery,
  useLazyGetTrackingHistoryQuery,
  useGetSearchResultsQuery
} = datacenterApi;
