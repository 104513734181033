import { useEffect, useState} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import BaseLayout from "./baselayout";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from "../components/Header/Header";
import { Button, Drawer, Descriptions, Tooltip, Popover, Input, Row, Col } from "antd";
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import DataTables from "../components/DataTables/DataTables";
import {CSVLink} from "react-csv";
import {
  useGetSingleDcQuery,
  useGetRackInfoQuery,
  useGetAuditRackQuery
} from "../services/datacenter";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader";
import * as api from "../api";
import Banner from "../components/Banner/Banner";


function AuditLogWrapper( { isImpersonated } ) {

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [childDrawerVisible, setChildDrawerVisible] = useState(false);
  const [rackSelected, setRackSelected] = useState({});
  const [searchedText, setSearchedText] = useState("");
  const [errorMsg, setErrormsg ] =useState(null)
  const { data_center_id } = useParams();
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [selectedMachine, setSelectedMachine] = useState(null)

  const {
    data: dc_data,
    error: dc_error
  } = useGetSingleDcQuery({ dc_id: data_center_id });

 
  const {
    data: rackInfo,
    isLoading: isRackInfoLoading,
    error: rackInfoError
  } = useGetRackInfoQuery({ data_center_id });
  
  const {
    data: auditRackInfo,
    isFetching: isAuditRackFetching,
    error: auditRackInfoError
  } = useGetAuditRackQuery({data_center_id, rack_number:rackSelected['rack_number'], machine_number:null}, { skip: Object.keys(rackSelected).length === 0 || !rackSelected['rack_number']});

  const {
    data: exportAuditRackInfo,
    isFetching: isExportAuditRackFetching
  } = useGetAuditRackQuery({data_center_id, rack_number:null, machine_number:null});
  
  api.setLastPath(window.location.href);

  const navigate = useNavigate();
  const dcAccessRequired = (error) => {
    if(error?.status === 401 && error?.data?.message == "Authorization failed") {
      alert("Access Denied! You are not authorize to access this Datacenter. Click on OK to redirect to the home page…");
      navigate('/datacenters');
    } else {
        console.log("error", error)
        api.trigger401(error,setErrormsg)
    }
}

  useEffect(() => {
    if(dc_error){
      console.log(dc_error);
      // if(dc_error.data) setMessage(error.data.message, "error", 10);
      dcAccessRequired(dc_error);
    }
    if(rackInfoError){
      console.log(rackInfoError);
      // if(rackInfoError.data) setMessage(error.data.message, "error", 10);
      dcAccessRequired(rackInfoError);
    }
    if(auditRackInfoError){
      console.log(auditRackInfoError);
      // if(auditRackInfoError.data) setMessage(error.data.message, "error", 10);
      dcAccessRequired(auditRackInfoError);
    }
  }, [dc_error, rackInfoError, auditRackInfoError])

  let csvlist = [];
  let filename = "";
  let exportAuditRackInfoData;
  if(exportAuditRackInfo){
    exportAuditRackInfoData = exportAuditRackInfo.data.map((row, index) => ({
      key: index,
      ...row,
    }));
    csvlist = exportAuditRackInfoData.map((row, index) => {
      if(row.post_width) row.post_width = row.post_width.replace(/[^0-9]/g,'')+"\" ";
      return row;
    });
    filename = csvlist[0]?.data_center_name;
  }

  let auditRackInfoData;
  if(auditRackInfo){
    if(auditRackInfo.data){
      auditRackInfoData=auditRackInfo.data
    }
  }
  const showTotal = (total) =>
  `Total ${total} Racks `;

  const strCompare = (a, b, key) => {
    if (!a[key]) a[key] = "N/A";
    if (!b[key]) b[key] = "N/A";
    return a[key].localeCompare(b[key]);
  };

  let rackList = [];
  if (rackInfo) {
    if (!rackInfo.success) {
      console.log("failed");
    }
    rackList = rackInfo.data.map((row, index) => ({
      key: index,
      ...row,
    }));
    console.log(rackInfo);
    // csvlist = rackList.map((row, index) => {
    //   if(row.post_width) row.post_width = row.post_width.replace(/[^0-9]/g,'')+"\" ";
    //   return row;
    // });
    // filename = csvlist[0].data_center_name;
  }

  const showDrawer = (record) => {
    setDrawerVisible(true);
    setRackSelected(record);
  };

  const closeDrawer  = () => {
    setDrawerVisible(false);
    setRackSelected({});
  }

  const columns = [
    {
      title: "Rack Number",
      key: "rack_number",
      dataIndex: "rack_number",
      render: (text, record, index) => (
        <>
          <Link to={`/datacenters/${data_center_id}/rack/${encodeURIComponent(record.rack_number)}`}>
            {record.rack_number}
          </Link>
        </>
      ),
      filteredValue:[searchedText],
        onFilter:(value,record)=>{return String(record.rack_number)
          .toLowerCase()
          .includes(value.toLowerCase()) 
        },
        sorter: (a, b) => a.issue_id.localeCompare(b.issue_id),
      sorter: (a, b) => strCompare(a, b, "rack_number"),
    },
    {
      title: "Floor",
      key: "floor_number",
      dataIndex: "R_floor",
      render: (text) => (text?text:"NA")
    },
    {
      title: "Row",
      dataIndex: "R_row",
      key: "R_row",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Column",
      dataIndex: "R_column",
      key: "R_column",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Post Width",
      dataIndex: "post_width",
      key: "post_width",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Holes",
      dataIndex: "holes",
      key: "holes",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Cage Number",
      dataIndex: "cage_number",
      key: "cage_number",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Circuits Per Rack",
      dataIndex: "circuits_rack",
      key: "circuits_rack",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Voltage",
      dataIndex: "voltage",
      key: "voltage",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Amps Per Circuit",
      dataIndex: "amps_circuit",
      key: "amps_circuit",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Outlets Per Circuit",
      dataIndex: "outlets_circuit",
      key: "outlets_circuit",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Outlet Type",
      dataIndex: "outlet_type",
      key: "outlet_type",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Lock Combination",
      dataIndex: "assigned_locks",
      key: "assigned_locks",
      render: (text) => (text?text:"NA")

    },
    {
      title: "Active",
      dataIndex: "Active",
      key: "Active",
      render: (text) => (text? (text ==="Y" ? "Yes":"No"):"NA")
    },

  ];

  const machineColumns = [
    {
      title:"Machine",
      dataIndex:"machine_number",
      key:"machine_number",
      render: (text, record) => (<Button
        type="text"
        className="rack-btn"
        style={{ color: "#1890ff" }}
        onClick = {() => {setChildDrawerVisible(true); setSelectedMachine(record.machine_number);} }
        >
          {record.machine_number}
        </Button> )
    },
    {
      title:"Status",
      dataIndex:"status",
      key:"status"
    }
  ];

 
  
  const headers = [
    { label: "data_center_id", key: "data_center_id" },
    { label: "data_center_name", key: "data_center_name" },
    { label: "rack_number", key: "rack_number" },
    { label: "R_floor", key: "R_floor" },
    { label: "R_row", key: "R_row" },
    { label: "R_column", key: "R_column" },
    { label: "type", key: "type" },
    { label: "post_width", key: "post_width" },
    { label: "holes", key: "holes" },
    { label: "cage_number", key: "cage_number" },
    { label: "circuits_rack", key: "circuits_rack" },
    { label: "voltage", key: "voltage" },
    { label: "amps_circuit", key: "amps_circuit" },
    { label: "outlets_circuit", key: "outlets_circuit" },
    { label: "outlet_type", key: "outlet_type" },
    { label: "assigned_locks", key: "assigned_locks" },
    { label: "Active", key: "Active" },
    { label: "machine_number", key: "machine_number" },
    { label: "assest_tag", key: "assest_tag" },
    { label: "serial_number", key: "serial_number" },
    { label: "product_name", key: "product_name" },
    { label: "region_number", key: "region_number" },
    { label: "status", key: "status" },
    { label: "ip_address", key: "ip_address" },
    { label: "net_mask", key: "net_mask" },
    { label: "gateway", key: "gateway" },
  ];

  const renderChildDrawer = (record) => {
    let obj = auditRackInfoData.filter(function(row){
        return row.machine_number === selectedMachine
    })
    return(
          <Drawer
            title={`Machine Detail - ${selectedMachine}`}
            placement="right"
            width={window.innerWidth > 600 ? "500" : "100%"}
            onClose={()=>{setChildDrawerVisible(false)}}
            visible={childDrawerVisible}
          >
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Asset Tag">{obj[0]['assest_tag'] ? obj[0]['assest_tag']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Serial Number">{obj[0]['serial_number'] ? obj[0]['serial_number']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Product Name">{obj[0]['product_name'] ? obj[0]['product_name']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Region Number">{obj[0]['region_number'] ? obj[0]['region_number']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Status">{obj[0]['Active'] ? (obj[0]['Active'] === 'Y' ? "Active" : "Inactive"): "NA"}</Descriptions.Item>
              <Descriptions.Item label="IP Address">{obj[0]['ip_address'] ? obj[0]['ip_address']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Subnet Mask">{obj[0]['net_mask'] ? obj[0]['net_mask']: "NA"}</Descriptions.Item>
              <Descriptions.Item label="Gateway">{obj[0]['gateway'] ? obj[0]['gateway']: "NA"}</Descriptions.Item>
            </Descriptions>
          </Drawer>
    )
  }

  const getTitle = () => {
    let title = `Audit Log`;
    if(dc_data && dc_data.data && dc_data.data.length) {
        title += ` - ${dc_data.data[0].name}, ${dc_data.data[0].location}`
    }
    return title;
}

  return (
    <>
      <Header title={getTitle()} />
      {errorMsg && <Banner msg={errorMsg} type="error" />}
      {/* <div style={{display:"flex", justifyContent:"space-between"}}> */}
      <Row>
         <Col lg={18} md={14} xs={24}>
            <Breadcrumbs />
         </Col>
         <Col className="search-menu-wrapper" lg={6} md={10} xs={24}>
          <div>
            <Popover content={"Hint: Rack number"}trigger="hover">
                <Input placeholder="Search" style={{width:200}}
                  allowClear
                  onChange={(e)=>{
                    setSearchedText(e.target.value)
                  }}
                  />
              </Popover>
          </div>
          <Button type="default" className="top-btn">
            <Link to={`/datacenters/${data_center_id}/unknown-racks`}><QuestionCircleOutlined></QuestionCircleOutlined>  Unknown Racks</Link>
          </Button>
          <Button type="primary" style={{backgroundColor:"transparent",color:"black",border:"none",boxShadow:"none"}} disabled={isExportAuditRackFetching} >
            <CSVLink headers={headers}  data={csvlist} filename={`Rack_details_${filename}.csv`}   style={{paddingLeft:4}} >{<DownloadOutlined />}</CSVLink>
          </Button>
        </Col>
        </Row>
      {/* </div> */}
      <div className="content-container">
        {/* <WorkInProgress /> */}
      <DataTables
            columns={columns}
            rowKey="key"
            emptyText="No Racks in this Data Center"
            dataSource={rackList}
            showTotal={showTotal}
            loading={isRackInfoLoading}
          />
      </div>
      
      <Drawer
      title={`Rack - ${rackSelected.rack_number}`}
      placement="right"
      visible={drawerVisible}
      bodyStyle={{
        minWidth: "320px",
      }}
      closable={true}
      onClose={closeDrawer}
      width={window.innerWidth > 600 ? "40%" : "100%"}
      maskClosable={false}
    >
        {isAuditRackFetching ? <AkamaiPreloader style={{position:"center"}} /> :(
          <>
        <DataTables
        columns={machineColumns}
        rowKey="list_of_machines"
        emptyText="No data to be shown here"
        loading={false}
        dataSource={auditRackInfoData}
        pagination ={false}
        bordered
        >
      </DataTables>
      {childDrawerVisible && renderChildDrawer(selectedRecord)}
      </>
      )}
      </Drawer>
    </>
  );
}

export default function AuditLog( { isImpersonated } ) {
  let props = { isImpersonated }; 
  return BaseLayout(AuditLogWrapper, props);
}
