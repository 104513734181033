import { configureStore } from "@reduxjs/toolkit";
import { datacenterApi } from "./services/datacenter";
import { userApi } from "./services/user";
import { isokApi } from "./services/isok";
import { activitylogsApi  } from "./services/activitylogs";
import { clientUtilsApi } from "./services/clientutils";
export const store = configureStore({
  reducer: {
    [datacenterApi.reducerPath]: datacenterApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [isokApi.reducerPath]: isokApi.reducer,
    [activitylogsApi.reducerPath]: activitylogsApi.reducer,
    [clientUtilsApi.reducerPath]: clientUtilsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(datacenterApi.middleware, userApi.middleware, activitylogsApi.middleware, isokApi.middleware, clientUtilsApi.middleware),
});
